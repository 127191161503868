<mat-card class="product-form-card">
  <h1 class="mat-display-1">Create New Report</h1>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <form
    action=""
    class="news-form"
    [formGroup]="createReportForm"
    (ngSubmit)="onSubmit(createReportForm)"
    *ngIf="!isLoading"
  >
    <!--    Content     -->
    <div
      formGroupName="reportContent"
      class="form-control-group"
    >

      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Report Name</mat-label>
        <input
          matInput
          type="text"
          name="name"
          formControlName="name"
          id="name"
          label="name"
          placeholder="Enter the report name here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Report Type</mat-label>
        <mat-select
          formControlName="reportType"
        >
          <mat-option *ngFor="let reportType of reportTypes" value="{{ reportType }}">{{ reportType }}</mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <div class="datepicker">
        <mat-form-field appearance="fill">
          <mat-label>Choose a start date</mat-label>
          <input matInput [matDatepicker]="startPicker" formControlName="reportDateStart">
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>Choose an end date</mat-label>
          <input matInput [matDatepicker]="endPicker" formControlName="reportDateEnd">
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <br>
    </div>
    <!--    Meta     -->
    <div
      formGroupName="reportMeta"
      class="form-control-group"
    >
      <mat-form-field [hidden]="true">
        <mat-label>Creator</mat-label>
        <input
          matInput
          value="{{ userId }}"
          name="creator"
          formControlName="creator"
          id="creator"
          label="creator"
        >
      </mat-form-field>
    </div>
    <button class="btn mat-raised-button mat-primary" type="submit" *ngIf="!hasError">Create New Report</button>
  </form>
</mat-card>
