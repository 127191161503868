import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  private reportLinks = [
    { path: 'print-new', label: 'Print New Report' },
  ];

  private reportTypes = [
    'Total Playtime',
    'Purchases',
    'New Users',
    'Average Session Length',
    'Harmonia Balance'
  ];

  constructor() { }
  getReportLinks() {
    return this.reportLinks;
  }

  getReportTypes() {
    return this.reportTypes;
  }

  createReport(reportInput) {
    console.log(reportInput);
  }
}
