import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../core/auth/auth.service';
import {NewsService} from '../../../../services/news.service';
import {NewsItemModel} from '../../../../models/NewsItem.model';
import {isEmpty} from 'rxjs/operators';

@Component({
  selector: 'app-latest-news-form',
  templateUrl: './latest-news-form.component.html',
  styleUrls: ['./latest-news-form.component.css']
})
export class LatestNewsFormComponent implements OnInit, OnDestroy {
  isLoading = true;
  // private authStatusSub: Subscription;
  editing: boolean = false;

  buttonFunctions;
  buttonFunctionsStatusSub: Subscription;

  newsStatusSub: Subscription;

  // need to convert news into a subscription w/ observers, but only if/when news becomes a feed and not a single item
  newsFeed = [];
  news;

  validationErrors: [];
  editNewsForm: FormGroup;
  formStatusChanges: Subscription;
  formValueChanges: Subscription;

  buttonText1: string;
  buttonText2: string;
  buttonText3: string;

  buttonLink1: string;
  buttonLink2: string;
  buttonLink3: string;

  hasError = false;

  constructor(
    // private authService: AuthService,
    private newsService: NewsService
  ) { }

  ngOnInit(): void {
    // // get's logged in user, subscribes to auth status and stop loading when connected
    // this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
    //   authStatus => {
    //     console.log(authStatus);
    //
    //     // automatically sets author to logged in user
    //     this.userId = this.authService.getUserId();
    //   }
    // );

    /*
      Store the subscription to any changes to the button functions for the News form
    */
    this.buttonFunctionsStatusSub = this.newsService.getNewsButtonFunctionsStatusListener().subscribe(
      newsButtonFunctionsStatus => {
        this.buttonFunctions = newsButtonFunctionsStatus;
      }
    );

    /*
    - Store the News Subscription and store any existing news items from the backend in this.newsFeed
    - Patch in the values from the backend for each Form Control
    */
    this.newsStatusSub = this.newsService.getNewsStatusListener().subscribe(
      newsStatus => {
        this.newsFeed = newsStatus;
        this.editNewsForm = new FormGroup({
          editNewsContent1: new FormGroup({
            body: new FormControl(
              null,
              [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(160)
              ]
            ),
            date: new FormControl(
              null,
              [
                Validators.minLength(4),
                Validators.maxLength(160)
              ]
            ),
          }),
          editNewsButton1: new FormGroup({
            buttonText1: new FormControl(
              null
            ),
            buttonFunction: new FormControl(
              null,
              [
                Validators.minLength(4),
                Validators.maxLength(160)
              ]
            ),
            buttonLink: new FormControl(
              null,
              [
                Validators.required,
              ]
            ),
          }),
          editNewsContent2: new FormGroup({
            body: new FormControl(
              null,
              [
                Validators.minLength(4),
                Validators.maxLength(160)
              ]
            ),
            date: new FormControl(
              null,
              [
                Validators.minLength(4),
                Validators.maxLength(160)
              ]
            ),
          }),
          editNewsButton2: new FormGroup({
            buttonText2: new FormControl(
              null
            ),
            buttonFunction: new FormControl(
              null,
              [
                Validators.minLength(4),
                Validators.maxLength(160)
              ]
            ),
            buttonLink: new FormControl(
              null,
              [
                Validators.required,
              ]
            ),
          }),
          editNewsContent3: new FormGroup({
            body: new FormControl(
              null,
              [
                Validators.minLength(4),
                Validators.maxLength(160)
              ]
            ),
            date: new FormControl(
              null,
              [
                Validators.minLength(4),
                Validators.maxLength(160)
              ]
            ),
          }),
          editNewsButton3: new FormGroup({
            buttonText3: new FormControl(
              null
            ),
            buttonFunction: new FormControl(
              null,
              [
                Validators.minLength(4),
                Validators.maxLength(160)
              ]
            ),
            buttonLink: new FormControl(
              null,
              [
                Validators.required,
              ]
            ),
          })
        });

        /*
        patch in each news item's information
        */
        if (this.newsFeed) {
          console.log(this.newsFeed);
          if (this.newsFeed[0]) {
            this.editNewsForm.patchValue({
              editNewsContent1: {
                body: this.newsFeed[0].body,
                date: this.newsFeed[0].date,
              },
              editNewsButton1: {
                buttonText1: this.newsFeed[0].buttonText,
                buttonFunction: this.newsFeed[0].buttonFunction,
                buttonLink: this.newsFeed[0].buttonLink,
              },
            });
            this.buttonText1 = this.newsFeed[0] ? this.newsFeed[0].buttonText : 'Example Button Text';
          }
          if (this.newsFeed[1]) {
            this.editNewsForm.patchValue({
              editNewsContent2: {
                body: this.newsFeed[1].body,
                date: this.newsFeed[1].date,
              },
              editNewsButton2: {
                buttonText2: this.newsFeed[1].buttonText,
                buttonFunction: this.newsFeed[1].buttonFunction,
                buttonLink: this.newsFeed[1].buttonLink,
              },
            });
            this.buttonText2 = this.newsFeed[1] ? this.newsFeed[1].buttonText : 'Example Button Text';
          }
          if (this.newsFeed[2]) {
            this.editNewsForm.patchValue({
              editNewsContent3: {
                body: this.newsFeed[2].body,
                date: this.newsFeed[2].date,
              },
              editNewsButton3: {
                buttonText3: this.newsFeed[2].buttonText,
                buttonFunction: this.newsFeed[2].buttonFunction,
                buttonLink: this.newsFeed[2].buttonLink,
              },
            });
            this.buttonText3 = this.newsFeed[2] ? this.newsFeed[2].buttonText : 'Example Button Text';
          }
        }

        /*
        subscribe to form value changes for button text CAPITALIZATION
        */
        this.formValueChanges = this.editNewsForm.valueChanges.subscribe(
          (value) => {
            if (value.editNewsButton1.buttonText1) {
              this.buttonText1 = value.editNewsButton1.buttonText1.toUpperCase();
            }
            if (value.editNewsButton2.buttonText2) {
              this.buttonText2 = value.editNewsButton2.buttonText2.toUpperCase();
            }
            if (value.editNewsButton3.buttonText3) {
              this.buttonText3 = value.editNewsButton3.buttonText3.toUpperCase();
            }
          },
          error => {
            alert(error);
          },
          () => {
            console.log('completed');
          }
        );
        /*
        subscribe to Form Status changes (editing mode)
        */
        this.formStatusChanges = this.editNewsForm.statusChanges.subscribe(
          (status) => {
            this.editing = status;
          },
          error => {
            alert(error);
          },
          () => {
            console.log('completed');
          }
        );
      }
    );
    /*
      ** Possibly redundant with above formStatusChanges subscription... but might be required for a non-editing mode)
      checks for existing object and determines editing mode or not
    */
    if (this.newsFeed !== []) {
      this.editing = true;
    }

    /*
    get news and button functions from newsService
    ** Possibly redundant with the formValueChanges subscriptions above?
    */
    this.newsService.getLatestNews();
    this.newsService.getLatestNewsButtonFunctions();

    /*
    build form for non-editing mode (no existing news items)
    */
    this.editNewsForm = new FormGroup({
      editNewsContent1: new FormGroup({
        body: new FormControl(
          null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(160),
          ]
        ),
        date: new FormControl(
          null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(160),
          ]
        ),
      }),
      editNewsButton1: new FormGroup({
        buttonText1: new FormControl(
          null,
          [
            Validators.required
          ]
        ),
        buttonFunction: new FormControl(
          null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(160)
          ]
        ),
        buttonLink: new FormControl(
          null,
          [
            Validators.required,
          ]
        ),
      }),
      editNewsContent2: new FormGroup({
        body: new FormControl(
          null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(160)
          ]
        ),
        date: new FormControl(
          null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(160)
          ]
        ),
      }),
      editNewsButton2: new FormGroup({
        buttonText2: new FormControl(
          null,
          [
            Validators.required
          ]
        ),
        buttonFunction: new FormControl(
          null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(160)
          ]
        ),
        buttonLink: new FormControl(
          null,
          [
            Validators.required,
          ]
        ),
      }),
      editNewsContent3: new FormGroup({
        body: new FormControl(
          null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(160)
          ]
        ),
        date: new FormControl(
          null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(160)
          ]
        ),
      }),
      editNewsButton3: new FormGroup({
        buttonText3: new FormControl(
          null,
          [
            Validators.required
          ]
        ),
        buttonFunction: new FormControl(
          null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(160)
          ]
        ),
        buttonLink: new FormControl(
          null,
          [
            Validators.required,
          ]
        ),
      })
    });

    /*
    stop loading once init is finished
    */
    this.isLoading = false;
  }

  onSubmit(form) {
    console.log(`submitted`, form.value);
    this.isLoading = true;
    if (this.editing) {
      return this.submitEditNewsFormMutation(form.value);
    }
    this.submitAddNewsFormMutation(form.value);
  }

  submitAddNewsFormMutation(form) {
    console.log(`submit mutation args`, form);
    const newsInput = [
      {
        id: 1,
        body: form.editNewsContent1.body,
        date: form.editNewsContent1.date,
        buttonText: form.editNewsButton1.buttonText1,
        buttonFunction: form.editNewsButton1.buttonFunction,
        buttonLink: form.editNewsButton1.buttonLink,
      },
      {
        id: 2,
        body: form.editNewsContent2.body,
        date: form.editNewsContent2.date,
        buttonText: form.editNewsButton2.buttonText2,
        buttonFunction: form.editNewsButton2.buttonFunction,
        buttonLink: form.editNewsButton2.buttonLink,
      },
      {
        id: 3,
        body: form.editNewsContent3.body,
        date: form.editNewsContent3.date,
        buttonText: form.editNewsButton3.buttonText3,
        buttonFunction: form.editNewsButton3.buttonFunction,
        buttonLink: form.editNewsButton3.buttonLink,
      },
    ];
    this.newsService.addNewsItem(newsInput);
    this.newsService.getNewsStatusListener().next();
  }

  submitEditNewsFormMutation(form) {
    console.log(`submit mutation args`, form);
    const newsInput = [
      {
        id: 1,
        body: form.editNewsContent1.body,
        date: form.editNewsContent1.date,
        buttonText: form.editNewsButton1.buttonText1,
        buttonFunction: form.editNewsButton1.buttonFunction,
        buttonLink: form.editNewsButton1.buttonLink,
      },
      {
        id: 2,
        body: form.editNewsContent2.body,
        date: form.editNewsContent2.date,
        buttonText: form.editNewsButton2.buttonText2,
        buttonFunction: form.editNewsButton2.buttonFunction,
        buttonLink: form.editNewsButton2.buttonLink,
      },
      {
        id: 3,
        body: form.editNewsContent3.body,
        date: form.editNewsContent3.date,
        buttonText: form.editNewsButton3.buttonText3,
        buttonFunction: form.editNewsButton3.buttonFunction,
        buttonLink: form.editNewsButton3.buttonLink,
      },
    ];
    this.newsService.editNewsItem(newsInput);
    // this.editNewsForm.reset(newsInput);
    setTimeout(() => {
      this.isLoading = false;
    }, 800);
  }

  ngOnDestroy() {
    // this.authStatusSub.unsubscribe();
    this.formStatusChanges.unsubscribe();
    this.formValueChanges.unsubscribe();
    this.newsStatusSub.unsubscribe();
    this.buttonFunctionsStatusSub.unsubscribe();
  }
}
