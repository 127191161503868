<div mat-dialog-container class="add-form-group-container">
    <h2 mat-dialog-title>Add new {{categoryCardItem.title}} to the {{ profileType }}</h2>
    <mat-dialog-content class="add-form-group-container">
        <div 
            class="category-cart-item-subcategory" 
            *ngFor="let subcategory of newFormGroupArray; index as index"
        >
            <div class="category-cart-item-subcategory-title" *ngIf="subcategory.options !== 'hidden'">{{ subcategory.title }}</div>
            <div class="subcategory-items-container">
                <div *ngIf="subcategory.type === 'list' && subcategory.options !== 'hidden'" class="profile-category-card-item">
                    <app-category-card-item
                        class="profile-category-card-item"
                        [parentForm]="newForm.controls[subcategory.fragment]"
                        [formGroup]="newForm.controls[subcategory.fragment]"
                        [categoryCardItem]="subcategory"
                        [profileType]="profileType">
                    </app-category-card-item>
                </div>
                <div *ngIf="subcategory.fragment === 'info' && subcategory.options !== 'hidden'" class="profile-category-card-item">
                    <app-single-item-form
                        class="profile-category-card-item"
                        [parentForm]="newForm.controls[subcategory.fragment]"
                        [formGroup]="newForm.controls[subcategory.fragment]"
                        [categoryCardItem]="subcategory"
                        [profileType]="profileType">
                    </app-single-item-form>
                </div>
                <div *ngIf="subcategory.type === 'single' && subcategory.fragment !== 'info' && subcategory.options !== 'hidden'" class="profile-category-card-item">
                    <app-dynamic-form 
                        [parentForm]="newForm.controls[subcategory.fragment]" 
                        [formInputs]="subcategory" 
                        [editing]="editing" 
                        [mutation]="mutation"
                    ></app-dynamic-form>
                </div>
                <mat-divider *ngIf="subcategory.options !== 'hidden'"></mat-divider>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <!-- <button *ngIf="newFormGroupArray[0].fragment === 'info'" mat-flat-button color="primary" (click)="createNewRecord(newForm)">Save new {{categoryCardItem.title}}</button> -->
        <button mat-flat-button color="primary" (click)="addNewFormGroup(newForm)">Save new {{categoryCardItem.title}}</button>
        <button mat-flat-button mat-dialog-close="false" color="warn">Cancel creation</button>
    </mat-dialog-actions>
</div>