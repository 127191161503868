import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserOptionsService } from 'src/app/dash/services/user-options.service';
import { FormInputControlService } from '../components/profile/profile/forms/form-items/form-input-control.service';

@Injectable({
  providedIn: 'root',
})
export abstract class ProfileService {
  abstract profileType: string;
  abstract actionPanelContent;
  abstract profileCategoryCards;

  public profileId;
  public editing = false;

  public profileForm;

  public profileOptions;
  public profileOptionsSubscription: Subscription;
  
  protected constructor(
    protected http: HttpClient,
    protected formInputControlService: FormInputControlService,
    public profileOptionsService: UserOptionsService,
  ) { }

  public getProfileItem(BACKEND_URL, itemId) {
    this.profileId = itemId;

    console.log(BACKEND_URL);
    return this.http.post<{ data: any; loading: boolean, success: boolean;  }>(
      BACKEND_URL + '/user-profile',
      {id: itemId}
    )
    .pipe(
        map((responseData) => {
            console.log('responseData', responseData);
            const profileData = this.formatProfileObject(responseData);
      
            return profileData;
        })
          
    )

    // return this.apollo
    //   .watchQuery<any>({
    //     query: QUERY,
    //     variables: {id: itemId}
    //   })
    //   .valueChanges
    //   .pipe(
    //     map((responseData) => {
    //       console.log(responseData);
    //       const profileData = this.formatProfileObject(responseData);
          
    //       return profileData;
    //     })
    //   )
  }

  public formatProfileObject(responseData) {
    console.log('responseData', responseData);
    let profileItem = responseData.data;
    let loading = responseData.loading ? responseData.loading : false;
    let networkStatus = responseData.networkStatus;

    let profileData = {
      loading: loading,
      type: 'default',
      actionPanelData: {},
      categoryCardItems: [],
      editing: this.editing,
      form: undefined,
    };
    profileData.type = this.getProfileType();
    profileData.actionPanelData = this.getActionPanelData(profileItem);
    profileData.categoryCardItems = this.getCategoryCardData(profileItem, this.profileOptions);
    profileData.form = this.convertProfileDataToForm(profileData.categoryCardItems);
    console.log(this.profileForm);
    
    return profileData;
  }

  public convertProfileDataToForm(profileData) {
    this.profileForm = this.formInputControlService.categoryCardListToFormGroup(profileData);
    return this.profileForm;
  }

  public getProfileType() {
    return this.profileType
  }

  abstract getActionPanelData(profileData);

  abstract getCategoryCardData(profileData, profileOptions);

  abstract getFormGroupType(fragment);

  public fetchProfileOptions() {
    return this.profileOptionsService.getUserOptions()
  }

  public createNewRecord(BACKEND_URL, data) {
    console.log(BACKEND_URL);
    console.log(data);
    const URL = BACKEND_URL + '/user-profile-create'
    let pdata = this.transformFormData(JSON.parse(data));
    console.log(pdata);

    return this.http.post<{ data: any; loading: boolean, success: boolean;  }>(
      URL,
      {
        data: pdata,
      }
    )
    .pipe(
      map((responseData) => {
          console.log('responseData', responseData);
          // const profileData = this.formatProfileObject(responseData);
    
          // return profileData;
          return responseData;
      })
    )
    // return this.apollo
    //   .mutate<any>({
    //     mutation: MUTATION,
    //     context: {
    //       useMultipart: true,
    //     },
    //     variables: {
    //       data: pdata
    //     }
    //   })
  }

  public updateCategoryCardData(BACKEND_URL, profileId, data) {
    console.log(profileId);
    console.log(data);
    console.log(BACKEND_URL);
    const URL = BACKEND_URL + '/user-profile-edit'
    console.log(URL);
    let tData = this.transformFormData(JSON.parse(data));
    console.log(tData);

    return this.http.post<{ data: any; loading: boolean, success: boolean;  }>(
      URL,
      {
        id: profileId,
        data: tData,
      }
    )
    .pipe(
      map((responseData) => {
          console.log('responseData', responseData);
          const profileData = this.formatProfileObject(responseData);
    
          return profileData;
      })
    )
  }
  
  abstract transformFormData(formData);

}
