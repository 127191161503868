import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DirectoryService } from 'src/app/shared/services/directory.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserDirectoryService extends DirectoryService {
  public dataTableColumns;
  public dataTable: any[] = [];
  // public dataTable: UserDirectoryModel[] = [];
  public dataTableColumnsArray: {field: string, width: number}[] = []; 

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

}
