import { UserOptionsService } from "src/app/dash/services/user-options.service";
import { BaseFormInput } from "src/app/shared/components/profile/profile/forms/form-items/base-form-input";
import { CheckboxFormInput } from "src/app/shared/components/profile/profile/forms/form-items/checkbox-form-inputs";
import { DateFormInput } from "src/app/shared/components/profile/profile/forms/form-items/date-form-input";
import { DropdownFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-form-input";
import { DropdownMultiFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-multi-form-input";
import { ImageFormInput } from "src/app/shared/components/profile/profile/forms/form-items/image-form-input";
import { NumberFormInput } from "src/app/shared/components/profile/profile/forms/form-items/number-form-input";
import { TextFormInput } from "src/app/shared/components/profile/profile/forms/form-items/text-form-input";
import { UnorderedListFormInput } from "src/app/shared/components/profile/profile/forms/form-items/unordered-list-form-input";

export class CreateUserSessionsFormGroupsModel {
    sessionUserInfoControls: BaseFormInput<string>[] = [
      new DropdownFormInput({
        label: `Session User Status`,
        controlType: `dropdown`,
        key: 'sessionUserStatusCode',
        value: 'active',
        options: this.userOptionsService.statusOptions,
        order: 1,
        disabled: false,
      }),
    ];
    sessionInfoControls: BaseFormInput<string>[] = [
      // new DropdownFormInput({
      //   label: `Session Status`,
      //   controlType: `dropdown`,
      //   key: 'sessionStatusCode',
      //   value: undefined,
      //   options: this.userOptionsService.statusOptions,
      //   order: 1,
      //   disabled: false,
      // }),
      new NumberFormInput({
        label: `Session ID`,
        key: 'sessionId',
        value: undefined,
        order: 1,
        disabled: false,
      }),
      // new DropdownFormInput({
      //   label: `Session Type`,
      //   controlType: `dropdown`,
      //   key: 'sessionStatusCode',
      //   value: undefined,
      //   options: this.userOptionsService.sessionTypeOptions,
      //   order: 1,
      //   disabled: false,
      // }),
      // new TextFormInput({
      //   label: `Session Description`,
      //   key: 'sessionDescription',
      //   value: undefined,
      //   order: 2,
      //   disabled: false,
      // }),
      // new NumberFormInput({
      //   label: `Times Used`,
      //   key: 'timesUsed',
      //   value: undefined,
      //   order: 4,
      //   disabled: false,
      // }),
    ];
    
    constructor(public userOptionsService: UserOptionsService) {}
}