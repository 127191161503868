import { UserOptionsService } from "src/app/dash/services/user-options.service";
import { CreateUserArtistsFormGroupsModel } from "./CreateUserArtistsFormGroups.model";
import { CreateUserImagesFormGroupsModel } from "./CreateUserImagesFormGroups.model";
import { CreateUserInventoryFormGroupsModel } from "./CreateUserInventoryFormGroups.model";
import { CreateUserInventoryTransactionsFormGroupsModel } from "./CreateUserInventoryTransactionsFormGroups.model";
import { CreateUserLinkedPlatformProfilesFormGroupsModel } from "./CreateUserLinkedPlatformProfilesFormGroups.model";
import { CreateUserPerksFormGroupsModel } from "./CreateUserPerksFormGroups.model";
import { CreateUserPersonalInformationFormGroupsModel } from "./CreateUserPersonalInformationFormGroups.model";
import { CreateUserSessionsFormGroupsModel } from "./CreateUserSessionsFormGroups.model";
import { CreateUserVrPlatformProfilesFormGroupsModel } from "./CreateUserVrPlatformProfilesFormGroups.model";

export class CreateUserFormGroupsModel {
    info = {
        subcategories: new CreateUserPersonalInformationFormGroupsModel(this.userOptionsService).model,
        fragment: "info",
        type: "single",
        title: "User Information"
    };
    artists: CreateUserArtistsFormGroupsModel[]= [];
    linkedPlatformProfiles: CreateUserLinkedPlatformProfilesFormGroupsModel[] = [];
    userImages: CreateUserImagesFormGroupsModel[] = [];
    userSessions: CreateUserSessionsFormGroupsModel[] = [];
    userInventory: CreateUserInventoryFormGroupsModel[] = [];
    // userInventoryTransactions: UserInventoryTransactionsFormGroupsModel[] = [];
    userPerks: CreateUserPerksFormGroupsModel[] = [];
    vrPlatformProfiles: CreateUserVrPlatformProfilesFormGroupsModel[] = [];

    constructor(public userOptionsService: UserOptionsService) {}
}