<mat-toolbar color="primary" class="header-main-nav">
  <span>
    <ul class="header-main-nav__items">
      <li>
        <a
          routerLink=""
          class="main-header__brand"
        >
          <img
            src="../../../../../assets/images/soundscapeVrLogo.jpg"
            alt="Soundscape VR Dash"
          >
        </a>
      </li>
      <!--
      Update here for environment changes!
      -->
      <li>
<!--        <h1>DEV Environment</h1>-->
<!--        <h1>STAGING Environment</h1>-->
        <h1>{{ appEnv }} Environment</h1>
      </li>
      <li *ngIf="userIsAuthenticated && isAuthorized('employee')">
        <a
          mat-button
          routerLink="/dash/info-pane/latest-news"
          routerLinkActive="mat-accent">
          Game Info Pane
        </a>
      </li>
      <li *ngIf="userIsAuthenticated && isAuthorized('admin')">
        <a
          mat-button
          routerLink="/dash/server-control/variables"
          routerLinkActive="mat-accent">
          Server Control
        </a>
      </li>
      <li *ngIf="userIsAuthenticated && isAuthorized('admin')">
        <a
          mat-button
          disabled
          routerLink="/dash/messages/blast"
          routerLinkActive="mat-accent"
          matTooltip="Coming Soon!">
          Messaging
        </a>
      </li>
      <li *ngIf="userIsAuthenticated && isAuthorized('admin')">
        <a
          mat-button
          disabled
          routerLink="/dash/reports/print-new"
          routerLinkActive="mat-accent"
          matTooltip="Coming Soon!">
          Reporting
        </a>
      </li>
      <li *ngIf="userIsAuthenticated && isAuthorized('admin')">
        <a
          mat-button
          disabled
          routerLink="/dash/gifts/blast"
          routerLinkActive="mat-accent"
          matTooltip="Coming Soon!">
          Gifting
        </a>
      </li>
      <li *ngIf="userIsAuthenticated && isAuthorized('admin')">
        <a
          mat-button
          routerLink="/dash/user/directory"
          routerLinkActive="mat-accent">
          Users
        </a>
      </li>
      <li *ngIf="userIsAuthenticated && isAuthorized('employee')">
        <a
          mat-button
          routerLink="/dash/muse/get-stream"
          routerLinkActive="mat-accent"
          matTooltip="Media Url Stream Extractor">
          MUSE
        </a>
      </li>
    </ul>
  </span>
  <span class="spacer-class"></span>
  <ul class="header-main-nav__items">
    <li *ngIf="userIsAuthenticated && isAuthorized('player')">
      <button mat-button [routerLink]="['/dash/my/profile/', userId]">My Profile</button>
    </li>
    <li *ngIf="!userIsAuthenticated">
      <a mat-button routerLink="/auth/login" routerLinkActive="mat-accent">Login</a>
    </li>
    <li *ngIf="userIsAuthenticated">
      <button mat-button (click)="onLogout()">Logout</button>
    </li>
  </ul>
</mat-toolbar>
