<!-- <form [formGroup]="parentForm.controls[categoryCardItem.fragment]"> -->
    <div 
        class="category-cart-item-subcategory" 
        *ngFor="let subcategory of categoryCardItem.subcategories; index as index"
    >    
        <div class="category-cart-item-subcategory-title">{{ subcategory.title }}</div>
        <div class="subcategory-items-container">
            <div *ngIf="subcategory.type === 'list'" class="profile-category-card-item">
                <app-category-card-item
                    class="profile-category-card-item"
                    [parentForm]="parentForm.controls[subcategory.fragment]"
                    [formGroup]="parentForm.controls[subcategory.fragment]"
                    [categoryCardItem]="subcategory"
                    [profileType]="profileType">
                </app-category-card-item>
            </div>
            <div *ngIf="subcategory.type === 'single'" class="profile-category-card-item">
                <app-dynamic-form [parentForm]="parentForm.controls[categoryCardItem.subcategories[index].fragment]" [formInputs]="subcategory" [editing]="editing" [mutation]="mutation"></app-dynamic-form>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>
<!-- </form> -->