<mat-expansion-panel 
  class="category-card-expansion-panel"
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
  [expanded]="panelOpenState"
  id="{{categoryCardItem.fragment}}"
>
  <mat-expansion-panel-header class="action-panel-category-card-item-header">
      <mat-panel-title class="category-card-item-header" *ngIf="categoryCardItem.listType && categoryCardItem.listItems || categoryCardItem.type === 'single' && categoryCardItem.listItems || categoryCardItem.type === 'single' && !categoryCardItem.listItems && !categoryCardItem.listType">
          {{categoryCardItem.title}}
      </mat-panel-title>
      <mat-panel-title class="category-card-item-header" *ngIf="categoryCardItem.listType && !categoryCardItem.listItems">
        {{categoryCardItem.title}} #{{itemIndex + 1}}
      </mat-panel-title>
      <mat-panel-description 
        class="action-panel-category-card-item-sub-header" 
        *ngIf="panelOpenState === false"
      >
        <div 
          class="category-card-item-sub-header"
          *ngFor="let subheader of categoryCardItem.subcategories; let isLast=last"
        >
          {{ subheader.title }}{{isLast ? '' : ', '}}
        </div>
      </mat-panel-description>
      <!-- <button 
        *ngIf="panelOpenState === true && categoryCardItem.type === 'single' && !categoryCardItem.listItems && categoryCardItem.listType" 
        mat-raised-button 
        color="warn"
        class="category-card-soft-delete-button" 
        (click)="softDeleteCategoryCardForm(categoryCardItem)"
        type="button"
      >Delete</button> -->
  </mat-expansion-panel-header>

  <mat-divider></mat-divider>

  <div *ngIf="categoryCardItem.type === 'single'" >
    <div>
      <!-- <button 
        mat-raised-button
        class="category-card-edit-button" 
        (click)="editCategoryCardForm()"
      >
          Edit
      </button> -->
    </div>
    <div>
      <app-single-item-form 
        [parentForm]="parentForm"
        [formGroup]="parentForm"
        [categoryCardItem]="categoryCardItem"
        [profileType]="profileType"
        [editing]="editing"
        [mutation]="mutation"
      ></app-single-item-form>
    </div>
  </div>
  <div *ngIf="categoryCardItem.listItems && categoryCardItem.listItems.length === 0">
    <p class="no-listItem-text">
      No {{ categoryCardItem.title }} associated with this {{ profileType }}
    </p>
    <button 
      mat-raised-button 
      color="primary"
      class="category-card-edit-button" 
      (click)="addCategoryCardForm(categoryCardItem.listItems)"
      type="button"
    >
        Add {{ categoryCardItem.title }}
    </button>
  </div>
  <div *ngIf="categoryCardItem.type === 'list' && categoryCardItem.listItems.length > 0" class="category-card-container">
    <div *ngIf="categoryCardItem.type === 'list'">
      <app-category-card-list
        [parentForm]="parentForm"
        [formGroup]="parentForm"
        [categoryCardItems]="categoryCardItem.listItems"
        [profileType]="profileType"
        [editing]="editing"
      ></app-category-card-list>
    </div>
    <div>
      <button 
        mat-raised-button 
        color="primary"
        class="category-card-edit-button" 
        (click)="addCategoryCardForm(categoryCardItem)"
        type="button"
      >
          Add {{ categoryCardItem.title }}
      </button>
    </div>
  </div>
</mat-expansion-panel>
<br>
