import {Injectable, Input} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArtistService {
  private artistLinks = [
    { path: 'artist-entry', label: 'Artist Entry' },
    { path: 'artist-schedule', label: 'Artist Schedule' },
    { path: 'artist-list', label: 'Artist List' }
  ];

  private artists = [
    {
      _id: '123abc',
      name: 'The Beatles',
      website: 'www.applerecords.com',
      livestream: 'www.soundscape.com/livestream/beatles',
      linkId: '123abc'
    },
    {
      _id: '123cba',
      name: 'Blink 182',
      website: 'www.takeoffyourpants.com',
      livestream: 'www.soundscape.com/livestream/blink',
      linkId: '123abc'
    },
    {
      _id: '123321',
      name: 'Bon Jovi',
      website: 'www.jonbons.com',
      livestream: 'www.soundscape.com/livestream/bonjovi',
      linkId: '123abc'
    },
    {
      _id: '123123',
      name: 'Beethoven',
      website: 'www.bigdog.com',
      livestream: 'www.soundscape.com/livestream/beethoven',
      linkId: '123abc'
    },
  ];

  private performances = [
    {
      _id: '1234567',
      body: 'Cool Body',
      artist: {
        _id: '123abc',
        name: 'The Beatles',
      },
      buttonText: 'Cool button text',
    },
    {
      _id: '1234321',
      body: 'Cooler Body',
      artist: {
        _id: '123cba',
        name: 'Blink 182',
      },
      buttonText: 'Cooler button text',
    },
    {
      _id: '1231231',
      body: 'Coolest Body',
      artist: {
        _id: '123321',
        name: 'Bon Jovi',
      },
      buttonText: 'Coolest button text',
    },
    {
      _id: '1234123',
      body: 'Most Coolest Body',
      artist: {
        _id: '123123',
        name: 'Beethoven',
      },
      buttonText: 'Most Coolest button text',
    },
  ];

  constructor() { }

  getArtistLinks() {
    return this.artistLinks;
  }

  getArtists() {
    return this.artists;
  }

  getLatestPerformance(itemNumber, artistId) {
    const artistPerformances = this.performances.filter( performance => {
      if (performance.artist._id === artistId) {
        console.log('true', performance);
        return performance;
    } {
        console.log('false');
      }
      return;
    });
    return artistPerformances[0];
  }

  addArtist(artistInput) {
    console.log(artistInput);
  }

  editArtist(artistId, artistInput) {
    console.log(artistId, artistInput);
  }

  addPerformance(performanceInput) {
    console.log(performanceInput);
  }

  editPerformance(performanceId, performanceInput) {
    console.log(performanceId, performanceInput);
  }
}
