import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import { Subject } from 'rxjs';

const BACKEND_URL = environment.apiUrl + '/artists';

@Injectable({
  providedIn: 'root'
})
export class MuseService {
  private museLinks = [
    { path: 'get-stream', label: 'Get Stream URL' },
    // { path: 'magic-mirror-links', label: 'Magic Mirror Videos' },
    // { path: 'stats', label: 'Video Stats' },
  ];

  private urlStreamListener = new Subject<{}>();

  constructor(private http: HttpClient, private router: Router) { }

  getUrlStreamListener() {
    return this.urlStreamListener;
  }
  
  getMuseLinks() {
      return this.museLinks;
  }

  async convertVideoLinkToStreamUrl(videoLinkInput) {
    return this.http.post<{ videoLinkInput: any }>(
      BACKEND_URL + '/mmurltest',
      videoLinkInput
    )
    .subscribe(
      response => {
        this.urlStreamListener.next(response);
        return response;
      },
      error => {},
      () => {
        console.log('convert video link to stream url - completed')
      }
    )
  }
}
