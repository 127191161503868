import { UserOptionsService } from "src/app/dash/services/user-options.service";
import { BaseFormInput } from "src/app/shared/components/profile/profile/forms/form-items/base-form-input";
import { BreakFormInput } from "src/app/shared/components/profile/profile/forms/form-items/break-form-input";
import { CheckboxFormInput } from "src/app/shared/components/profile/profile/forms/form-items/checkbox-form-inputs";
import { DateFormInput } from "src/app/shared/components/profile/profile/forms/form-items/date-form-input";
import { DropdownFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-form-input";
import { DropdownMultiFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-multi-form-input";
import { ImageFormInput } from "src/app/shared/components/profile/profile/forms/form-items/image-form-input";
import { NumberFormInput } from "src/app/shared/components/profile/profile/forms/form-items/number-form-input";
import { TextFormInput } from "src/app/shared/components/profile/profile/forms/form-items/text-form-input";
import { UnorderedListFormInput } from "src/app/shared/components/profile/profile/forms/form-items/unordered-list-form-input";

export class CreateUserLinksFormGroupsModel {
    userLinkInfoControls: BaseFormInput<string>[] = [
      new DropdownFormInput({
        label: `Link Status`,
        controlType: `dropdown`,
        key: 'linkStatusCode',
        value: undefined,
        options: this.userOptionsService.publishStatusOptions,
        order: 2,
        disabled: false,
      }),
      new BreakFormInput({controlType: `break`}),
      new BreakFormInput({controlType: `break`}),
      new TextFormInput({
        label: `Link Name`,
        key: 'linkName',
        value: undefined,
        order: 3,
        disabled: false,
      }),
      new BreakFormInput({controlType: `break`}),
      new BreakFormInput({controlType: `break`}),
      new TextFormInput({
        label: `Link URL`,
        key: 'linkUrl',
        value: undefined,
        order: 4,
        disabled: false,
      }),
    ];
    
    constructor(public userOptionsService: UserOptionsService) {}
}