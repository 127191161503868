import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  private audienceGroups = [
    'all-users',
  ];

  private messageLinks = [
    { path: 'blast', label: 'New News Blast' },
  ];

  constructor() { }

  getMessageLinks() {
    return this.messageLinks;
  }

  getAudienceGroups() {
    return this.audienceGroups;
  }

  sendMessage(messageInput) {
    console.log(messageInput);
  }
}
