import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../core/auth/auth.service';
import {NewsService} from '../../../../services/news.service';
import {ReportingService} from '../../../../services/reporting.service';

@Component({
  selector: 'app-print-report-form',
  templateUrl: './print-report-form.component.html',
  styleUrls: ['./print-report-form.component.css']
})
export class PrintReportFormComponent implements OnInit, OnDestroy {
  isLoading = true;
  authStatusSub: Subscription;

  validationErrors: [];

  createReportForm = new FormGroup({
    reportContent: new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(160)]),
      reportType: new FormControl(null, [Validators.required]),
      reportDateStart: new FormControl(null, [Validators.required]),
      reportDateEnd: new FormControl(null, [Validators.required]),
    }),
    reportMeta: new FormGroup({
      creator: new FormControl(null, [Validators.required]),
    })
  });

  reportTypes;

  userId: string;

  hasError = false;

  constructor(
    private authService: AuthService,
    private reportingService: ReportingService
  ) { }

  ngOnInit(): void {
    // get's logged in user, subscribes to auth status and stop loading when connected
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      authStatus => {}
    );
    // automatically sets author to logged in user
    this.userId = this.authService.getUserId();

    this.createReportForm.patchValue({
      reportMeta: {
        creator: this.userId
      }
    });

    this.reportTypes = this.reportingService.getReportTypes();

    // subscribe to form status changes
    this.createReportForm.statusChanges.subscribe(
      // (status) => console.log(status)
    );

    // subscribe to form value changes
    this.createReportForm.valueChanges.subscribe(
      // (value) => console.log(value)
    );

    this.isLoading = false;
  }

  onSubmit(form) {
    console.log(`submitted`, form.value);
    this.isLoading = true;
    this.submitCreateReportFormMutation(form.value);
  }

  submitCreateReportFormMutation(form) {
    console.log(`submit mutation args`, form);
    const reportInput = {
      name: form.reportContent.name,
      reportType: form.reportContent.reportType,
      reportDateStart: form.reportContent.reportDateStart,
      reportDateEnd: form.reportContent.reportDateEnd,
      creator: form.reportMeta.creator,
    };
    this.reportingService.createReport(reportInput);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}
