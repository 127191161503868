import { Component, OnInit } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ReportingService} from '../../services/reporting.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-view',
  templateUrl: './reports-view.component.html',
  styleUrls: ['./reports-view.component.css']
})
export class ReportsViewComponent implements OnInit {
  links = [];
  activeLink;
  background: ThemePalette = 'primary';
  color = '#00000';

  constructor(
    private router: Router,
    private reportingService: ReportingService
  ) { }

  ngOnInit(): void {
    const reportLinks = this.reportingService.getReportLinks();
    this.links.push(...reportLinks);
    this.activeLink = this.router.url.replace("/dash/reports", '');
  }

}
