<mat-card class="product-form-card">
  <h1 class="mat-display-1" *ngIf="editing === true && news">Edit Existing News Blast</h1>
  <h1 class="mat-display-1" *ngIf="editing === false">Create News Blast</h1>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <form
    action=""
    class="news-form"
    [formGroup]="editNewsForm"
    (ngSubmit)="onSubmit(editNewsForm)"
    *ngIf="!isLoading"
  >
    <!--    Content 1     -->
    <div
      formGroupName="editNewsContent1"
      class="form-control-group"
    >
      <h3>News Item 1</h3>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Date</mat-label>
        <input
          matInput
          type="text"
          name="date"
          formControlName="date"
          id="date"
          label="date"
          placeholder="Enter the news date here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>News Body</mat-label>
        <input
          matInput
          type="text"
          name="body"
          formControlName="body"
          id="body"
          label="body"
          placeholder="Enter the news body here"
        >
      </mat-form-field>
      <br>
    </div>
    <!--    Button     -->
    <div
      formGroupName="editNewsButton1"
      class="form-control-group"
    >
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Button Text</mat-label>
        <input
          matInput
          type="text"
          name="buttonText1"
          formControlName="buttonText1"
          id="buttonText1"
          label="buttonText1"
          placeholder="Enter the news button text"
        >
      </mat-form-field>
      <br>
      <div class="button-display">
        <mat-form-field
          appearance="fill"
          class="form-control"
        >
          <mat-label>Button Function</mat-label>
          <mat-select
            formControlName="buttonFunction"
          >
            <mat-option *ngFor="let buttonFunction of buttonFunctions" value="{{ buttonFunction.newsButtonFunctionName }}">{{ buttonFunction.newsButtonFunctionName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="example-button" type="button">
          <div>{{ buttonText1 }}</div>
        </div>
      </div>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Button Link</mat-label>
        <input
          matInput
          type="text"
          name="buttonLink"
          formControlName="buttonLink"
          id="buttonLink"
          label="buttonLink"
          placeholder="Enter the news button link"
        >
      </mat-form-field>
      <mat-divider></mat-divider>
    </div>

    <!--    Content 2     -->
    <div
      formGroupName="editNewsContent2"
      class="form-control-group"
    >
      <h3>News Item 2</h3>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Date</mat-label>
        <input
          matInput
          type="text"
          name="date"
          formControlName="date"
          id="date"
          label="date"
          placeholder="Enter the news date here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>News Body</mat-label>
        <input
          matInput
          type="text"
          name="body"
          formControlName="body"
          id="body"
          label="body"
          placeholder="Enter the news body here"
        >
      </mat-form-field>
      <br>
    </div>
    <!--    Button     -->
    <div
      formGroupName="editNewsButton2"
      class="form-control-group"
    >
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Button Text</mat-label>
        <input
          matInput
          type="text"
          name="buttonText2"
          formControlName="buttonText2"
          id="buttonText2"
          label="buttonText2"
          placeholder="Enter the news button text"
        >
      </mat-form-field>
      <br>
      <div class="button-display">
        <mat-form-field
          appearance="fill"
          class="form-control"
        >
          <mat-label>Button Function</mat-label>
          <mat-select
            formControlName="buttonFunction"
          >
            <mat-option *ngFor="let buttonFunction of buttonFunctions" value="{{ buttonFunction.newsButtonFunctionName }}">{{ buttonFunction.newsButtonFunctionName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="example-button" type="button">
          <div>{{ buttonText2 }}</div>
        </div>
      </div>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Button Link</mat-label>
        <input
          matInput
          type="text"
          name="buttonLink"
          formControlName="buttonLink"
          id="buttonLink"
          label="buttonLink"
          placeholder="Enter the news button link"
        >
      </mat-form-field>
      <mat-divider></mat-divider>
    </div>

    <!--    Content 3     -->
    <div
      formGroupName="editNewsContent3"
      class="form-control-group"
    >
      <h3>News Item 3</h3>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Date</mat-label>
        <input
          matInput
          type="text"
          name="date"
          formControlName="date"
          id="date"
          label="date"
          placeholder="Enter the news date here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>News Body</mat-label>
        <input
          matInput
          type="text"
          name="body"
          formControlName="body"
          id="body"
          label="body"
          placeholder="Enter the news body here"
        >
      </mat-form-field>
      <br>
    </div>
    <!--    Button     -->
    <div
      formGroupName="editNewsButton3"
      class="form-control-group"
    >
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Button Text</mat-label>
        <input
          matInput
          type="text"
          name="buttonText3"
          formControlName="buttonText3"
          id="buttonText3"
          label="buttonText3"
          placeholder="Enter the news button text"
        >
      </mat-form-field>
      <br>
      <div class="button-display">
        <mat-form-field
          appearance="fill"
          class="form-control"
        >
          <mat-label>Button Function</mat-label>
          <mat-select
            formControlName="buttonFunction"
          >
            <mat-option *ngFor="let buttonFunction of buttonFunctions" value="{{ buttonFunction.newsButtonFunctionName }}">{{ buttonFunction.newsButtonFunctionName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="example-button" type="button">
          <div>{{ buttonText3 }}</div>
        </div>
      </div>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Button Link</mat-label>
        <input
          matInput
          type="text"
          name="buttonLink"
          formControlName="buttonLink"
          id="buttonLink"
          label="buttonLink"
          placeholder="Enter the news button link"
        >
      </mat-form-field>
      <mat-divider></mat-divider>
    </div>
<!--    <div *ngIf="editNewsForm.controls.valid && editNewsForm.controls.touched">-->
      <button class="btn mat-raised-button mat-primary" type="submit" *ngIf="editing || hasError">Edit Existing News Blast</button>
      <button class="btn mat-raised-button mat-primary" type="submit" *ngIf="!editing && !hasError">Create News Blast</button>
<!--    </div>-->
  </form>
</mat-card>
