<div *ngIf="loading === true"  class="loading-spinner-container">
    <mat-spinner class="loading-spinner"></mat-spinner>
    <div>Loading...</div>
</div>

<div class="profile-container" *ngIf="loading === false">
    <app-action-panel
        class="profile-action-panel"
        [actionPanelContent]="actionPanelContent"
        [profileType]="profileType"
    ></app-action-panel>
    <div class="profile-category-card-container">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="profile-category-card-container__header">
                <div class="profile-header-text">
                    {{ profileType }} Profile
                </div>
                <button mat-flat-button class="form-submit-button" color="accent" type="submit" [disabled]="profileForm.invalid">Save {{ profileType }} Profile</button>
            </div>
            <!-- <button mat-fab class="form-submit-button" color="accent" type="submit" [disabled]="profileForm.invalid"><mat-icon>plus</mat-icon></button> -->
            <app-category-card-list
                [parentForm]="profileForm"
                class="profile-category-card-list"
                [categoryCardItems]="categoryCardItems"
                [profileType]="profileType"
            ></app-category-card-list>
        </form>
    </div>
</div>