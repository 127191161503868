import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  selector: 'app-category-card-list',
  templateUrl: './category-card-list.component.html',
  styleUrls: ['./category-card-list.component.css']
})
export class CategoryCardListComponent implements OnInit {
  @Input() categoryCardItems;
  @Input() profileType;
  @Input() editing = false;

  @Input() parentForm;
  @Input() parentFormGroup: FormGroup
  @Input() parentFormArray: FormArray = undefined;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  opened = false;

  constructor() { }

  ngOnInit(): void {
    if (this.parentForm.controls[0]) {
      this.parentFormArray = this.parentForm;
    }
    if (!this.parentForm.controls[0]) {
      this.parentFormGroup = this.parentForm;
    }
  }

  toggleAccordion() {
    if (this.opened === false) {
      this.accordion.openAll()
      this.opened = true;
    } else {
      if (this.opened === true) {
        this.accordion.closeAll()
        this.opened = false;
      }
    }
  }
}
