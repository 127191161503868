<div class="example-action-buttons">
    <button mat-button (click)="toggleAccordion()" *ngIf="!opened" class="example-action-button">Expand All</button>
    <button mat-button (click)="toggleAccordion()" *ngIf="opened" class="example-action-button">Close All</button>    
</div>
<mat-accordion class="example-headers-align" multi>
    <app-category-card-item 
        *ngFor="let categoryCardItem of categoryCardItems; index as index"
        class="profile-category-card-item"
        [parentForm]="parentFormArray ? parentFormArray.controls[index]: parentFormGroup.controls[categoryCardItem.fragment]"
        [formGroup]="parentFormArray ? parentFormArray.controls[index] : parentFormGroup.controls[categoryCardItem.fragment]"
        [categoryCardItem]="categoryCardItem"
        [profileType]="profileType"
        [editing]="editing"
        [mutation]="categoryCardItems.mutation"
        [itemIndex]="index"
    ></app-category-card-item>
</mat-accordion>