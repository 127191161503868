import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-directory-actions',
  templateUrl: './directory-actions.component.html',
  styleUrls: ['./directory-actions.component.css']
})
export class DirectoryActionsComponent implements OnInit {
  @Input() fetchedSourceType;
  @Input() selection;

  constructor() { }

  ngOnInit(): void {
  }

    // Directory actions
    addObject() {
      console.log(this.fetchedSourceType);
    }

    bulkUpdateObject(objects: any[]) {
      console.log(this.selection);
      console.log(objects);
    }
  
    downloadCSV() {}
    
    more() {}

}
