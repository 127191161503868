import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { DynamicAddFormGroupComponent } from '../../forms/dynamic-add-form-group/dynamic-add-form-group.component';

@Component({
  selector: 'app-category-card-item',
  templateUrl: './category-card-item.component.html',
  styleUrls: ['./category-card-item.component.css']
})
export class CategoryCardItemComponent implements OnInit {
  @Input() categoryCardItem;
  @Input() profileType;

  @Input() editing = false;
  
  @Input() mutation;

  @Input() parentForm: FormGroup;

  @Input() itemIndex;
  
  @Input() index;

  panelOpenState = false;
  
  dialogRefSub: Subscription;

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.dialogRefSub !== undefined) {
      this.dialogRefSub.unsubscribe()
    }
  }

  // editCategoryCardForm() {
  //   console.log('editing')
  //   console.log('editing', this.categoryCardItem.editing)
  //   this.editing = !this.editing
  //   console.log('editing', this.categoryCardItem.editing)
  // }

  addCategoryCardForm(listItemsArray) {
    console.log('adding - this.parentForm', this.parentForm);
    console.log('adding - categoryCardItem', this.categoryCardItem)
    console.log('adding - listItemsArray', listItemsArray);
    let dialogRef = this.dialog.open(DynamicAddFormGroupComponent, {data: {categoryCardItem: listItemsArray[0] ? listItemsArray[0] : this.categoryCardItem, parentForm: this.parentForm, profileType: this.profileType, fragment: this.categoryCardItem.fragment}});
    // let dialogRef = this.dialog.open(DynamicAddFormGroupComponent, {data: {categoryCardItem: listItemsArray, parentForm: this.parentForm, profileType: this.profileType, fragment: this.categoryCardItem.fragment}});

    this.dialogRefSub = dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      console.log('dialogue close result: ', result);
      if (result === 'false' || result === "undefined" || result === undefined) {
        return;
      } else {
        console.log('dialogue close this.parentForm: ', this.parentForm);
        this.parentForm = result.parentForm;

        const newListItem = {
          title: result.originalData.title,
          fragment: result.originalData.fragment,
          listType: result.originalData.listType,
          subcategories: result.newFormGroupArray,
          type: 'single',
        }      
        console.log('dialogue close pre-push this.categoryCardItem: ', this.categoryCardItem)
        this.categoryCardItem.listItems.push(newListItem)
        console.log('dialogue close post-push this.categoryCardItem: ', this.categoryCardItem)
        console.log('dialogue close second this.parentForm: ', this.parentForm);
      }
    });
  }

  // addCategoryCardForm(listItemsArray) {
  //   console.log('adding - form', this.parentForm);
  //   console.log('adding - categoryCardItem', this.categoryCardItem)
  //   console.log('adding - listItemsArray', listItemsArray);
  //   let dialogRef = this.dialog.open(DynamicAddFormGroupComponent, {data: {categoryCardItem: listItemsArray[0], parentForm: this.parentForm, profileType: this.profileType}});

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }
}
