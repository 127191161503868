<div class="admin-form-tab-group">
  <nav mat-tab-nav-bar [backgroundColor]="background" [color]="color">
    <a
      mat-tab-link
      class="admin-form-tab"
      *ngFor="let link of links"
      (click)="activeLink = link.path"
      [active]="activeLink == link.path"
      [routerLink]="[link.path]"
    > {{ link.label }}</a>
  </nav>
</div>
<h3
  *ngIf="!activeLink"
  class="admin-form-tab-start-text"
>Click a tab to get started</h3>
<router-outlet></router-outlet>

<!--<h1>Messaging Dash</h1>-->
<!--<mat-tab-group dynamicHeight class="admin-form-tab-group">-->
<!--  <mat-tab label="Message Blast">-->
<!--    <div class="admin-form-tab mat-elevation-z4">-->
<!--      <app-message-blast-form></app-message-blast-form>-->
<!--    </div>-->
<!--  </mat-tab>-->
<!--</mat-tab-group>-->
