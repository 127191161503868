import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserOptionsService } from 'src/app/dash/services/user-options.service';
import { UserProfileService } from 'src/app/dash/services/user-profile.service';
import { FormInputControlService } from 'src/app/shared/components/profile/profile/forms/form-items/form-input-control.service';
import { ProfileComponent } from 'src/app/shared/components/profile/profile/profile.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent extends ProfileComponent implements OnInit, AfterViewInit {
  public MUTATION = 'MUTATION_UPDATE_PATIENT_PROFILE';
  public BACKEND_URL = environment.apiUrl + '/users';
  @Input() profileForm: FormGroup;
  
  constructor(
    public formInputControlService: FormInputControlService,
    public patientProfileService: UserProfileService,
    public route: ActivatedRoute,
    protected profileOptionsService: UserOptionsService,
  ) {
    super(formInputControlService, patientProfileService, route, profileOptionsService)
  }
}
