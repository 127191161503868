<mat-card class="artist-form-card">
    <h1 class="mat-display-1">Convert Video Link to URL Stream</h1>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <form
      action=""
      class="artist-form"
      [formGroup]="convertVideoLinkToStreamUrlForm"
      (ngSubmit)="onSubmit(convertVideoLinkToStreamUrlForm)"
      *ngIf="!isLoading"
    >
      <!--    Content     -->
      <div
        formGroupName="convertVideoLinkToStreamUrl"
        class="form-control-group"
      >
        <mat-form-field
          appearance="fill"
          class="form-control"
        >
          <mat-label>Video Link for Conversion</mat-label>
          <input
            matInput
            type="url"
            name="videoLinkUrl"
            formControlName="videoLinkUrl"
            id="videoLinkUrl"
            label="videoLinkUrl"
            placeholder="Enter video link here"
          >
        </mat-form-field>
        <br>
      </div>
      <!--    Meta     -->
      <div
        formGroupName="convertVideoLinkToStreamUrlMeta"
        class="form-control-group"
      >
        <mat-form-field [hidden]="true">
          <mat-label>Creator</mat-label>
          <input
            matInput
            value="{{ userId }}"
            name="creator"
            formControlName="creator"
            id="creator"
            label="creator"
          >
        </mat-form-field>
      </div>
      <button class="btn mat-raised-button mat-primary" type="submit" *ngIf="!hasError">Get Streaming Link</button>
    </form>
    <br>
    <div *ngIf="urlStream !== undefined" class="artist-form">
        <h3>URL Stream: </h3>
        <a href="{{ urlStream }}" target="_blank">{{ urlStream }}</a>
    </div>
  </mat-card>
  