import { Component, OnInit } from '@angular/core';
import {ArtistService} from '../../../services/artist.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-artist-list',
  templateUrl: './artist-list.component.html',
  styleUrls: ['./artist-list.component.css']
})
export class ArtistListComponent implements OnInit {

  private artists;

  displayedColumns: string[] = ['name', 'website', 'livestream', 'linkId', 'edit-artist', 'edit-performance'];
  dataSource;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private artistService: ArtistService
  ) {}

  ngOnInit(): void {
    this.artists = this.artistService.getArtists();
    this.dataSource = this.artists;
  }

  editArtist(artistId) {
    this.router.navigate(['artist-entry', {editing: true, artist: artistId}], {relativeTo: this.route.parent});
  }

  // editPerformance(artistId) {
  //   const performance = this.artistService.getLatestPerformance(0, artistId);
  //   this.router.navigate(['artist-schedule', {editing: true, performance: performance._id}], {relativeTo: this.route.parent});
  // }
  editPerformance(artistId) {
    this.router.navigate(['artist-schedule', {editing: true, artist: artistId}], {relativeTo: this.route.parent});
  }
}
