<mat-card class="artist-form-card">
  <h1 class="mat-display-1" *ngIf="editing === true && artist">Edit Existing Artist</h1>
  <h1 class="mat-display-1" *ngIf="editing === false">Add New Artist</h1>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <form
    action=""
    class="artist-form"
    [formGroup]="editArtistForm"
    (ngSubmit)="onSubmit(editArtistForm)"
    *ngIf="!isLoading"
  >
    <!--    Content     -->
    <div
      formGroupName="editArtistContent"
      class="form-control-group"
    >
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Artist Name</mat-label>
        <input
          matInput
          type="text"
          name="name"
          formControlName="name"
          id="name"
          label="name"
          placeholder="Enter the artist's name here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Artist Website</mat-label>
        <input
          matInput
          type="url"
          name="website"
          formControlName="website"
          id="website"
          label="website"
          placeholder="Enter the artist's website URL here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Artist Livestream URL</mat-label>
        <input
          matInput
          type="url"
          name="livestream"
          formControlName="livestream"
          id="livestream"
          label="livestream"
          placeholder="Enter the artist's livestream URL here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Artist Link ID</mat-label>
        <input
          matInput
          type="text"
          name="linkId"
          formControlName="linkId"
          id="linkId"
          label="linkId"
          placeholder="Enter the artist's linkId here"
        >
      </mat-form-field>
      <br>
    </div>
    <!--    Meta     -->
    <div
      formGroupName="editArtistMeta"
      class="form-control-group"
    >
      <mat-form-field [hidden]="true">
        <mat-label>Creator</mat-label>
        <input
          matInput
          value="{{ userId }}"
          name="creator"
          formControlName="creator"
          id="creator"
          label="creator"
        >
      </mat-form-field>
      <div *ngIf="editing && artist">
        <input
          type="hidden"
          name="artistId"
          formControlName="artistId"
          id="artistId"
          label="artistId"
          value="{{ artist._id }}"
        >
      </div>
    </div>
    <button class="btn mat-raised-button mat-primary" type="submit" *ngIf="editing || hasError">Edit Existing Artist</button>
    <button class="btn mat-raised-button mat-primary" type="submit" *ngIf="!editing && !hasError">Add Artist</button>
  </form>
</mat-card>
