import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { UserOptionsService } from 'src/app/dash/services/user-options.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { BaseFormInput } from './forms/form-items/base-form-input';
import { FormInputControlService } from './forms/form-items/form-input-control.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export abstract class ProfileComponent implements OnInit, AfterViewInit {
  // abstract MUTATION: DocumentNode;
  abstract BACKEND_URL: String;

  @Input() profileType;
  @Input() categoryCardItems;
  @Input() actionPanelContent;
  @Input() loading: boolean;

  @Input() formGroups: Observable<BaseFormInput<any>[]>;

  private fragment: string;

  
  abstract profileForm: FormGroup;
  payLoad = '';

  itemId;
  editProfileIndexSubscription;



  constructor(
    protected formInputControlService: FormInputControlService,
    protected profileService: ProfileService,
    protected route: ActivatedRoute,
    protected profileOptionsService: UserOptionsService,
  ) { }

  ngOnInit(): void {
    {
      this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    }
    this.getProfileIdFromParams();
    // this.profileForm = this.formInputControlService.categoryCardListToFormGroup(this.categoryCardItems);
  }
  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
    console.log(this.categoryCardItems);
    // this.profileForm = this.formInputControlService.categoryCardListToFormGroup(this.categoryCardItems);
    console.log(this.itemId);
  }
  


  public getProfileIdFromParams() {
    this.editProfileIndexSubscription = this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        const itemId = paramMap.get('id');
        this.itemId = itemId;
      }
    })
  }

  onSubmit() {
    this.loading = true;
    this.payLoad = JSON.stringify(this.profileForm.getRawValue());
    console.log(this.profileForm);
    console.log(this.payLoad);
    console.log(this.itemId);
    this.profileService.updateCategoryCardData(this.BACKEND_URL, this.itemId, this.payLoad)
      .subscribe(( rdata ) => {
        console.log('got data', rdata);
        this.loading = false;
        window.location.reload()
      },(error) => {
        console.log('there was an error sending the query', error);
      });;
  }

}
