import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../core/auth/auth.service';
import {ArtistService} from '../../../../services/artist.service';
import {ServerControlService} from '../../../../services/server-control.service';

@Component({
  selector: 'app-server-variables-form',
  templateUrl: './server-variables-form.component.html',
  styleUrls: ['./server-variables-form.component.css']
})
export class ServerVariablesFormComponent implements OnInit, OnDestroy {
  isLoading = true;
  authStatusSub: Subscription;

  serverVariablesSub: Subscription;

  serverVariables;

  formStatusChanges: Subscription;
  formValueChanges: Subscription;

  validationErrors: [];

  editServerVariablesForm: FormGroup = new FormGroup({
    editServerVariables: new FormGroup({
      freeHarmoniaFrequency: new FormControl(null, [Validators.required]),
      CheckinHarmoniaAmount: new FormControl(null, [Validators.required]),
      CheckinDaysNeededToReset: new FormControl(null, [Validators.required]),
      maxDailyAccrual: new FormControl(null, [Validators.required]),
      maxGoldenRecord: new FormControl(null, [Validators.required]),
      dailyHarmoniaLimit: new FormControl(null, [Validators.required]),
      SVRLivePassword: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(160)]),
      SoundEscapePassword: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(160)]),
    }),
    editServerVariablesMeta: new FormGroup({
      creator: new FormControl(null, [Validators.required]),
    })
  });

  userId: string;
  hasError = false;

  constructor(
    private authService: AuthService,
    private serverControlService: ServerControlService
  ) { }

  ngOnInit(): void {
    // get's logged in user, subscribes to auth status and stop loading when connected
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      authStatus => {}
    );
    // automatically sets author to logged in user
    this.userId = this.authService.getUserId();

    // gets Server Variables values from backend via angular service
    this.serverVariables = this.serverControlService.getServerVariables();

    this.serverVariablesSub = this.serverControlService.getNewsStatusListener().subscribe(
      serverControlStatus => {
        this.serverVariables = serverControlStatus;
        this.editServerVariablesForm.patchValue({
          editServerVariables: {
            freeHarmoniaFrequency: this.serverVariables.freeHarmoniaFrequency,
            CheckinHarmoniaAmount: this.serverVariables.CheckinHarmoniaAmount,
            CheckinDaysNeededToReset: this.serverVariables.CheckinDaysNeededToReset,
            maxDailyAccrual: this.serverVariables.maxDailyAccrual,
            maxGoldenRecord: this.serverVariables.maxGoldenRecord,
            dailyHarmoniaLimit: this.serverVariables.dailyHarmoniaLimit,
            SVRLivePassword: this.serverVariables.SVRLivePassword,
            SoundEscapePassword: this.serverVariables.SoundEscapePassword,
          },
          editServerVariablesMeta: {
            creator: this.userId
          }
        });
      }
    );



    // subscribe to form status changes
    this.formStatusChanges = this.editServerVariablesForm.statusChanges.subscribe(
      // (status) => console.log(status)
    );

    // subscribe to form value changes
    this.formValueChanges = this.editServerVariablesForm.valueChanges.subscribe(
      // (value) => {}
    );

    this.isLoading = false;
  }

  onSubmit(form) {
    console.log(`submitted`, form.value);
    this.isLoading = true;
    this.submitServerVariableUpdateForm(form.value);
  }

  async submitServerVariableUpdateForm(form) {
    console.log(`submit Edit Server Variables Update args`, form);
    const serverVariablesInput = {
      freeHarmoniaFrequency: form.editServerVariables.freeHarmoniaFrequency,
      CheckinHarmoniaAmount: form.editServerVariables.CheckinHarmoniaAmount,
      CheckinDaysNeededToReset: form.editServerVariables.CheckinDaysNeededToReset,
      maxDailyAccrual: form.editServerVariables.maxDailyAccrual,
      maxGoldenRecord: form.editServerVariables.maxGoldenRecord,
      dailyHarmoniaLimit: form.editServerVariables.dailyHarmoniaLimit,
      SVRLivePassword: form.editServerVariables.SVRLivePassword,
      SoundEscapePassword: form.editServerVariables.SoundEscapePassword,
      creator: form.editServerVariablesMeta.creator
    };
    // connect to server control service to push new values
    await this.serverControlService.updateServerVariables(serverVariablesInput);
    setTimeout(() => {
      this.isLoading = false;
    }, 800);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    this.formStatusChanges.unsubscribe();
    this.formValueChanges.unsubscribe();
    this.serverVariablesSub.unsubscribe();
  }

}
