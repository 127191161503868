<div [formGroup]="form" class="form">
    <label [attr.for]="formInput.key"></label>
    <div *ngIf="formInput.controlType === 'break'">
        <br>
    </div>
    <div *ngIf="formInput.controlType !== 'break'">
        <div 
            [ngSwitch]="formInput.controlType"
            [hidden]="formInput.hidden"
            [attr.display]="formInput.hidden ? 'none' : 'inline'"
        >
            <br>
            <mat-form-field *ngIf="formInput.controlType !== 'deletedTime' && formInput.controlType !== 'image' && formInput.controlType !== 'list'">
                <mat-label>
                    {{ formInput.label }}:
                </mat-label>
                <mat-select *ngSwitchCase="'dropdown'" [formControlName]="formInput.key" [id]="formInput.key" [required]="formInput.required" [title]="formInput.value">
                    <mat-option 
                        *ngFor="let opt of formInput.options" 
                        [value]="opt.key"
                        [title]="opt.key"
                    >
                        {{ opt.key }}
                    </mat-option>
                </mat-select>
                <mat-select *ngSwitchCase="'dropdown-multi'" [formControlName]="formInput.key" [id]="formInput.key" [required]="formInput.required" [title]="formInput.value" multiple>
                    <mat-select-trigger>
                      {{formInput.value ? formInput.value[0] : ''}}
                      <span *ngIf="formInput.value?.length > 1" class="example-additional-selection">
                        (+{{formInput.value.length - 1}})
                      </span>
                    </mat-select-trigger>
                    <mat-option
                        *ngFor="let opt of formInput.options" 
                        [value]="opt.value"
                        [title]="opt.value"
                    >
                    {{ opt.value }}
                    </mat-option>
                </mat-select>
                <mat-list *ngSwitchCase="'unordered-list'" role="list" [id]="formInput.key" [title]="formInput.value">
                    <mat-list-item
                        *ngFor="let listItem of formInput.value"
                        class="unordered-list_item"
                        role="listItem"
                    >
                        <input
                            matInput
                            class="unordered-list_input"
                            [required]="formInput.required"
                            [value]="listItem">
                    </mat-list-item>
                </mat-list>
                <div *ngSwitchCase="'checkbox'" >
                    <input 
                        matInput 
                        [hidden]="true" 
                        [formControlName]="formInput.key" 
                        [id]="formInput.key"
                    >
                    <mat-checkbox 
                        [title]="formInput.key"
                        [formControlName]="formInput.key"
                    ></mat-checkbox>
                </div>
                <textarea 
                    matInput  
                    *ngSwitchCase="'textArea'" 
                    [formControlName]="formInput.key"
                    [title]="formInput.key"
                    [id]="formInput.key" 
                    [type]="formInput.type"
                    [required]="formInput.required"
                ></textarea>
                <input 
                    matInput
                    *ngSwitchCase="'text'" 
                    [formControlName]="formInput.key"
                    [title]="formInput.key"
                    [id]="formInput.key"
                    [type]="formInput.type"
                    [required]="formInput.required">
                <input 
                    matInput
                    *ngSwitchCase="'number'" 
                    [formControlName]="formInput.key"
                    [title]="formInput.key"
                    [id]="formInput.key" 
                    [type]="formInput.type"
                    [required]="formInput.required">
                <div 
                    *ngSwitchCase="'date'" 
                    class="form-input-date-container"
                >
                    <input
                        class="form-input-date"
                        matInput 
                        [matDatepicker]="picker" 
                        [formControlName]="formInput.key"
                        [title]="formInput.key"
                        [id]="formInput.key" 
                        [type]="formInput.type"
                        [required]="formInput.required">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="form-input-date-toggle"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </div>
            </mat-form-field>
            <div 
                *ngSwitchCase="'deletedTime'"
            >
                <div class="deleted-time-container_false">
                    <button
                        *ngIf="formInput.value === null && deletedTime === null"
                        mat-raised-button 
                        color="warn"
                        class="category-card-soft-delete-button" 
                        (click)="softDeleteCategoryCardForm(form)"
                        type="button"
                    >Delete</button>
                </div>
                <div 
                    *ngIf="formInput.value !== null && deletedTime !== null" 
                    class="deleted-time-container_true" 
                >
                    <div>
                        <mat-label class="category-cart-item-subcategory-avatar-title">
                            {{ formInput.label }}:
                        </mat-label>
                        <p>{{ deletedTime }}</p>
                    </div>
                    <button
                        mat-raised-button 
                        color="accent"
                        class="category-card-soft-delete-button" 
                        (click)="unDeleteCategoryCardForm(form)"
                        type="button"
                    >Restore</button>
                </div>
            </div>
            <!-- <input 
                    *ngSwitchCase="'hidden'" 
                    [formControlName]="formInput.key"
                    [title]="formInput.key"
                    [id]="formInput.key"
                    [type]="formInput.type"
                    [required]="formInput.required"> -->
            <div 
                *ngSwitchCase="'image'"
            >
                <mat-label class="category-cart-item-subcategory-avatar-title">
                    {{ formInput.label }}:
                </mat-label>
                <div *ngIf="formInput.disabled === true">
                    <img class="image" src="{{ apiUrl }}/images/{{ formInput.value }}">
                </div>
                <div *ngIf="formInput.disabled === false || formInput.disabled === undefined">
                    <!-- <div *ngIf="formInput.value !== null || formInput.value !== ''">
                        <img src="{{ restUrl }}/{{ formInput.value }}">
                        <br>
                    </div> -->
                    <input 
                        
                        [title]="formInput.key"
                        [id]="formInput.key" 
                        [type]="formInput.type"
                        [required]="formInput.required"
                        (change)="onImageSelected($event)" 
                        class="image-input"
                        #imageUpload>
                    <div class="image-upload">
                        <img class="image" *ngIf="formInput.value" src="{{apiUrl}}/images/{{formInput.value}}">
                        <br *ngIf="formInput.value">
                        {{formInput.value || "No image uploaded yet."}}
                        <button 
                            mat-mini-fab 
                            color="accent" 
                            class="upload-btn"
                            type="button"
                            (click)="imageUpload.click()">
                            <mat-icon>attach_file</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
  
    <!-- <div class="errorMessage" *ngIf="!isValid && formInput.required === true">{{formInput.label}} is required</div> -->
</div>
