import { Injectable } from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

import { environment } from '../../../environments/environment';
import {Subject} from 'rxjs';

import {NewsItemModel} from '../models/NewsItem.model';
import {map} from 'rxjs/operators';
const BACKEND_URL = environment.apiUrl + '/news';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private newsLinks = [
    { path: 'latest-news', label: 'Latest News' },
  ];

  private newsStatusListener = new Subject<NewsItemModel[]>();
  private newsButtonFunctionsListener = new Subject<any[]>();

  // private newsFeed: NewsItemModel[];
  private newsFeed: NewsItemModel[];

  // private newsUpdated = new Subject<{news: NewsItemModel[], itemNumber: number}>();
  private newsUpdated = new Subject<{news: any, itemNumber: number}>();

  private buttonFunctions = [
    undefined
  ];

  constructor(private http: HttpClient, private router: Router) { }

  getNewsLinks() {
    return this.newsLinks;
  }

  getNewsStatusListener() {
    return this.newsStatusListener;
  }

  getNewsButtonFunctionsStatusListener() {
    return this.newsButtonFunctionsListener;
  }

  getLatestNews() {
    this.http.get<{ message: string; news: any, maxNewsItems: number }>(
      BACKEND_URL + '/getJSON'
    )
    .subscribe(response => {
      this.newsFeed = response.news;
      this.newsStatusListener.next(this.newsFeed);
    },
      error => {
        alert(error);
      },
      () => {
        console.log('get latest news - completed');
      }
    );
  }

  getLatestNewsButtonFunctions() {
    this.http.get<{ message: string; newsButtonFunctions: any, maxButtonFunctionsItems: number }>(
      BACKEND_URL + '/getButtonFunctions'
    )
      .subscribe(response => {
          this.buttonFunctions = response.newsButtonFunctions;
          this.buttonFunctions.push(
            {
              newsButtonFunctionId: response.newsButtonFunctions.length + 1,
              newsButtonFunctionName: undefined
            }
          );
          console.log(this.buttonFunctions)
          this.newsButtonFunctionsListener.next(this.buttonFunctions);
        },
        error => {
          alert(error);
          // this.newsButtonFunctionsListener.next([]);
        },
        () => {
          console.log('completed');
        }
      );
  }

  addNewsItem(newsInput) {
    this.http.post<{ newsUpdate: any }>(
      BACKEND_URL + '/updateNews',
      newsInput
    )
      .subscribe(
        response => {
          this.newsStatusListener.next(response.newsUpdate);
          // this.newsStatusListener.next(this.newsFeed);
          // this.router.navigate(['/']);
        },
        error => {
          alert(error);
        },
        () => {
          console.log('completed');
        }
      );
  }

  editNewsItem(newsInput) {
    const og = newsInput.slice();
    const newsItems = newsInput;

    // prepare outbound data for backend
    for (const newsItem of newsItems) {
      // set date to null if it is not entered in form
      if (newsItem.date === '' || newsItem.date === undefined || newsItem.date === null) {
        newsItem.date = null;
      }
      // set buttonText to null if it is not entered in form
      if (newsItem.buttonText === '' || newsItem.buttonText === undefined || newsItem.buttonText === null) {
        newsItem.buttonText = null;
        newsItem.buttonFunction = null;
        newsItem.newsLink = null;
      } else {
        // set buttonFunction ID via text name
        for (const buttonFunction of this.buttonFunctions) {
          if (newsItem.buttonFunction === buttonFunction.newsButtonFunctionName) {
            newsItem.buttonFunction = buttonFunction.newsButtonFunctionId;
          }
        }
        if (newsItem.buttonFunction > this.buttonFunctions.length - 1) {
          newsItem.buttonFunction = undefined;
        }
      }
    }
    this.http.post<{ newsUpdate: any }>(
      BACKEND_URL + '/updateNews',
      newsItems
    )
      .subscribe(
        response => {
          console.log('edit News Item Response');
          console.log(response)
          // prepare inbound data for frontend use
          for (const newsItem of response.newsUpdate) {

            // set buttonFunction ID via text name
            for (const buttonFunction of this.buttonFunctions) {
              if (newsItem.buttonFunction === buttonFunction.newsButtonFunctionId) {
                newsItem.buttonFunction = buttonFunction.newsButtonFunctionName;
              }
            }
            if (newsItem.buttonFunction > this.buttonFunctions.length - 1) {
              newsItem.buttonFunction = null;
            }
          }
          this.newsStatusListener.next(response.newsUpdate);
          return response;
        },
        error => {
          alert(error);
        },
        () => {
          console.log('completed');
        }
      );
  }
}
