import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserProfileService } from 'src/app/dash/services/user-profile.service';
import { FormInputControlService } from '../form-items/form-input-control.service';

@Component({
  selector: 'app-dynamic-add-form-group',
  templateUrl: './dynamic-add-form-group.component.html',
  styleUrls: ['./dynamic-add-form-group.component.css']
})
export class DynamicAddFormGroupComponent implements OnInit {
  @Injectable()parentForm
  @Injectable()formGroup
  @Injectable()categoryCardItem
  @Injectable()profileType
  @Injectable()editing
  @Injectable()mutation

  @Injectable()fragment

  formGroupType;
  newFormGroup;
  newFormGroupArray;
  newForm;

  categoryCardItems;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<DynamicAddFormGroupComponent>,
    public formInputControlService: FormInputControlService,
    public userProfileService: UserProfileService,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.parentForm = this.data.parentForm ? this.data.parentForm : new FormGroup({});
    this.categoryCardItem = this.data.categoryCardItem ? this.data.categoryCardItem : {};
    this.profileType = this.data.profileType;
    if (this.categoryCardItem.listItems && this.categoryCardItem.listItems.length > 0) {
      this.getFormGroupType(this.categoryCardItem.listItems[0].fragment);
    } else {
      this.getFormGroupType(this.data.fragment)
    }
    this.createNewForm(this.newFormGroupArray)
  }

  getFormGroupType(fragment) {
    console.log(fragment);
    console.log(this.profileType);
    switch(this.profileType) {
      case 'user': 
      case 'User': {
        console.log(fragment);
        this.newFormGroupArray = this.userProfileService.getFormGroupType(fragment);
        break;
      }  
    }
    return this.newFormGroupArray;
  }

  createNewForm(formInputModel) {
    console.log(formInputModel);
    const formGroups = this.formInputControlService.blankFormInputsToFormGroup(formInputModel);
    this.newForm = formGroups.newForm;
    this.categoryCardItems = formGroups.categoryCardListItems;
    console.log(this.newForm);
    console.log(this.categoryCardItems);

    return this.newForm;
  }

  addNewFormGroup(formOutput) {
    if (this.categoryCardItem.listItems && this.categoryCardItem.listItems.length < 1 && this.parentForm.controls.length < 1) {
      this.parentForm.controls.push(formOutput);
    } else if (this.categoryCardItem.listItems && this.categoryCardItem.listItems.length < 1) {
      this.parentForm.controls.splice(0, 1, formOutput);
    } else {
      this.parentForm.controls.push(formOutput);
    }

    const newFormGroupResult = {
      parentForm: this.parentForm,
      newFormGroupArray: this.newFormGroupArray,
      originalData: this.categoryCardItem
    }

    this.matDialogRef.close(newFormGroupResult);
  }

}
