import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';


const BACKEND_URL = environment.apiUrl + '/server-variables';

@Injectable({
  providedIn: 'root'
})
export class ServerControlService {
  private serverLinks = [
    { path: 'variables', label: 'Server Variables' },
    { path: 'command', label: 'Server Commands' },
  ];
  private serverVariables = {};
  private serverControlListener = new Subject<{}>();

  constructor(private http: HttpClient, private router: Router) { }

  getServerLinks() {
    return this.serverLinks;
  }

  getNewsStatusListener() {
    return this.serverControlListener;
  }
  getServerVariables() {
    this.http.get<{ message: string; serverVariables: any }>(
      BACKEND_URL + '/get'
    )
      .subscribe(response => {
        this.serverVariables = response.serverVariables;
        this.serverControlListener.next(this.serverVariables);
        return response;
      },
      error => {
        alert(error);
      },
        () => {
        console.log('get server variables - completed');
      }
    );
  }

  updateServerVariables(serverVariablesInput) {
    console.log(serverVariablesInput);
    this.http.post<{ serverVariables: any }>(
      BACKEND_URL + '/update',
      serverVariablesInput
    )
    .subscribe(
      response => {
        console.log(response);
        this.serverVariables = response.serverVariables;
        this.serverControlListener.next(response.serverVariables);
        return response;
      },
      error => {
        alert(error);
      },
      () => {
        console.log('update server variables - completed');
      }
    );
  }
}
