import { Component, OnInit } from '@angular/core';
import {ErrorComponent} from '../error.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  errorMessage = 'Page Not Found';

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.dialog.open(ErrorComponent, {data: {message: this.errorMessage}});
  }

}
