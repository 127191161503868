<div class="dynamic-form-container">
    <!-- <div class="category-card-edit-button-container">
        <button 
          mat-raised-button
          class="category-card-edit-button" 
          (click)="editCategoryCardForm()"
          *ngIf="editing === false"
        >
            Edit
        </button>
    </div> -->
    <form (ngSubmit)="onSubmit()" [formGroup]="parentForm" class="form">
        
        <div *ngFor="let formInput of formInputs.controls; index as index" class="form-row">
            <app-dynamic-form-input [formInput]="formInput" [form]="parentForm" [editing]="editing"></app-dynamic-form-input>
        </div>
        <!-- <div class="form-action-row">
            <div *ngIf="editing === true">
                <button mat-flat-button class="form-submit-button" color="accent" type="submit" [disabled]="!formGroup.valid">Save {{ formInputs.title }}</button>
                <button mat-flat-button class="form-cancel-button" (click)="editCategoryCardForm()">Cancel Editing</button>
            </div>
        </div>         -->
    </form>
    <div class="form-output-row">
        <div *ngIf="payLoad" class="form-output-row">
            <strong>Saved the following values</strong>
            <br>
            {{payLoad}}
            <br>
        </div>
    </div>
</div>