import {Component, OnInit} from '@angular/core';
import {AuthService} from './core/auth/auth.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Soundscape VR Admin Panel v1.0';
  private authStatus;

  constructor(public authService: AuthService) {}

  ngOnInit() {
    this.authStatus = this.authService.autoAuthUser();
  }

}
