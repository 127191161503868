import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DirectoryTypeModel } from '../models/directoryType.model';

@Injectable()
export abstract class DirectoryService {
    abstract dataTableColumnsArray: {field: string, width: number}[];
    abstract dataTable: DirectoryTypeModel[] = [];
    public width: number = 350;

    protected constructor(
        protected http: HttpClient,
    ) {}

    getDirectoryItemArray(BACKEND_URL) {
        console.log(BACKEND_URL);
        return this.http.post<{ data: any; loading: boolean, success: boolean;  }>(
          BACKEND_URL + '/get',
          {skip: 160, take: 100}
        )
        .pipe(
            map((responseData) => {
                console.log('responseData', responseData);
                const directoryData = this.buildDirectoryResponseObject(responseData);
                directoryData.loading = responseData.loading;
                return directoryData;
            })
             
        )
      }

    private buildDirectoryResponseObject(responseData) {
        let rawResponseArray = [];
        let directoryItemArray: DirectoryTypeModel[] = [];
        let directoryColumnObjectsArray: {field: string, width: number}[] = [];
        let directoryColumnsHeaders: string[] = [];

        rawResponseArray = this.transformRawResponseDataToArray(responseData);
        
        directoryItemArray = this.extractDirectoryObjectFromArray(rawResponseArray);

        directoryColumnsHeaders = this.extractDirectoryColumnHeadersFromArrayObject(directoryItemArray);

        directoryColumnObjectsArray = this.createColumnObjectsFromHeaders(directoryColumnsHeaders, this.width);
        
        const directoryResponse = this.formatDirectoryResponseObject(directoryItemArray,  directoryColumnObjectsArray, directoryColumnsHeaders);

        return directoryResponse;
    }
    private transformRawResponseDataToArray(responseData) {
        const rawResponseArray = [];
    
        for (const key in responseData) {
          if (responseData.hasOwnProperty(key)) {
            rawResponseArray.push({ ...responseData[key], id: key})
          }
        }
    
        return rawResponseArray;
    }
    private extractDirectoryObjectFromArray(rawResponseArray) {
        const directoryItemArray: any[] = [];  
        // const directoryItemArray: DirectoryTypeModel[] = [];  
        const items: any = Object.values(rawResponseArray[0])[0];
        for (let item of items) {
            directoryItemArray.push(item);
        }

        return directoryItemArray;
    }
    private extractDirectoryColumnHeadersFromArrayObject(directoryItemArray) {
        const directoryColumnsHeaders: string[] = ['select', 'profile'];

        Object.getOwnPropertyNames(directoryItemArray[0]).map(property => {
            if (property !== '__typename') {
            directoryColumnsHeaders.push(property);
            } else {
            skip;
            }
        })
        return directoryColumnsHeaders;
    }
    private createColumnObjectsFromHeaders(directoryColumnsHeaders, width) {
        const directoryColumnsArray: {field: string, width: number}[] = [];

        for (let property of directoryColumnsHeaders) {
            if (property !== '__typename') {
            directoryColumnsArray.push({
                field: property,
                width: width,
            });
            } else {
            break;
            }
        }

        return directoryColumnsArray;
    }
    private formatDirectoryResponseObject(directoryObjectArray, directoryColumnsArray, directoryColumnsHeadersArray) {
        const directoryResponseObject = {
          dataTable: directoryObjectArray,
          dataTableColumns: directoryColumnsArray,
          directoryColumnsHeaders: directoryColumnsHeadersArray,
          loading: true,
        }
        return directoryResponseObject;
    }
}