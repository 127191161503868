import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { HomepageComponent } from '../dash/views/homepage/homepage.component';
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', component: HomepageComponent},

  { path: 'auth', loadChildren: () => import('src/app/core/auth/auth.module').then(m => m.AuthModule) },
  { path: 'dash', loadChildren: () => import('src/app/dash/dash.module').then(m => m.DashModule) },

  // Keep these at the bottom!  Wildcard will override any paths below it
  { path: 'not-found', component: PageNotFoundComponent},
  { path: '**', redirectTo: '/not-found'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
