import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserDirectoryService } from 'src/app/dash/services/user-directory.service';
import { DirectoryViewComponent } from 'src/app/shared/components/directory/view/directory-view/directory-view.component';
import { FormInputControlService } from 'src/app/shared/components/profile/profile/forms/form-items/form-input-control.service';
import { ProfileComponent } from 'src/app/shared/components/profile/profile/profile.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-directory-view',
  templateUrl: './user-directory-view.component.html',
  styleUrls: ['./user-directory-view.component.css']
})
export class UserDirectoryViewComponent extends DirectoryViewComponent implements OnInit, OnDestroy {
  @Input() dataSourceType: string = 'user';
  @Input() dataTable: any[] = [];
  // @Input() dataTable: UserModel[] = [];

  public BACKEND_URL = environment.apiUrl + '/users';

  public directorySubscription: Subscription;
  
  constructor(
    public userDirectoryService: UserDirectoryService,
  ) {
    super(userDirectoryService);
  }
}
