import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../core/auth/auth.service';
import {MessagingService} from '../../../../services/messaging.service';
import {GiftingService} from '../../../../services/gifting.service';

@Component({
  selector: 'app-gift-blast-form',
  templateUrl: './gift-blast-form.component.html',
  styleUrls: ['./gift-blast-form.component.css']
})
export class GiftBlastFormComponent implements OnInit, OnDestroy {
  isLoading = true;
  authStatusSub: Subscription;
  userId: string;

  audienceGroups;

  validationErrors: [];
  giftBlastForm = new FormGroup({
    giftBlastContent: new FormGroup({
      body: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(160)]),
      amount: new FormControl(null, [Validators.required])
    }),
    giftBlastAudience: new FormGroup({
      audienceGroups: new FormControl(null, [Validators.required]),
    }),
    giftBlastMeta: new FormGroup({
      creator: new FormControl(null, [Validators.required]),
    })
  });
  hasError = false;

  constructor(
    private authService: AuthService,
    private giftingService: GiftingService
  ) { }

  ngOnInit(): void {
    // get's logged in user, subscribes to auth status and stop loading when connected
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      authStatus => {}
    );
    // automatically sets author to logged in user
    this.userId = this.authService.getUserId();
    this.giftBlastForm.patchValue({
      giftBlastMeta: {
        creator: this.userId
      }
    });

    this.audienceGroups = this.giftingService.getAudienceGroups();

    // subscribe to form status changes
    this.giftBlastForm.statusChanges.subscribe(
      // (status) => console.log(status)
    );

    // subscribe to form value changes
    this.giftBlastForm.valueChanges.subscribe(
      // (value) => console.log(value)
    );

    this.isLoading = false;
  }

  onSubmit(form) {
    console.log(`submitted`, form.value);
    this.isLoading = true;
    this.submitGiftBlastForm(form.value);
  }

  submitGiftBlastForm(form) {
    console.log(`submit mutation args`, form);
    const giftBlastInput = {
      message: form.giftBlastContent.body,
      amount: form.giftBlastContent.amount,
      audience: form.giftBlastAudience.audienceGroups,
      creator: form.giftBlastMeta.creator,
    };
    this.isLoading = false;
    this.giftingService.sendGift(giftBlastInput);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}
