import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.css']
})
export class ActionPanelComponent implements OnInit {
  @Input() actionPanelContent;
  @Input() profileType;

  constructor() { }

  ngOnInit(): void {
  }

  onClickAction(actionName) {
    console.log(actionName);
  }

}
