import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from '../core/auth/auth.guard';
import {InfoPaneViewComponent} from './views/info-pane-view/info-pane-view.component';
import {ServerControlViewComponent} from './views/server-control-view/server-control-view.component';
import {MessagesViewComponent} from './views/messages-view/messages-view.component';
import {ReportsViewComponent} from './views/reports-view/reports-view.component';
import {GiftsViewComponent} from './views/gifts-view/gifts-view.component';
import {LatestNewsFormComponent} from './components/forms/info-pane/latest-news-form/latest-news-form.component';
import {ArtistEntryFormComponent} from './components/forms/info-pane/artist-entry-form/artist-entry-form.component';
import {ArtistScheduleFormComponent} from './components/forms/info-pane/artist-schedule-form/artist-schedule-form.component';
import {ArtistListComponent} from './components/info-pane/artist-list/artist-list.component';
import {ServerVariablesFormComponent} from './components/forms/server-control/server-variables-form/server-variables-form.component';
import {ServerCommandsFormComponent} from './components/forms/server-control/server-commands-form/server-commands-form.component';
import {MessageBlastFormComponent} from './components/forms/messages/message-blast-form/message-blast-form.component';
import {PrintReportFormComponent} from './components/forms/reports/print-report-form/print-report-form.component';
import {GiftBlastFormComponent} from './components/forms/gifts/gift-blast-form/gift-blast-form.component';
import { UserDirectoryViewComponent } from './views/user-view/user-directory-view/user-directory-view.component';
import { UserViewComponent } from './views/user-view/user-view.component';
import { UserProfileViewComponent } from './views/user-view/user-profile-view/user-profile-view.component';
import { DynamicCreateFormComponent } from '../shared/components/profile/profile/forms/dynamic-create-form/dynamic-create-form.component';
import { MuseViewComponent } from './views/muse-view/muse-view.component';
import { GetStreamComponent } from './components/muse/get-stream/get-stream.component';
import { MagicMirrorLinksComponent } from './components/muse/magic-mirror-links/magic-mirror-links.component';
import { VideoStatsComponent } from './components/muse/video-stats/video-stats.component';
import { RoleGuard } from '../core/auth/role.guard';
import { MyViewComponent } from './views/my-view/my-view.component';

const routes: Routes = [
  // Game Info Pane
  {
    path: 'info-pane',
    component: InfoPaneViewComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: 'employee' },
    children: [
      { path: 'latest-news', component: LatestNewsFormComponent, canActivate: [AuthGuard, RoleGuard], data: { role: 'employee' } },
      { path: 'artist-entry', component: ArtistEntryFormComponent, canActivate: [AuthGuard, RoleGuard], data: { role: 'employee' } },
      { path: 'artist-entry/:artistId', component: ArtistEntryFormComponent, canActivate: [AuthGuard, RoleGuard], data: { role: 'employee' } },
      { path: 'artist-schedule', component: ArtistScheduleFormComponent, canActivate: [AuthGuard, RoleGuard], data: { role: 'employee' } },
      { path: 'artist-schedule/:artistId', component: ArtistScheduleFormComponent, canActivate: [AuthGuard, RoleGuard], data: { role: 'employee' } },
      { path: 'artist-list', component: ArtistListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: 'employee' } },
    ]
  },
  // Server Controls
  {
    path: 'server-control',
    component: ServerControlViewComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: 'employee' },
    children: [
      { path: 'variables', component: ServerVariablesFormComponent, canActivate: [AuthGuard] },
      { path: 'command', component: ServerCommandsFormComponent, canActivate: [AuthGuard] },
    ]
  },
  // Messaging
  {
    path: 'messages',
    component: MessagesViewComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: 'employee' },
    children: [
      { path: 'blast', component: MessageBlastFormComponent, canActivate: [AuthGuard] },
    ]
  },
  // Reporting
  {
    path: 'reports',
    component: ReportsViewComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: 'employee' },
    children: [
      { path: 'print-new', component: PrintReportFormComponent, canActivate: [AuthGuard] },
    ]
  },
  // Gifting
  {
    path: 'gifts',
    component: GiftsViewComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: 'employee' },
    children: [
      { path: 'blast', component: GiftBlastFormComponent, canActivate: [AuthGuard] },
    ]
  },
  // Users
  { 
    path: 'user', 
    component: UserViewComponent, 
    canActivate: [AuthGuard, RoleGuard],
    data: { role: 'admin' },
    children: [
      { 
        path: 'directory', 
        component: UserDirectoryViewComponent, 
        canActivate: [AuthGuard, RoleGuard],
        data: { role: 'admin' },
      },
      { 
        path: 'profile/:id', 
        component: UserProfileViewComponent, 
        canActivate: [AuthGuard, RoleGuard],
        data: { role: 'admin' },
      },
      {
        path: 'create', 
        component: DynamicCreateFormComponent, 
        canActivate: [AuthGuard, RoleGuard],
        data: { role: 'admin' },
        // data: { 
        //   breadcrumb: 'Create',
        //   fragment: 'patient',
        // },
      },
    ] 
  },
  // MUSE
  {
    path: 'muse',
    component: MuseViewComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: 'employee' },
    children: [
      { path: 'get-stream', component: GetStreamComponent, canActivate: [AuthGuard, RoleGuard], data: { role: 'employee' }, },
    ]
  },
  // MY USER
  {
    path: 'my',
    component: MyViewComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: 'player' },
    children: [
      { path: 'profile/:id', component: UserProfileViewComponent, canActivate: [AuthGuard, RoleGuard], data: { role: 'player' }, },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class DashRoutingModule {}

