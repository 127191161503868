import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  selector: 'app-single-item-form',
  templateUrl: './single-item-form.component.html',
  styleUrls: ['./single-item-form.component.css']
})
export class SingleItemFormComponent implements OnInit {
  @Input() categoryCardItem;
  @Input() profileType;

  @Input() editing = false;

  @Input() mutation;

  @Input() parentForm: FormGroup;
  @Input() formGroup: FormGroup;

  
  @ViewChild(MatAccordion) accordion: MatAccordion;

  panelOpenState = false;

  constructor(
  ) { }

  ngOnInit(): void {
  }


  editCategoryCardForm(form) {
    console.log(form)
  }
}
