import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatFooterRow, MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel} from '@angular/cdk/collections';
import { Router } from '@angular/router';

@Component({
  selector: 'app-directory-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements AfterViewInit, OnInit {
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatFooterRow) footer: MatFooterRow;

  @Input()fetchedSource;
  @Input()fetchedSourceType;
  @Input()fetchedSourceColumns;
  @Input()isLoading;
  @Input()directoryColumnsHeaders;
  @Input()error = null;
  loading = true;

  columns: { field: string, width: number, }[] = [];
  displayedColumns: string[] = [];

  // Selecting
  selection = new SelectionModel<any>(true, []);

  constructor(
    private router: Router,
  ) {
  }


  ngOnInit() {
    console.log('table source', this.fetchedSource, this.fetchedSourceType, this.fetchedSourceColumns);
   
    for (let column of this.fetchedSourceColumns) {
      this.columns.push(column);
    }

    this.dataSource = new MatTableDataSource(this.fetchedSource);
    console.log('dataSource', this.dataSource);
    this.loading = this.isLoading;
  }

  ngAfterViewInit() {
    // Fill out table with data, sort, and paginator
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Drag & Drop columns
    // Resizing columns
    
    console.log('paginator pageSize', this.paginator.pageSize)
    console.log('paginator skip', (this.paginator.pageIndex) * this.paginator.pageSize)
  }


  // **Filtering**
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addFilter() {}

  //  **Selection**
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  navigate(itemId, fragArg) {
    console.log(this.fetchedSourceType);
    console.log(itemId);
    console.log(fragArg);
    let fragment: string;
    if (fragArg !== null) {
      fragment = fragArg;
      this.router.navigate([`dash/${this.fetchedSourceType}/profile/`, itemId], {fragment: fragment})
    } else {
      this.router.navigate([`dash/${this.fetchedSourceType}/profile/${itemId}`])
    }
  }

  indentify(index, item) {
    return item.id
  }


}
