<div class="admin-form-tab-group">
    <nav mat-tab-nav-bar [backgroundColor]="background" [color]="color">
      <a
        mat-tab-link
        class="admin-form-tab"
        *ngFor="let link of links"
        (click)="activeLink = link.path"
        [active]="activeLink == link.path"
        [routerLink]="[link.path]"
        [disabled]=""
      > {{ link.label }}</a>
    </nav>
  </div>
  <h3
    *ngIf="!activeLink"
    class="admin-form-tab-start-text"
  >Click a tab to get started</h3>
  <router-outlet></router-outlet>