import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormComponent} from './components/directory/form/form.component';
import {DirectoryProfileComponent} from './components/directory/profile/profile.component';
import {TableComponent} from './components/directory/table/table.component';
import {ItemComponent} from './components/directory/profile/item/item.component';
import {QuickComponent} from './components/directory/profile/quick/quick.component';
import {MainComponent} from './components/directory/profile/main/main.component';
import { ActionPanelComponent } from './components/profile/profile/action-panel/action-panel.component';
import { CategoryCardListComponent } from './components/profile/profile/category-card-list/category-card-list.component';
import { CategoryCardItemComponent } from './components/profile/profile/category-card-list/category-card-item/category-card-item.component';
import { ProfileComponent } from './components/profile/profile/profile.component';
import { SingleItemFormComponent } from './components/profile/profile/forms/single-item-form/single-item-form.component';
import { RouterModule } from '@angular/router';
import { DirectoryActionsComponent } from './components/directory/directory-actions/directory-actions.component';
import { DynamicFormInputComponent } from './components/profile/profile/forms/dynamic-form-input/dynamic-form-input.component';
import { DynamicFormComponent } from './components/profile/profile/forms/dynamic-form/dynamic-form.component';
import { DynamicAddFormGroupComponent } from './components/profile/profile/forms/dynamic-add-form-group/dynamic-add-form-group.component';
import { AngularMaterialModule } from '../core/shared/angular-material.module';
import { DynamicCreateFormComponent } from './components/profile/profile/forms/dynamic-create-form/dynamic-create-form.component';


@NgModule({
  declarations: [
    FormComponent,
    DirectoryProfileComponent,
    TableComponent,
    ItemComponent,
    QuickComponent,
    MainComponent,
    ActionPanelComponent,
    CategoryCardListComponent,
    CategoryCardItemComponent,
    SingleItemFormComponent,
    DirectoryActionsComponent,
    DynamicFormInputComponent,
    DynamicFormComponent,
    DynamicAddFormGroupComponent,
    DynamicCreateFormComponent,
  ],
  entryComponents: [
    DynamicAddFormGroupComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    FormComponent,
    DirectoryProfileComponent,
    TableComponent,
    ItemComponent,
    QuickComponent,
    MainComponent,
    ActionPanelComponent,
    CategoryCardListComponent,
    CategoryCardItemComponent,
    SingleItemFormComponent,
    DirectoryActionsComponent,
    DynamicFormInputComponent,
    DynamicFormComponent,
  ]
})
export class SharedModule { }
