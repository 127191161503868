<mat-card class="product-form-card">
  <h1 class="mat-display-1">Create Message Blast</h1>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <form
    action=""
    class="news-form"
    [formGroup]="messageBlastForm"
    (ngSubmit)="onSubmit(messageBlastForm)"
    *ngIf="!isLoading"
  >
    <!--    Content     -->
    <div
      formGroupName="messageBlastContent"
      class="form-control-group"
    >
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Message Body</mat-label>
        <input
          matInput
          type="text"
          name="body"
          formControlName="body"
          id="body"
          label="body"
          placeholder="Enter the message body here"
        >
      </mat-form-field>
      <br>
    </div>
    <!--    Button     -->
    <div
      formGroupName="messageBlastAudience"
      class="form-control-group"
    >
      <div class="button-display">
        <mat-form-field
          appearance="fill"
          class="form-control"
        >
          <mat-label>Recipient Audience Group</mat-label>
          <mat-select
            formControlName="audienceGroups"
          >
            <mat-option *ngFor="let audienceGroup of audienceGroups" value="{{ audienceGroup }}">{{ audienceGroup }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!--    Meta     -->
    <div
      formGroupName="messageBlastMeta"
      class="form-control-group"
    >
      <mat-form-field [hidden]="true">
        <mat-label>Creator</mat-label>
        <input
          matInput
          value="{{ userId }}"
          name="creator"
          formControlName="creator"
          id="creator"
          label="creator"
        >
      </mat-form-field>
    </div>
    <button class="btn mat-raised-button mat-primary" type="submit" *ngIf="!hasError">Send Message Blast!</button>
  </form>
</mat-card>
