import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileFormModel } from 'src/app/shared/models/profileForm.model';

import { BaseFormInput } from './base-form-input';

@Injectable({
  providedIn: 'root'
})
export class FormInputControlService {
  constructor() { }
  categoryCardListToFormGroup(categoryCardListItems) {
    // console.log('categoryCardListToFormGroups', categoryCardListItems)
    const form: any = {};

    categoryCardListItems.forEach(categoryCardListItem => {
      if (categoryCardListItem.type === "single") {
        // console.log('inside single', categoryCardListItem)
        const formGroup = this.categoryCardItemToFormGroup(categoryCardListItem);
        form[categoryCardListItem.fragment] = new FormGroup(formGroup);
      }

      if (categoryCardListItem.type === "list") {
        // console.log('inside list', categoryCardListItem)
        const formArray = []
        categoryCardListItem.listItems.forEach(listItem => {
          if (listItem.type === "single") {
            // console.log('categoryCardListToFormGroup - list > single', categoryCardListItem)
            const formGroup = this.categoryCardItemToFormGroup(listItem);
            formArray.push(new FormGroup(formGroup))
          }
          if (listItem.type === "list") {
            const formGroup: any = {};
            // console.log('categoryCardListToFormGroup - list > list', categoryCardListItem)
            if (categoryCardListItem.listItems.length > 0) {
              // console.log('categoryCardListToFormGroup - list > list > full', categoryCardListItem.listItems)
              // categoryCardListItem.listItems.forEach(listItem => {
              // console.log('categoryCardListToFormGroup - list > list > full', listItem)
              const formGroupChild = this.categoryCardItemToFormGroup(listItem);
              // // console.log('categoryCardListToFormGroup - list > list > full > post formGroupCreation', formGroupChild)
              formGroup[listItem.fragment] = new FormGroup(formGroupChild)
              // })
              formArray.push(new FormGroup(formGroup))
              // // console.log('categoryCardListToFormGroup - list > list > full > post formArrayCreation', formArray)
            } else {
              // console.log('NO ITEMS')
            }
          }
        })
        form[categoryCardListItem.fragment] = new FormArray(formArray);
        // // console.log('categoryCardListToFormGroup', form)
      }
      // console.log('categoryCardListToFormGroup', form)
    });

    const profileForm = new FormGroup(form);
    // console.log(profileForm);

    return profileForm;
  }

  categoryCardItemToFormGroup(categoryCardListItem) {
    const formGroup: any = {};
    // console.log('inside categoryCardItemToFormGroup', categoryCardListItem)
    if (categoryCardListItem.type === "single" && !categoryCardListItem.controls) {
      categoryCardListItem.subcategories.forEach(subcategory => {
        if (subcategory.controls) {
          // console.log('categoryCardItemToFormGroup - single > inside if', subcategory)
          formGroup[subcategory.fragment] = this.formInputsToFormGroup(subcategory.controls);
        } else if (!subcategory.controls && subcategory.listItems) {
          // console.log('categoryCardItemToFormGroup - single > inside else if', subcategory)
          formGroup[subcategory.fragment] = this.categoryCardListItemToFormArray(subcategory);
        }
      })
    }
    if (categoryCardListItem.type === "list") {
      categoryCardListItem.subcategories.forEach(subcategory => {
        // // console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
        // console.log('categoryCardItemToFormGroup - list', subcategory)
        formGroup[subcategory.fragment] = this.categoryCardListItemToFormArray(subcategory.listItems);
      })
    }

    return formGroup
  }

  categoryCardListItemToFormArray(categoryCardListItem) {
    const formArray: any = []
    // console.log('categoryCardListItemToFormArray', categoryCardListItem);
    if (categoryCardListItem.listItems.length === 0) {
      const formGroupSub = {
        field: new FormControl('')
      };
      formArray.push(new FormGroup(formGroupSub));
    }
    if (categoryCardListItem.listItems.length > 0) {
      categoryCardListItem.listItems.forEach(listItem => {
        if (categoryCardListItem.type === "single") {
          // // console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
          // console.log('arraySingle - cardList', categoryCardListItem);
          // console.log('arraySingle - listItem', listItem);
          const formGroup = this.categoryCardItemToFormGroup(listItem);
          formArray.push(new FormGroup(formGroup));
        }
        if (categoryCardListItem.type === "list") {
          // console.log('arrayList - cardList', categoryCardListItem);
          // console.log('arrayList - listItem', listItem);
          const formGroupChild = this.categoryCardItemToFormGroup(listItem);
          formArray.push(new FormGroup(formGroupChild));
        }
      })
    }
    // console.log('categoryCardListItemToFormArray', formArray);
    return new FormArray(formArray);
  }

  formInputsToFormGroup(formInputs) {
    let group: any = {};

    if (formInputs[0].controls) {
      let formInputGroup = formInputs;
      for (let subcategory of formInputGroup) {
        let subcategoryGroup = {};
        subcategory.controls.forEach(formInput => {
          subcategoryGroup[formInput.key] = formInput.required ? new FormControl({value: formInput.value || '', disabled: formInput.disabled}, Validators.required,) : new FormControl({value: formInput.value || '', disabled: formInput.disabled});
        });
        group[subcategory.fragment] = new FormGroup(subcategoryGroup)
      }
    } else {
      formInputs.forEach(formInput => {
        // console.log(formInput)
        group[formInput.key] = formInput.required ? new FormControl({value: formInput.value || '', disabled: formInput.disabled}, Validators.required,) : new FormControl({value: formInput.value || '', disabled: formInput.disabled});
      });
    }
    // console.log(group);

    return new FormGroup(group);
  }

  blankFormInputsToFormGroup(categoryCardListItems) {
    // console.log(categoryCardListItems)
    let formGroup: any = {};
    for (let categoryCardListItem of categoryCardListItems) {
      // console.log(categoryCardListItem);
      if (categoryCardListItem.type === "single" && categoryCardListItem.controls) {
        // console.log('a');
        formGroup[categoryCardListItem.fragment] = this.formInputsToFormGroup(categoryCardListItem.controls);
      } else if (categoryCardListItem.type === "single" && categoryCardListItem.subcategories) {
        // console.log('b');
        formGroup[categoryCardListItem.fragment] = this.formInputsToFormGroup(categoryCardListItem.subcategories);
      } else if (categoryCardListItem.type === "list") {
        // console.log('c');
        formGroup[categoryCardListItem.fragment] = new FormArray([]);
      }
    }
    let newForm = new FormGroup(formGroup)
    return {
      newForm: newForm,
      categoryCardListItems: categoryCardListItems
    };
  }
}