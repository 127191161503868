import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MuseService } from '../../services/muse.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-muse-view',
  templateUrl: './muse-view.component.html',
  styleUrls: ['./muse-view.component.css']
})
export class MuseViewComponent implements OnInit {
  links = [];
  activeLink;
  background: ThemePalette = 'primary';
  color = '#00000';

  constructor(
    private router: Router,
    private museService: MuseService,
  ) { }

  ngOnInit(): void {
    const museLinks = this.museService.getMuseLinks();
    this.links.push(...museLinks);
    this.activeLink = this.router.url.replace("/dash/muse", '');
  }
}
