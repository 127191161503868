<mat-spinner *ngIf="loading === true"></mat-spinner>
<div class="table-container" *ngIf="loading === false">
  <div class="mat-elevation-z8">

    <div class="table-card__header">
      <h2>{{ fetchedSourceType.charAt(0).toUpperCase() + fetchedSourceType.slice(1) }} Directory</h2>
      <app-directory-actions
        [fetchedSourceType]="fetchedSourceType"
        [selection]="selection"
      ></app-directory-actions>
    </div>
    <div *ngIf="error">
      <h1>An error ocurred!</h1>
      <p>{{ error }}</p>
    </div>
    <mat-card>
      <mat-card-header>
        <div class="directory-filter-container">
          
          <mat-form-field class="directory-filter">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
          </mat-form-field>
          <button mat-button mat-stroked-button class="directory-filter-button" (click)="addFilter()" disabled="true">
            + Add Filter
          </button>
        </div>
      </mat-card-header>
      <table
        mat-table
        matSort
        class="full-width-table"
        aria-label="Elements"
        *ngIf="dataSource"
      >
        <mat-header-row 
          *matHeaderRowDef="directoryColumnsHeaders" 
        >
        </mat-header-row>
        <mat-row 
          *matRowDef="let row; columns: directoryColumnsHeaders;"
          (click)="selection.toggle(row)"
        >
        </mat-row>
        <ng-container *ngFor="let column of directoryColumnsHeaders; trackBy: indentify" [matColumnDef]="column">
          <div *ngIf="column === 'profile'">
            <mat-cell mat-cell *matCellDef="let row;">
              <a mat-stroked-button (click)="navigate(row.id, null)" class="profile-button">View</a>
            </mat-cell>
          </div>
          <div class="select-column" *ngIf="column === 'select'">
            <mat-header-cell mat-header-cell *matHeaderCellDef class="select-cell">
              <mat-checkbox 
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let row;" class="select-cell">
              <mat-checkbox 
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </mat-cell>
          </div>
          <mat-header-cell 
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.charAt(0).toUpperCase() + column.slice(1) }}
          </mat-header-cell>
          <mat-cell *matCellDef="let object" >
            <div 
              class="directory-cell-text"
              mouseover=""
              style="cursor: pointer;"
              (click)="navigate(object.id, column)"
            >
              {{ object[column] }}
            </div>
          </mat-cell>
        </ng-container>
        
  <!--      <tr class="mat-row" *matNoDataRow>-->
  <!--        <td class="mat-cell" >No data matching the filter "{{input.value}}"</td>-->
  <!--      </tr>-->
  <!--      <mat-footer-row mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>-->
      </table>
      <mat-paginator #paginator
                     [length]="dataSource?.data.length"
                     [pageIndex]="0"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 20, 50]">
      </mat-paginator>
    </mat-card>
  </div>
</div>