import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../core/auth/auth.service';
import {ArtistService} from '../../../../services/artist.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-artist-schedule-form',
  templateUrl: './artist-schedule-form.component.html',
  styleUrls: ['./artist-schedule-form.component.css']
})
export class ArtistScheduleFormComponent implements OnInit, OnDestroy {
  isLoading = true;
  authStatusSub: Subscription;
  editing: boolean = false;

  artists;
  performanceSub: Subscription;
  artistId;
  performance;
  performanceId: string;
  itemNumber = 0;

  validationErrors: [];
  editPerformanceForm: FormGroup;

  buttonText: string;

  userId: string;

  hasError = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private artistService: ArtistService
  ) { }

  ngOnInit(): void {
    // get's logged in user, subscribes to auth status and stop loading when connected
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      authStatus => {}
    );
    // automatically sets author to logged in user
    this.userId = this.authService.getUserId();
    this.artists = this.artistService.getArtists();
    this.performanceSub = this.route.params.subscribe(
      value => {
        console.log(value);
        if (value.editing !== undefined) {
          console.log('editing is true');
          this.editing = value.editing;
          this.artistId = value.artist;
          this.performance = this.artistService.getLatestPerformance(this.itemNumber - 1, this.artistId);
          console.log(this.performance);
        }
      }
    );
    console.log(this.artistId);


    console.log('performance', this.performance);
    this.buttonText = this.performance ? this.performance.buttonText : 'Example Button Text';

    // checks for existing object and determines editing mode or not
    if (this.performance !== undefined) {
      this.editing = true;
      this.performanceId = this.performance._id;
    }

    // build the form, changing validation logic depending on editing mode
    if (!this.editing) {
      this.editPerformanceForm = new FormGroup({
        editPerformanceContent: new FormGroup({
          body: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(160)]),
          artist: new FormControl(null, [Validators.required])
        }),
        editPerformanceButton: new FormGroup({
          buttonText: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(80)]),
        }),
        editPerformanceMeta: new FormGroup({
          creator: new FormControl(null, [Validators.required]),
          performanceId: new FormControl(null)
        })
      });
    } else {
      this.editPerformanceForm = new FormGroup({
        editPerformanceContent: new FormGroup({
          body: new FormControl(null, [Validators.minLength(4), Validators.maxLength(160)]),
          artist: new FormControl(null)
        }),
        editPerformanceButton: new FormGroup({
          buttonText: new FormControl(null, [Validators.minLength(4), Validators.maxLength(80)]),
        }),
        editPerformanceMeta: new FormGroup({
          creator: new FormControl(null),
          performanceId: new FormControl(null)
        })
      });
    }

    // if editing mode is on, fill the form with existing values from server
    // in either case, add the creator by reading the current userId
    if (this.editing) {
      this.editPerformanceForm.patchValue({
        editPerformanceContent: {
          body: this.performance.body,
          artist: this.performance.artist.name
        },
        editPerformanceButton: {
          buttonText: this.performance.buttonText
        },
        editPerformanceMeta: {
          performanceId: this.performanceId
        }
      });
    }
    this.editPerformanceForm.patchValue({
      editPerformanceMeta: {
        creator: this.userId,
      }
    });

    // subscribe to form status changes
    this.editPerformanceForm.statusChanges.subscribe(
      // (status) => console.log(status)
    );

    // subscribe to form value changes
    this.editPerformanceForm.valueChanges.subscribe(
      (value) => {
        this.buttonText = value.editPerformanceButton.buttonText;
      }
    );

    this.isLoading = false;
  }

  onSubmit(form) {
    console.log(`submitted`, form.value);
    this.isLoading = true;
    if (this.editing) {
      return this.submitEditPerformanceFormMutation(form.value);
    }
    this.submitAddPerformanceFormMutation(form.value);
  }

  submitAddPerformanceFormMutation(form) {
    console.log(`submit Add Performance args`, form);
    const performanceInput = {
      body: form.editPerformanceContent.body,
      artist: form.editPerformanceContent.artist,
      buttonText: form.editPerformanceButton.buttonText,
      performanceId: form.editPerformanceMeta.performanceId,
      creator: form.editPerformanceMeta.creator
    };
    this.artistService.addPerformance(performanceInput);
  }

  submitEditPerformanceFormMutation(form) {
    console.log(`submit Edit Performance args`, form);
    const performanceInput = {
      body: form.editPerformanceContent.body,
      artist: form.editPerformanceContent.artist,
      buttonText: form.editPerformanceButton.buttonText,
      performanceId: form.editPerformanceMeta.performanceId,
      creator: form.editPerformanceMeta.creator
    };
    this.artistService.editPerformance(form.editPeformanceMeta.performanceId, performanceInput);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }

}
