
<mat-card class="form-card">
    <h2 mat-dialog-title>Create new {{ profileType }}</h2>
    <mat-card-content>
        <div 
            class="category-cart-item-subcategory" 
            *ngFor="let subcategory of newFormGroupArray; index as index"
        >
            <div class="subcategory-items-container">
                <div *ngIf="subcategory.type === 'list'" class="profile-category-card-item">
                    <br>
                    <app-category-card-item
                        class="category-card-item"
                        [parentForm]="newForm.controls[subcategory.fragment]"
                        [formGroup]="newForm.controls[subcategory.fragment]"
                        [categoryCardItem]="subcategory"
                        [profileType]="profileType">
                    </app-category-card-item>
                </div>
                <div *ngIf="subcategory.fragment === 'info'" class="profile-category-card-item">                    
                    <div class="category-cart-item-subcategory-title">{{ subcategory.title }}</div>
                    <app-single-item-form
                        class="category-card-item"
                        [parentForm]="newForm.controls[subcategory.fragment]"
                        [formGroup]="newForm.controls[subcategory.fragment]"
                        [categoryCardItem]="subcategory"
                        [profileType]="profileType">
                    </app-single-item-form>
                </div>
                <div *ngIf="subcategory.type === 'single' && subcategory.fragment !== 'info'" class="profile-category-card-item">
                    <app-dynamic-form 
                        class="category-card-item"
                        [parentForm]="newForm.controls[subcategory.fragment]" 
                        [formInputs]="subcategory" 
                        [editing]="editing" 
                        [mutation]="mutation"
                    ></app-dynamic-form>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <button mat-flat-button color="primary" (click)="createNewRecord(newForm)">Save new {{profileType}}</button>
        <button mat-flat-button mat-dialog-close="false" color="warn">Cancel creation</button>
    </mat-card-actions>
</mat-card>