import { UserOptionsService } from "src/app/dash/services/user-options.service";
import { CheckboxFormInput } from "src/app/shared/components/profile/profile/forms/form-items/checkbox-form-inputs";
import { DateFormInput } from "src/app/shared/components/profile/profile/forms/form-items/date-form-input";
import { DropdownFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-form-input";
import { ImageFormInput } from "src/app/shared/components/profile/profile/forms/form-items/image-form-input";
import { NumberFormInput } from "src/app/shared/components/profile/profile/forms/form-items/number-form-input";
import { TextFormInput } from "src/app/shared/components/profile/profile/forms/form-items/text-form-input";

export class CreateUserPersonalInformationFormGroupsModel {
    
    personalInfoControls = {
        controls: [
            new TextFormInput({
                label: `Username`,
                key: 'username',
                value: undefined,
                order: 2,
                disabled: false,
            }),
            new NumberFormInput({
                label: `Level`,
                key: 'level',
                value: '1',
                order: 3,
                required: false,
                disabled: false,
            }),
            new DropdownFormInput({
                label: `Status`,
                key: 'status',
                value: 'standard',
                order: 4,
                options: this.userOptionsService.userStatusOptions,
                required: true,
                disabled: false,
            }),
            new DropdownFormInput({
                label: `Type`,
                key: 'type',
                value: 'standard',
                order: 5,
                options: this.userOptionsService.userTypeOptions,
                required: true,
                disabled: false,
            }),
            new DropdownFormInput({
                label: `Analytics Status`,
                key: 'analyticsStatus',
                value: 'standard',
                order: 6,
                options: this.userOptionsService.analyticsStatusOptions,
                required: true,
                disabled: false,
            }),
        ],
        fragment: "details",
        title: "User Details",
        type: "single"
    };
    harmoniaInfoControls = {
        controls: [
            new NumberFormInput({
                label: `Current Harmonia`,
                key: 'harmonia',
                value: '0',
                order: 1,
                disabled: false,
            }),
            new NumberFormInput({
                label: `Total Checkin Harmonia`,
                key: 'totalCheckinHarmonia',
                value: '0',
                order: 3,
                required: true,
                disabled: false,
            }),
            new NumberFormInput({
                label: `Week Accrual`,
                key: 'weekAccrual',
                value: '0',
                order: 2,
                required: true,
                disabled: false,
            }),
            new NumberFormInput({
                label: `Perk Points`,
                key: 'perkPoints',
                value: '0',
                order: 6,
                required: false,
                disabled: false,
            }),
        ],
        fragment: "currency",
        title: "User Currency Details",
        type: "single"
    };
    achievementInfoControls = {
        controls: [
            new CheckboxFormInput({
                label: `Completed Tutorial`,
                key: 'tutorialComplete',
                value: false,
                order: 1,
                required: false,
                disabled: false,
            }),
            new CheckboxFormInput({
                label: `Reached 100 Multiplier`,
                key: 'reached100Multiplier',
                value: false,
                order: 2,
                required: false,
                disabled: false,
            }),
            new NumberFormInput({
                label: `Golden Record Count`,
                key: 'goldenRecordCount',
                value: `0`,
                order: 3,
                required: false,
                disabled: false,
            }),
            new CheckboxFormInput({
                label: `Reached 500 Multiplier`,
                key: 'reached500Multiplier',
                value: false,
                order: 4,
                required: false,
                disabled: false,
            }),
            new CheckboxFormInput({
                label: `Reached 1000 Multiplier`,
                key: 'reached1000Multiplier',
                value: false,
                order: 5,
                required: false,
                disabled: false,
            }),
        ],
        fragment: "achievements",
        title: "User Achievements Details",
        type: "single"
    };

    model = [
        this.personalInfoControls,
        this.harmoniaInfoControls,
        this.achievementInfoControls,
    ]
    constructor(public userOptionsService: UserOptionsService) {}
}