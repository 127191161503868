import { Component, OnInit } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ServerControlService} from '../../services/server-control.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-server-control-view',
  templateUrl: './server-control-view.component.html',
  styleUrls: ['./server-control-view.component.css']
})
export class ServerControlViewComponent implements OnInit {
  links = [];
  activeLink;
  background: ThemePalette = 'primary';
  color = '#00000';

  constructor(
    private router: Router,
    private serverControlService: ServerControlService
  ) { }

  ngOnInit(): void {
    const serverLinks = this.serverControlService.getServerLinks();
    this.links.push(...serverLinks);
    this.activeLink = this.router.url.replace("/dash/server-control", '');
  }
}
