<mat-card class="artist-form-card">
  <h1 class="mat-display-1" *ngIf="editing === true && performance">Edit Existing Artist Performance</h1>
  <h1 class="mat-display-1" *ngIf="editing === false">Schedule New Artist Performance</h1>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <form
    action=""
    class="artist-form"
    [formGroup]="editPerformanceForm"
    (ngSubmit)="onSubmit(editPerformanceForm)"
    *ngIf="!isLoading"
  >
    <!--    Content     -->
    <div
      formGroupName="editPerformanceContent"
      class="form-control-group"
    >
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Artist Performance Body Text</mat-label>
        <input
          matInput
          type="text"
          name="body"
          formControlName="body"
          id="body"
          label="body"
          placeholder="Enter the artist's performance body text here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Artist</mat-label>
        <mat-select
          formControlName="artist"
        >
          <mat-option *ngFor="let artist of artists" value="{{ artist.name }}">{{ artist.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!--    Button     -->
    <div
      formGroupName="editPerformanceButton"
      class="form-control-group"
    >
      <div class="button-display">
          <mat-form-field
            appearance="fill"
            class="form-control"
          >
            <mat-label>Button Text</mat-label>
            <input
              matInput
              type="text"
              name="buttonText"
              formControlName="buttonText"
              id="buttonText"
              label="buttonText"
              placeholder="Enter the news button text"
            >
          </mat-form-field>
          <br>
        <div class="example-button" type="button">
          <div>{{ buttonText }}</div>
        </div>
      </div>
    </div>
    <!--    Meta     -->
    <div
      formGroupName="editPerformanceMeta"
      class="form-control-group"
    >
      <mat-form-field [hidden]="true">
        <mat-label>Creator</mat-label>
        <input
          matInput
          value="{{ userId }}"
          name="creator"
          formControlName="creator"
          id="creator"
          label="creator"
        >
      </mat-form-field>
      <div *ngIf="editing && performance">
        <input
          type="hidden"
          name="performanceId"
          formControlName="performanceId"
          id="performanceId"
          label="performanceId"
          value="{{ performance._id }}"
        >
      </div>
    </div>
    <button class="btn mat-raised-button mat-primary" type="submit" *ngIf="editing || hasError">Edit Existing Artist Schedule</button>
    <button class="btn mat-raised-button mat-primary" type="submit" *ngIf="!editing && !hasError">Schedule Artist</button>
  </form>
</mat-card>

