import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';

import { BaseFormInput } from '../form-items/base-form-input';

@Component({
  selector: 'app-dynamic-form-input',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.component.css']
})
export class DynamicFormInputComponent implements OnInit {
  @Input() formInput: BaseFormInput<string | string[]>;
  @Input() form: FormGroup;
  @Input() editing;
  @Input() parentForm;
  apiUrl: String;

  deletedTime = null;

  picker;

  constructor(
    private http: HttpClient,
    private authService: AuthService
    ) {}
  
  ngOnInit(): void {
    this.apiUrl = environment.apiUrl
    this.deletedTime =  this.form.controls.deletedTime && 
                        this.form.controls.deletedTime.value !== '' 
                        ? 
                        this.form.controls.deletedTime.value : null
  }

  get isValid() {
    return this.form.controls[this.formInput.key].valid;
  }

  onImageSelected(event) {
    const token = this.authService.getToken();
    console.log(this.formInput)
    console.log(this.form)
    console.log(this.parentForm)
    console.log(event)
    const image: File = event.target.files[0];
    console.log(image);
    let fileName = '';
    const formData = new FormData();
    formData.append("imageTest", 'image');
    formData.append("image", image);
    if (this.formInput.value) {
      formData.append('oldPath', this.formInput.value.toString())
    }
    
    console.log(formData);
    
    fetch(this.apiUrl + "/img/post", {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        // 'Content-Type': 'application/json'
        // 'Content-Type': 'multipart/form-data'
      },
      body: formData
    }).then(res => res.json())
    .then(fileResData => {
      console.log(fileResData);
      let imageUrlFull = fileResData.filePath;
      const imageUrl = imageUrlFull.replace("images/", "");

      // this.form.controls.image.value = imageUrl;
      // this.formInput.value = imageUrl
      this.form.controls['image'].setValue(imageUrl)
      this.formInput.value = imageUrl
      console.log(this.form.controls);
    })
    

    // if (image) {
    //   const httpOptions = {
    //     headers: new HttpHeaders({
    //       "Accept": 'application/json',
    //       'Access-Control-Allow-Origin': '*',
    //       'Content-Type': 'multipart/form-data',
    //       'Boundary':'',
    //       // 'Authorization':'authkey',
    //       // 'userId':'1'
    //     }),
    //     // body: formData
    //   }
    //   console.log(image)
    //   const imageName = image.name;
      
    //   const formData = new FormData();
    //   formData.append('image', image);
    //   if (this.formInput.value) {
    //     formData.append('oldPath', this.formInput.value)
    //   }
    //   // httpOptions.body.append("image", file);

    //   console.log(formData);

    //   const upload$ = this.http.put(this.restUrl + "/image/post", formData)

    //   upload$.subscribe((resData) => {
    //     console.log(resData);
    //   });
    // }
    
  }

  softDeleteCategoryCardForm(form) {
    console.log('Soft Delete - this.parentForm', this.parentForm);
    console.log('Soft Delete - form', form)
    console.log('Soft Delete - this.deletedTime', this.deletedTime)
    console.log('Soft Delete - this.formInput.value', this.formInput.value)
    this.deletedTime = new Date().toISOString();
    this.form.controls.deletedTime.setValue(this.deletedTime)
    this.formInput.value = this.deletedTime;
    console.log('Soft Delete - this.deletedTime', this.deletedTime)
    console.log('Soft Delete - this.formInput.value', this.formInput.value)
    return this.form
  }

  unDeleteCategoryCardForm(form) {
    // console.log('UnDelete - this.parentForm', this.parentForm);
    // console.log('unDelete - this.categoryCardItem', form)
    console.log('unDelete - this.deletedTime', this.deletedTime)
    console.log('unDelete - this.formInput.value', this.formInput.value)
    this.deletedTime = null;
    this.form.controls.deletedTime.setValue(null)
    this.formInput.value = null
    console.log('unDelete - this.deletedTime', this.deletedTime)
    console.log('unDelete - this.formInput.value', this.formInput.value)
    return this.form
  }
}
