import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseFormInput } from 'src/app/shared/components/profile/profile/forms/form-items/base-form-input';
import { BreakFormInput } from 'src/app/shared/components/profile/profile/forms/form-items/break-form-input';
import { CheckboxFormInput } from 'src/app/shared/components/profile/profile/forms/form-items/checkbox-form-inputs';
import { DateFormInput } from 'src/app/shared/components/profile/profile/forms/form-items/date-form-input';
import { DropdownFormInput } from 'src/app/shared/components/profile/profile/forms/form-items/dropdown-form-input';
import { FormInputControlService } from 'src/app/shared/components/profile/profile/forms/form-items/form-input-control.service';
import { ImageFormInput } from 'src/app/shared/components/profile/profile/forms/form-items/image-form-input';
import { NumberFormInput } from 'src/app/shared/components/profile/profile/forms/form-items/number-form-input';
import { TextFormInput } from 'src/app/shared/components/profile/profile/forms/form-items/text-form-input';
import { UnorderedListFormInput } from 'src/app/shared/components/profile/profile/forms/form-items/unordered-list-form-input';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { CreateUserArtistsFormGroupsModel, CreateUserArtistsRoleFormGroupsModel } from '../models/profile/user/create/CreateUserArtistsFormGroups.model';
import { CreateUserFormGroupsModel } from '../models/profile/user/create/CreateUserProfileFormGroups.model';
import { CreateUserImagesFormGroupsModel } from '../models/profile/user/create/CreateUserImagesFormGroups.model';
import { CreateUserInventoryFormGroupsModel } from '../models/profile/user/create/CreateUserInventoryFormGroups.model';
import { CreateUserInventoryTransactionsFormGroupsModel } from '../models/profile/user/create/CreateUserInventoryTransactionsFormGroups.model';
import { CreateUserLinkedPlatformProfilesFormGroupsModel } from '../models/profile/user/create/CreateUserLinkedPlatformProfilesFormGroups.model';
import { CreateUserPerksFormGroupsModel } from '../models/profile/user/create/CreateUserPerksFormGroups.model';
import { CreateUserPersonalInformationFormGroupsModel } from '../models/profile/user/create/CreateUserPersonalInformationFormGroups.model';
import { CreateUserSessionsFormGroupsModel } from '../models/profile/user/create/CreateUserSessionsFormGroups.model';
import { CreateUserVrPlatformProfilesFormGroupsModel } from '../models/profile/user/create/CreateUserVrPlatformProfilesFormGroups.model';
import { UserOptionsService } from './user-options.service';
import { GetUserFormGroupsModel } from '../models/profile/user/get/GetUserProfileFormGroups.model';
import { CreateUserLinksFormGroupsModel } from '../models/profile/user/create/CreateUserLinksFormGroups.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService extends ProfileService {
  public profileType: string = 'User';
  public actionPanelContent;
  public profileCategoryCards;
  editing = true;
  
  public optionsData;
  
  constructor(
    http: HttpClient,
    public formInputControlService: FormInputControlService,
    public profileOptionsService: UserOptionsService,
  ) {
    super(http, formInputControlService, profileOptionsService);
  }
  
  getActionPanelData(profileData) {
    console.log('getActionPanelData', profileData)
    const profile = profileData.user;
    const actionPanelContent = {
      header: {
        // avatar: {},
        fields: [],
      },
      body: [],
      actions: [],
    };

    // actionPanelContent.header.avatar = {
    //   imgSRC: profile.personProfile.profileImageUrl,
    //   title: `${profile.personProfile.fName} ${profile.personProfile.lName}'s profile image`,
    //   alt: `${profile.personProfile.fName} ${profile.personProfile.lName}`
    // }

    actionPanelContent.header.fields = [
      { key: 'SVRID', value: `SVRID: ${profile.id}` },
      { key: 'Username', value: `Username: ${profile.username}` },
      { key: 'Level', value: `Level: ${profile.level}` },
      { key: 'Status', value: `Status: ${profile.userStatus.userStatusName ? profile.userStatus.userStatusName : 'active'}` },
      { key: 'Type', value: `Type: ${profile.userType.userTypeName ? profile.userType.userTypeName : 'standard'}` },
    ];

    actionPanelContent.body = [
      {
        header: 'VR Platform of Choice', 
        body: profile.vrPlatformProfiles[0] ? profile.vrPlatformProfiles[0].vrPlatform.vrPlatformName : 'Steam', 
        // actions: [{text: 'Call', link: '/call'}, {text: 'Email', link: '/email'}], 
        // subheader: 'Platform of Choice', 
        // subbody: profile.bed.nameFull ? profile.bed.nameFull : "N/A"
      },
      {
        header: 'Linked Platforms', 
        body: profile.linkedPlatformProfiles[0] ? `# of Linked Plaftorms: ${profile.linkedPlatformProfiles.length}` : 'No Linked Platforms', 
        // actions: [{text: 'Call', link: '/call'}, {text: 'Email', link: '/email'}]
      },
      {
        header: 'Artist Profiles', 
        body: profile.artistProfiles[0] ? `# of Artist Profiles: ${profile.artistProfiles.length}` : 'No Artist Profiles', 
        // actions: [{text: 'Call', link: '/call'}, {text: 'Email', link: '/email'}]
      },
    ]

    actionPanelContent.actions = [
      { text: 'Add Founder\'s Status', link: '/add-founders-status' },
      { text: 'Rename User', link: '/rename' },
      { text: 'Add to Artist', link: '/add-to-artist' },
      { text: 'Email', link: '/email' },
      { text: 'Suspend User', link: '/suspend-user' },
      { text: 'Restore Data', link: '/restore-data' },
      { text: 'Delete User', link: '/delete-user' },
    ]

    this.actionPanelContent = actionPanelContent;

    return actionPanelContent;
  }

  getCategoryCardData(profileData) {
    console.log('profileData', profileData);
    const profile = profileData.user;

    let profileCategoryCards;

    const userInformationFormGroups: BaseFormInput<string | string[] | boolean>[][] = this.getUserInformationFormGroup(profile);

    const artists = this.getUserArtists(profile.artistProfiles);
    const linkedPlatformProfiles = this.getUserLinkedPlatformProfiles(profile.linkedPlatformProfiles);
    const userSessions = this.getUserSessions(profile.userSessions);
    const userImages = this.getUserImages(profile.userImages);
    const userInventory = this.getUserInventory(profile.userInventory);
    const userInventoryTransactions = this.getUserInventoryTransactions(profile.userInventoryTransactions);
    const userPerks = this.getUserPerks(profile.userPerks);
    // const userTransactions = this.getUserTransactions(profile.userTransactions, statusOptions);
    const vrPlatformProfiles = this.getUserVrPlatformProfiles(profile.vrPlatformProfiles);
    
    // profileCategoryCards = new GetUserFormGroupsModel(this.profileOptionsService)
    profileCategoryCards = [
      {
        title: `User Information`, 
        type: `single`, 
        fragment: 'info',
        subcategories: [
          {
            title: 'User Details',
            type: 'single',
            fragment: 'details',
            controls: userInformationFormGroups[0],
          },
          {
            title: 'User Currency Details',
            type: 'single',
            fragment: 'currency',
            controls: userInformationFormGroups[1],
          },
          {
            title: 'User Achievements Details',
            type: 'single',
            fragment: 'achievements',
            controls: userInformationFormGroups[2],
          },
        ]
      },
      {
        title: `Artists`, 
        type: `list`,
        fragment: 'artists',
        listType: 'User Artists Profiles',
        listItems: artists,
        subcategories: [{title: `${artists.length === 1 ? artists.length + ' Artist' : artists.length + ' Artists'}` }]
      },
      {
        title: `Linked Platform Profiles`, 
        type: `list`,
        fragment: 'linkedPlatformProfiles',
        listType: 'User Linked Platform Profiles',
        listItems: linkedPlatformProfiles,
        subcategories: [{title: `${linkedPlatformProfiles.length === 1 ? linkedPlatformProfiles.length + ' Linked Platform Profile' : linkedPlatformProfiles.length + ' Linked Platform Profiles'}` }]
      },
      {
        title: `User Images`, 
        type: `list`,
        fragment: 'userImages',
        listType: 'User Images',
        listItems: userImages,
        subcategories: [{title: `${userImages.length === 1 ? userImages.length + ' User Image' : userImages.length + ' User Images'}` }]
      },
      {
        title: `User Sessions`, 
        type: `list`,
        fragment: 'userSessions',
        listType: 'User Sessions',
        listItems: userSessions,
        subcategories: [{title: `${userSessions.length === 1 ? userSessions.length + ' Session' : userSessions.length + ' Sessions'}` }]
      },
      {
        title: `Inventory`, 
        type: `list`,
        fragment: 'userInventory',
        listType: 'User Inventory',
        listItems: userInventory,
        subcategories: [{title: `${userInventory.length === 1 ? userInventory.length + ' Inventory Item' : userInventory.length + ' Inventory Items'}` }]
      },
      {
        title: `Inventory Transactions`, 
        type: `list`,
        fragment: 'userInventoryTransactions',
        listType: 'User Inventory Transactions',
        listItems: userInventoryTransactions,
        subcategories: [{title: `${userInventoryTransactions.length === 1 ? userInventoryTransactions.length + ' Inventory Transaction' : userInventoryTransactions.length + ' Inventory Transactions'}` }]
      },
      {
        title: `Perks`, 
        type: `list`,
        fragment: 'userPerks',
        listType: 'User Perks',
        listItems: userPerks,
        subcategories: [{title: `${userPerks.length === 1 ? userPerks.length + ' Perk' : userPerks.length + ' Perks'}` }]
      },
      // {
      //   title: `Transactions`, 
      //   type: `list`,
      //   fragment: 'userTransactions',
      //   listType: 'User Transactions',
      //   listItems: userTransactions,
      //   subcategories: [{title: `${userTransactions.length === 1 ? userTransactions.length + ' Transaction' : userTransactions.length + ' Transactions'}` }]
      // },
      {
        title: `VR Platform Profiles`, 
        type: `list`,
        fragment: 'vrPlatformProfiles',
        listType: 'User VR Platform Profiles',
        listItems: vrPlatformProfiles,
        subcategories: [{title: `${vrPlatformProfiles.length === 1 ? vrPlatformProfiles.length + ' VR Platform Profile' : vrPlatformProfiles.length + ' VR Platform Profiles'}` }]
      },
    ]

    this.profileCategoryCards = profileCategoryCards;
    console.log('profileCategoryCards', this.profileCategoryCards);

    return this.profileCategoryCards;
  }

  getUserArtists(inputFromProfile) {
    console.log(inputFromProfile)
    const userArtistProfiles = inputFromProfile;
    const artistProfiles = [];

    let index = 0;
    for (let artistProfile of userArtistProfiles) {
      const userArtistProfileFormGroups = this.getUserArtistProfileFormGroups(artistProfile);

      let artistUserRoles = [];
      let artistUserRoleIndex = 0;
      for (let artistUserRole of artistProfile.artistUserRoles) {
        const artistUserRoleFormGroups = this.getArtistUserRoleFormGroups(artistUserRole);

        let artistUserRoleObject = {
          title: `Artist User Role`,
          type: 'single',
          index: artistUserRoleIndex + 1,
          fragment: `artistUserRole`,
          listType: 'artistUserRole',
          subcategories: [
            {
              title: `Artist User Role Information`,
              type: 'single',
              fragment: `artistUserRoleInformation`,
              controls: artistUserRoleFormGroups[0],
            },
            {
              title: `Role Information`,
              type: 'single',
              fragment: `roleInformation`,
              controls: artistUserRoleFormGroups[1],
            },
          ],
        }
        artistUserRoleIndex++
        artistUserRoles.push(artistUserRoleObject)
      }
      
      let artistImages = [];
      let artistImageIndex = 0;
      for (let artistImage of artistProfile.artistProfile.artistImages) {
        const artistImageFormGroups = this.getArtistImageFormGroups(artistImage);

        let artistImageObject = {
          title: `Artist Image`,
          type: 'single',
          index: artistImageIndex + 1,
          fragment: `artistImage`,
          listType: 'Artist Image',
          subcategories: [
            {
              title: `Artist Image Information`,
              type: 'single',
              fragment: `Artist Image Information`,
              controls: artistImageFormGroups[0],
            },
          ],
        }
        artistImageIndex++
        artistImages.push(artistImageObject)
      }

      let artistLinks = [];
      let artistLinkIndex = 0;
      for (let artistLink of artistProfile.artistProfile.artistLinks) {
        const artistLinkFormGroups = this.getArtistLinkFormGroups(artistLink);

        let artistLinkObject = {
          title: `Artist Link`,
          type: 'single',
          index: artistLinkIndex + 1,
          fragment: `artistLink`,
          listType: 'Artist Link',
          subcategories: [
            {
              title: `Artist Link Information`,
              type: 'single',
              fragment: `Artist Link Information`,
              controls: artistLinkFormGroups[0],
            },
          ],
        }
        artistLinkIndex++
        artistLinks.push(artistLinkObject)
      }

      let artistTools = [];
      let artistToolIndex = 0;
      for (let artistTool of artistProfile.artistProfile.artistToolSubscriptions) {
        const artistToolFormGroups = this.getArtistToolFormGroups(artistTool);

        let artistToolObject = {
          title: `Artist Tool`,
          type: 'single',
          index: artistToolIndex + 1,
          fragment: `artistTool`,
          listType: 'Artist Tool',
          subcategories: [
            {
              title: `Artist Tool Subscription Information`,
              type: 'single',
              fragment: `Artist Tool Subscription Information`,
              controls: artistToolFormGroups[0],
            },
            {
              title: `Artist Tool Information`,
              type: 'single',
              fragment: `Artist Tool Information`,
              controls: artistToolFormGroups[1],
            },
          ],
        }
        artistToolIndex++
        artistTools.push(artistToolObject)
      }

      let artistSessionTypes = [];
      let artistSessionTypeIndex = 0;
      for (let artistSessionType of artistProfile.artistProfile.artistSessionTypes) {
        const artistSessionTypeFormGroups = this.getArtistSessionTypeFormGroups(artistSessionType);

        let artistSessionTypeObject = {
          title: `Artist Session Type`,
          type: 'single',
          index: artistSessionTypeIndex + 1,
          fragment: `artistSessionType`,
          listType: 'Artist Session Type',
          subcategories: [
            {
              title: `Artist Session Type Information`,
              type: 'single',
              fragment: `Artist Session Type Information`,
              controls: artistSessionTypeFormGroups[0],
            },
            {
              title: `Session Type Information`,
              type: 'single',
              fragment: `Session Type Information`,
              controls: artistSessionTypeFormGroups[1],
            },
          ],
        }
        artistSessionTypeIndex++
        artistSessionTypes.push(artistSessionTypeObject)
      }

      let artistSessions = [];
      let artistSessionIndex = 0;
      for (let artistSession of artistProfile.artistProfile.sessions) {
        const artistSessionFormGroups = this.getArtistSessionFormGroups(artistSession);

        let artistSessionObject = {
          title: `Artist Session`,
          type: 'single',
          index: artistSessionIndex + 1,
          fragment: `artistSession`,
          listType: 'Artist Session',
          subcategories: [
            {
              title: `Artist Session Information`,
              type: 'single',
              fragment: `Artist Session Information`,
              controls: artistSessionFormGroups[0],
            },
          ],
        }
        artistSessionIndex++
        artistSessions.push(artistSessionObject)
      }

      let artistUsers = [];
      let artistUserIndex = 0;
      for (let artistUser of artistProfile.artistProfile.artistUsers) {
        const artistUserFormGroups = this.getArtistUserFormGroups(artistUser);

        let artistUserObject = {
          title: `Artist User`,
          type: 'single',
          index: artistUserIndex + 1,
          fragment: `artistUser`,
          listType: 'Artist User',
          subcategories: [
            {
              title: `Artist User Information`,
              type: 'single',
              fragment: `Artist User Information`,
              controls: artistUserFormGroups[0],
            },
            {
              title: `User Information`,
              type: 'single',
              fragment: `User Information`,
              controls: artistUserFormGroups[1],
            },
          ],
        }
        artistUserIndex++
        artistUsers.push(artistUserObject)
      }

      let artistProfileObject = {
        title: `Artist`, 
        type: `single`,
        fragment: `artists`,
        listType: 'Artist Profiles',
        subcategories: [
          {
            title: 'Artist User Information',
            type: 'single',
            fragment: `artistUserInformation`,
            controls: userArtistProfileFormGroups[1],
          },
          {
            title: 'Artist Profile Information',
            type: 'single',
            fragment: `artistProfileInformation`,
            controls: userArtistProfileFormGroups[0],
          },
          {
            title: `Artist User Roles`,
            type: 'list',
            fragment: `artistUserRoles`,
            listType: 'artistUserRoles',
            listItems: artistUserRoles,
            subcategories: [{title: `${artistUserRoles.length === 1 ? artistUserRoles.length + ' Artist User Role' : artistUserRoles.length + ' Artist User Roles'}`}]
          },
          {
            title: `Artist Images`,
            type: 'list',
            fragment: `artistImages`,
            listType: 'artistImages',
            listItems: artistImages,
            subcategories: [{title: `${artistImages.length === 1 ? artistImages.length + ' Artist Image' : artistImages.length + ' Artist Images'}`}]
          },
          {
            title: `Artist Links`,
            type: 'list',
            fragment: `artistLinks`,
            listType: 'artistLinks',
            listItems: artistLinks,
            subcategories: [{title: `${artistLinks.length === 1 ? artistLinks.length + ' Artist Link' : artistLinks.length + ' Artist Links'}`}]
          },
          {
            title: `Artist Tools`,
            type: 'list',
            fragment: `artistTools`,
            listType: 'artistTools',
            listItems: artistTools,
            subcategories: [{title: `${artistTools.length === 1 ? artistTools.length + ' Artist Tool' : artistTools.length + ' Artist Tools'}`}]
          },
          {
            title: `Artist Session Types`,
            type: 'list',
            fragment: `artistSessionTypes`,
            listType: 'artistSessionTypes',
            listItems: artistSessionTypes,
            subcategories: [{title: `${artistSessionTypes.length === 1 ? artistSessionTypes.length + ' Artist Session Type' : artistSessionTypes.length + ' Artist Session Types'}`}]
          },
          {
            title: `Artist Sessions`,
            type: 'list',
            fragment: `artistSessions`,
            listType: 'artistSessions',
            listItems: artistSessions,
            subcategories: [{title: `${artistSessions.length === 1 ? artistSessions.length + ' Artist Session' : artistSessions.length + ' Artist Sessions'}`}]
          },
          {
            title: `Artist Users`,
            type: 'list',
            fragment: `artistUsers`,
            listType: 'artistUsers',
            listItems: artistUsers,
            subcategories: [{title: `${artistUsers.length === 1 ? artistUsers.length + ' Artist User' : artistUsers.length + ' Artist Users'}`}]
          },
        ]
      }
      index++
      artistProfiles.push(artistProfileObject);
    }
    return artistProfiles;
  }
  
  getUserLinkedPlatformProfiles(inputFromProfile) {
    const userLinkedPlatformProfiles = inputFromProfile;
    const linkedPlatformProfiles = [];

    let index = 0;
    for (let userLinkedPlatformProfile of userLinkedPlatformProfiles) {
      const userLinkedPlatformProfileFormGroups = this.getUserLinkedPlatformProfileFormGroups(userLinkedPlatformProfile);

      let linkedPlatformProfileLinks = [];
      let linkIndex = 0;
      for (let userLink of userLinkedPlatformProfile.userLinks) {
        const userLinkFormGroups = this.getUserLinkFormGroups(userLink);

        let linkObject = {
          title: `Link`,
          type: 'single',
          index: linkIndex + 1,
          fragment: `userLink`,
          listType: 'User Link',
          subcategories: [
            {
              title: `Link Information`,
              type: 'single',
              fragment: `userLinkInformation`,
              controls: userLinkFormGroups[0],
            },
          ],
        }
        linkIndex++
        linkedPlatformProfileLinks.push(linkObject)
      }

      let userLinkedPlatformProfileObject = {
        title: `Linked Platform`, 
        type: `single`,
        fragment: `linkedPlatformProfiles`,
        listType: 'Linked Platform Profiles',
        subcategories: [
          {
            title: 'Linked Platform Profile Information',
            type: 'single',
            fragment: `userLinkedPlatformProfileInformation`,
            controls: userLinkedPlatformProfileFormGroups[0],
          },
          {
            title: 'Linked Platform Information',
            type: 'single',
            fragment: `userLinkedPlatformInformation`,
            controls: userLinkedPlatformProfileFormGroups[1],
          },
          {
            title: `User Linked Platform Links`,
            type: 'list',
            fragment: `userLinks`,
            listType: 'User Linked Platform Links',
            listItems: linkedPlatformProfileLinks,
            subcategories: [{title: `${linkedPlatformProfileLinks.length === 1 ? linkedPlatformProfileLinks.length + ' User Link' : linkedPlatformProfileLinks.length + ' User Links'}`}]
          },
        ]
      }
      index++
      linkedPlatformProfiles.push(userLinkedPlatformProfileObject);
    }
    return linkedPlatformProfiles;
  }

  getUserImages(inputFromProfile) {
    const userImages = inputFromProfile;
    const images = [];

    let index = 0;
    for (let userImage of userImages) {
      const userImageFormGroups = this.getUserImageFormGroups(userImage);

      let userImageObject = {
        title: `Image`, 
        type: `single`,
        fragment: `userImages`,
        listType: 'Images',
        subcategories: [
          {
            title: 'User Image Information',
            type: 'single',
            fragment: `userImageInformation`,
            controls: userImageFormGroups[0],
          },
        ]
      }
      index++
      images.push(userImageObject);
    }
    return images;
  }
  
  getUserSessions(inputFromProfile) {
    const userSessions = inputFromProfile;
    const sessions = [];

    let index = 0;
    for (let userSession of userSessions) {
      const userSessionFormGroups = this.getUserSessionFormGroups(userSession);

      let userSessionObject = {
        title: `Session`, 
        type: `single`,
        fragment: `userSessions`,
        listType: 'Sessions',
        subcategories: [
          {
            title: 'Session User Information',
            type: 'single',
            fragment: `userSessionUserInformation`,
            controls: userSessionFormGroups[0],
          },
          {
            title: 'Session Information',
            type: 'single',
            fragment: `userSessionInformation`,
            controls: userSessionFormGroups[1],
          },
        ]
      }
      index++
      sessions.push(userSessionObject);
    }
    return sessions;
  }
  
  getUserInventory(inputFromProfile) {
    const userInventory = inputFromProfile;
    const inventory = [];

    let index = 0;
    for (let userInventoryItem of userInventory) {
      const userInventoryFormGroups = this.getUserInventoryFormGroups(userInventoryItem);

      let userInventoryObject = {
        title: `Inventory Item`, 
        type: `single`,
        fragment: `userInventory`,
        listType: 'Inventory',
        subcategories: [
          {
            title: 'Inventory Information',
            type: 'single',
            fragment: `userInventoryInformation`,
            controls: userInventoryFormGroups[0],
          },
          {
            title: 'Inventory Item Information',
            type: 'single',
            fragment: `inventoryItemInformation`,
            controls: userInventoryFormGroups[1],
          },
        ]
      }
      index++
      inventory.push(userInventoryObject);
    }
    return inventory;
  }
  
  getUserInventoryTransactions(inputFromProfile) {
    const userInventoryTransactions = inputFromProfile;
    const inventoryTransactions = [];

    let index = 0;
    for (let userInventoryTransactionItem of userInventoryTransactions) {
      const userInventoryTransactionFormGroups = this.getUserInventoryTransactionFormGroups(userInventoryTransactionItem);

      let userInventoryTransactionObject = {
        title: `Harmonia Transaction`, 
        type: `single`,
        fragment: `userInventoryTransactions`,
        listType: 'InventoryTransaction',
        subcategories: [
          {
            title: 'Inventory Transaction Item Information',
            type: 'single',
            fragment: `userInventoryTransactionInformation`,
            controls: userInventoryTransactionFormGroups[0],
          },
          {
            title: 'Inventory Item Information',
            type: 'single',
            fragment: `inventoryItemInformation`,
            controls: userInventoryTransactionFormGroups[1],
          },
        ]
      }
      index++
      inventoryTransactions.push(userInventoryTransactionObject);
    }
    return inventoryTransactions;
  }
  
  getUserPerks(inputFromProfile) {
    const userPerks = inputFromProfile;
    const perks = [];

    let index = 0;
    for (let userPerk of userPerks) {
      const userPerkFormGroups = this.getUserPerkFormGroups(userPerk);

      let userPerkObject = {
        title: `Perk`, 
        type: `single`,
        fragment: `userPerks`,
        listType: 'Perks',
        subcategories: [
          {
            title: 'User Perk Information',
            type: 'single',
            fragment: `userPerkInformation`,
            controls: userPerkFormGroups[0],
          },
          {
            title: 'Perk Information',
            type: 'single',
            fragment: `perkInformation`,
            controls: userPerkFormGroups[1],
          },
        ]
      }
      index++
      perks.push(userPerkObject);
    }
    return perks;
  }
  
  // getUserTransactions(inputFromProfile) {
  //   const userTransactions = inputFromProfile;
  //   const transactions = [];

  //   let index = 0;
  //   for (let userTransactionItem of userTransactions) {
  //     const userTransactionFormGroups = this.getUserTransactionFormGroups(userTransactionItem, statusOptions);

  //     let userTransactionObject = {
  //       title: `Transaction Item #${index + 1}`, 
  //       type: `single`,
  //       fragment: `userTransactionInventory`,
  //       listType: 'Transactions',
  //       subcategories: [
  //         {
  //           title: ' Transaction Item Information',
  //           type: 'single',
  //           fragment: `userTransactionInformation`,
  //           controls: userTransactionFormGroups[0],
  //         },
  //         {
  //           title: 'Transaction Item Information',
  //           type: 'single',
  //           fragment: `transactionItemInformation`,
  //           controls: userTransactionFormGroups[1],
  //         },
  //       ]
  //     }
  //     index++
  //     transactions.push(userTransactionObject);
  //   }
  //   return transactions;
  // }
  
  getUserVrPlatformProfiles(inputFromProfile) {
    const userVrPlatformProfiles = inputFromProfile;
    const vrPlatformProfiles = [];

    let index = 0;
    for (let userVrPlatformProfile of userVrPlatformProfiles) {
      const userVrPlatformProfileFormGroups = this.getUserVrPlatformProfileFormGroups(userVrPlatformProfile);

      let userVrPlatformProfileObject = {
        title: `VR Platform`, 
        type: `single`,
        fragment: `vrPlatformProfiles`,
        listType: 'Linked Platform Profiles',
        subcategories: [
          {
            title: 'VR Platform Profile Information',
            type: 'single',
            fragment: `userVrPlatformProfileInformation`,
            controls: userVrPlatformProfileFormGroups[0],
          },
          {
            title: 'VR Platform Information',
            type: 'single',
            fragment: `vrPlatformInformation`,
            controls: userVrPlatformProfileFormGroups[1],
          },
        ]
      }
      index++
      vrPlatformProfiles.push(userVrPlatformProfileObject);
    }
    return vrPlatformProfiles;
  }

  getUserInformationFormGroup(profileData) {
    const profile = profileData; 

    const personalInformationFormGroups: BaseFormInput<string | string[] | boolean>[][] = [];

    const personalInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `SVR ID`,
        key: 'SVRID',
        value: profile.id,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Username`,
        key: 'username',
        value: profile.username,
        order: 2,
        disabled: !this.editing,
      }),
      new NumberFormInput({
        label: `Level`,
        key: 'level',
        value: profile.level,
        order: 3,
        required: false,
        disabled: !this.editing,
      }),
      new DropdownFormInput({
        label: `Status`,
        key: 'status',
        value: profile.userStatus.userStatusName,
        order: 4,
        options: this.profileOptionsService.userStatusOptions,
        required: true,
        disabled: !this.editing,
      }),
      new DropdownFormInput({
        label: `Type`,
        key: 'type',
        value: profile.userType.userTypeName,
        order: 5,
        options: this.profileOptionsService.userTypeOptions,
        required: true,
        disabled: !this.editing,
      }),
      new DropdownFormInput({
        label: `Analytics Status`,
        key: 'analyticsStatus',
        value: profile.analyticsStatus.analyticsStatusName,
        order: 6,
        options: this.profileOptionsService.analyticsStatusOptions,
        required: true,
        disabled: !this.editing,
      }),
    ];
    personalInformationFormGroups.push(personalInfoControls);

    const harmoniaInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Current Harmonia`,
        key: 'harmonia',
        value: profile.userCurrency.currency,
        order: 1,
        disabled: !this.editing,
      }),
      new NumberFormInput({
        label: `Week Accrual`,
        key: 'weekAccrual',
        value: profile.userCurrency.weekAccrual,
        order: 2,
        required: false,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Total Checkin Harmonia`,
        key: 'totalCheckinHarmonia',
        value: profile.userCurrency.totalCheckinHarmonia,
        order: 3,
        required: false,
        disabled: true,
      }),
      new DateFormInput({
        label: `Last Accrual`,
        key: 'lastAccrual',
        value: profile.userCurrency.lastAccrual,
        order: 4,
        required: false,
        disabled: true,
      }),
      new DateFormInput({
        label: `Checkin Reset Date`,
        key: 'checkinReset',
        value: profile.userCurrency.checkinReset,
        order: 5,
        required: false,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Perk Points`,
        key: 'perkPoints',
        value: profile.userCurrency.perkPoints,
        order: 6,
        required: false,
        disabled: !this.editing,
      }),
    ];
    personalInformationFormGroups.push(harmoniaInfoControls);

    const achievementInfoControls: BaseFormInput<string | boolean>[] = [
      new CheckboxFormInput({
        label: `Completed Tutorial`,
        key: 'tutorialComplete',
        value: profile.userAchievements.tutorialComplete,
        order: 1,
        required: false,
        disabled: !this.editing,
      }),
      new CheckboxFormInput({
        label: `Reached 100 Multiplier`,
        key: 'reached100Multiplier',
        value: profile.userAchievements.reached100Multiplier,
        order: 2,
        required: false,
        disabled: !this.editing,
      }),
      new NumberFormInput({
        label: `Golden Record Count`,
        key: 'goldenRecordCount',
        value: `${profile.userAchievements.goldenRecordCount}`,
        order: 3,
        required: false,
        disabled: !this.editing,
      }),
      new CheckboxFormInput({
        label: `Reached 500 Multiplier`,
        key: 'reached500Multiplier',
        value: profile.userAchievements.reached500Multiplier,
        order: 4,
        required: false,
        disabled: !this.editing,
      }),
      new CheckboxFormInput({
        label: `Reached 1000 Multiplier`,
        key: 'reached1000Multiplier',
        value: profile.userAchievements.reached1000Multiplier,
        order: 5,
        required: false,
        disabled: !this.editing,
      }),
    ];
    personalInformationFormGroups.push(achievementInfoControls);

    return personalInformationFormGroups;
  }

  getUserArtistProfileFormGroups(artistProfile) {
    const profile = artistProfile; 

    const userArtistProfileFormGroups: BaseFormInput<string | string[]>[][] = [];

    const artistProfileInfoControls: BaseFormInput<string>[] = [
      new TextFormInput({
        label: `Artist Profile ID`,
        key: 'artistProfileId',
        value: profile.artistProfile.artistProfileId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Artist Profile Status`,
        key: 'artistProfileStatus',
        value: profile.artistProfile.status.statusName,
        order: 6,
        required: true,
        options: this.profileOptionsService.statusOptions,
        disabled: true,
      }),
      new BreakFormInput({controlType: `break`}),
      new TextFormInput({
        label: `Artist Name`,
        key: 'artistProfileName',
        value: profile.artistProfile.artistProfileName,
        order: 2,
        disabled: true,
      }),
      new TextFormInput({
        label: `Artist Description`,
        key: 'artistProfileDescription',
        value: profile.artistProfile.artistProfileDescription,
        order: 3,
        disabled: true,
      }),
    ];
    userArtistProfileFormGroups.push(artistProfileInfoControls);

    const artistUserInfoControls: BaseFormInput<string>[] = [
      new TextFormInput({
        label: `Artist User ID`,
        key: 'artistUserId',
        value: profile.artistUserId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Artist User Status`,
        key: 'artistUserStatus',
        value: profile.status.statusName,
        order: 6,
        options: this.profileOptionsService.statusOptions,
        required: true,
        disabled: !this.editing,
      }),
    ];
    userArtistProfileFormGroups.push(artistUserInfoControls);

    return userArtistProfileFormGroups;
  };

  getArtistUserRoleFormGroups(artistUserRole) {
    const profile = artistUserRole; 

    const artistUserRoleFormGroups: BaseFormInput<string | string[]>[][] = [];

    const artistUserRoleInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Artist User Role ID`,
        key: 'artistUserRoleId',
        value: profile.artistUserRoleId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Artist User Role Status`,
        controlType: `dropdown`,
        key: 'artistUserRoleStatusCode',
        value: profile.status.statusName,
        options: this.profileOptionsService.statusOptions,
        order: 1,
        disabled: !this.editing,
      }),
    ];
    artistUserRoleFormGroups.push(artistUserRoleInfoControls);

    const roleInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Role Id`,
        key: 'artistRoleId',
        value: profile.role.roleId,
        order: 4,
        disabled: false,
      }),
      new TextFormInput({
        label: `Role Name`,
        key: 'artistUserRoleName',
        value: profile.role.roleName,
        order: 4,
        disabled: true,
      }),
      new TextFormInput({
        label: `Role Description`,
        key: 'artistUserRoleDescription',
        value: profile.role.roleDescription,
        order: 4,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Role Type`,
        controlType: `dropdown`,
        key: 'artistUserRoleStatusCode',
        value: profile.role.roleType.roleTypeName,
        options: this.profileOptionsService.roleTypeOptions,
        order: 1,
        disabled: true,
      }),
    ];
    artistUserRoleFormGroups.push(roleInfoControls);
    
    return artistUserRoleFormGroups;
  };

  getArtistImageFormGroups(artistImage) {
    const profile = artistImage; 

    const artistImageFormGroups: BaseFormInput<string | string[]>[][] = [];

    const artistImageInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Artist Image ID`,
        key: 'artistImageId',
        value: profile.artistImageId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Artist Image Status`,
        controlType: `dropdown`,
        key: 'artistImageStatus',
        value: profile.status.statusName,
        options: this.profileOptionsService.statusOptions,
        order: 1,
        disabled: true,
      }),
      new BreakFormInput({controlType: `break`}),
      new TextFormInput({
        label: `Artist Image Name`,
        key: 'artistImageName',
        value: profile.artistImageName,
        order: 2,
        disabled: true,
      }),
      new TextFormInput({
        label: `Artist Image Description`,
        key: 'artistImageDescription',
        value: profile.artistImageDescription,
        order: 2,
        disabled: true,
      }),
      new BreakFormInput({controlType: `break`}),
      new ImageFormInput({
        label: `User Image`,
        key: 'image',
        value: profile.userImage.imageUrl !== null ? profile.userImage.imageUrl : '',
        order: 3,
        required: false,
        disabled: true,
      }),
    ];
    artistImageFormGroups.push(artistImageInfoControls);

    return artistImageFormGroups;
  };

  getArtistLinkFormGroups(artistLink) {
    const profile = artistLink; 

    const artistLinkFormGroups: BaseFormInput<string | string[]>[][] = [];

    const artistLinkInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Artist Link ID`,
        key: 'artistLinkId',
        value: profile.artistLinkId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Artist Link Status`,
        controlType: `dropdown`,
        key: 'artistLinkStatus',
        value: profile.status.statusName,
        options: this.profileOptionsService.statusOptions,
        order: 1,
        disabled: true,
      }),
      new BreakFormInput({controlType: `break`}),
      new TextFormInput({
        label: `Artist Link Name`,
        key: 'artistLinkName',
        value: profile.artistLinkName,
        order: 2,
        disabled: true,
      }),
      new TextFormInput({
        label: `Artist Link Description`,
        key: 'artistLinkDescription',
        value: profile.artistLinkDescription,
        order: 2,
        disabled: true,
      }),
      new BreakFormInput({controlType: `break`}),
      new TextFormInput({
        label: `User Link`,
        key: 'image',
        value: profile.userLink.webAddress !== null ? profile.userLink.webAddress : '',
        order: 3,
        required: false,
        disabled: true,
      }),
    ];
    artistLinkFormGroups.push(artistLinkInfoControls);

    return artistLinkFormGroups;
  };

  getArtistToolFormGroups(artistTool) {
    const profile = artistTool; 

    const artistToolFormGroups: BaseFormInput<string | string[]>[][] = [];

    const artistToolSubscriptionInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Artist Tool Subscription ID`,
        key: 'artistToolId',
        value: profile.artistToolSubscriptionId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Artist Tool Subscription Status`,
        controlType: `dropdown`,
        key: 'artistToolSubscriptionStatusCode',
        value: profile.status.statusName,
        options: this.profileOptionsService.statusOptions,
        order: 1,
        disabled: true,
      }),
    ];
    artistToolFormGroups.push(artistToolSubscriptionInfoControls);

    const artistToolInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Artist Tool ID`,
        key: 'artistToolId',
        value: profile.artistToolItem.artistToolItemId,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Artist Tool Name`,
        key: 'artistToolName',
        value: profile.artistToolItem.artistToolItemName,
        order: 4,
        disabled: true,
      }),
      new TextFormInput({
        label: `Artist Tool Description`,
        key: 'artistToolDescription',
        value: profile.artistToolItem.artistToolItemDescription,
        order: 4,
        disabled: true,
      }),
    ];
    artistToolFormGroups.push(artistToolInfoControls);
    
    return artistToolFormGroups;
  };

  getArtistSessionTypeFormGroups(artistSessionType) {
    const profile = artistSessionType; 

    const artistSessionTypeFormGroups: BaseFormInput<string | string[]>[][] = [];

    const artistSessionTypeInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Artist Session Type ID`,
        key: 'artistSessionTypeId',
        value: profile.artistSessionTypeId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Artist Session Type Status`,
        controlType: `dropdown`,
        key: 'artistSessionTypeSubscriptionStatusCode',
        value: profile.status.statusName,
        options: this.profileOptionsService.statusOptions,
        order: 1,
        disabled: true,
      }),
    ];
    artistSessionTypeFormGroups.push(artistSessionTypeInfoControls);

    const sessionTypeInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Session Type ID`,
        key: 'sessionTypeId',
        value: profile.sessionType.sessionTypeId,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Session Type Name`,
        key: 'sessionTypeName',
        value: profile.sessionType.sessionTypeName,
        order: 4,
        disabled: true,
      }),
      new TextFormInput({
        label: `Session Type Description`,
        key: 'sessionTypeDescription',
        value: profile.sessionType.sessionTypeDescription,
        order: 4,
        disabled: true,
      }),
    ];
    artistSessionTypeFormGroups.push(sessionTypeInfoControls);
    
    return artistSessionTypeFormGroups;
  };

  getArtistSessionFormGroups(artistSession) {
    const profile = artistSession; 

    const artistSessionFormGroups: BaseFormInput<string | string[]>[][] = [];

    const sessionInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Artist Session ID`,
        key: 'artistSessionId',
        value: profile.sessionId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Artist Session Status`,
        controlType: `dropdown`,
        key: 'artistSessionStatusCode',
        value: profile.status.statusName,
        options: this.profileOptionsService.statusOptions,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Session Type`,
        key: 'sessionType',
        value: profile.sessionType.sessionTypeName,
        options: this.profileOptionsService.sessionTypeOptions,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Session Name`,
        key: 'sessionName',
        value: profile.name,
        order: 4,
        disabled: true,
      }),
      new TextFormInput({
        label: `Session Description`,
        key: 'sessionDescription',
        value: profile.description,
        order: 4,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Times Used`,
        key: 'sessionTimesUsed',
        value: `${profile.timesUsed}`,
        order: 4,
        disabled: true,
      }),
    ];
    artistSessionFormGroups.push(sessionInfoControls);
    
    return artistSessionFormGroups;
  };

  getArtistUserFormGroups(artistUser) {
    const profile = artistUser; 

    const artistUserFormGroups: BaseFormInput<string | string[]>[][] = [];

    const artistUserInfoControls: BaseFormInput<string>[] = [
      new TextFormInput({
        label: `Artist User ID`,
        key: 'artistUserId',
        value: profile.artistUserId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Artist User Status`,
        key: 'artistUserStatus',
        value: profile.status.statusName,
        order: 6,
        options: this.profileOptionsService.statusOptions,
        required: true,
        disabled: true,
      }),
    ];
    artistUserFormGroups.push(artistUserInfoControls);

    const userInfoControls: BaseFormInput<string>[] = [
      new TextFormInput({
        label: `User ID`,
        key: 'userId',
        value: profile.userProfile.SVRID,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Username`,
        key: 'username',
        value: profile.userProfile.username,
        order: 1,
        disabled: true,
      }),
    ];
    artistUserFormGroups.push(userInfoControls);

    return artistUserFormGroups;
  };


  getUserLinkFormGroups(userLink) {
    const profile = userLink; 

    const userLinkFormGroups: BaseFormInput<string | string[]>[][] = [];

    const linkInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Link ID`,
        key: 'linkId',
        value: profile.userLinkId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Link Status`,
        controlType: `dropdown`,
        key: 'linkStatusCode',
        value: profile.publishStatus.publishStatusName,
        options: this.profileOptionsService.publishStatusOptions,
        order: 1,
        disabled: !this.editing,
      }),
      new BreakFormInput({controlType: `break`}),
      new TextFormInput({
        label: `Link Name`,
        key: 'linkName',
        value: profile.userLinkName,
        order: 4,
        disabled: !this.editing,
      }),
      new TextFormInput({
        label: `Link URL`,
        key: 'linkUrl',
        value: profile.webAddress,
        order: 4,
        disabled: !this.editing,
      }),
      // new DropdownFormInput({
      //   label: `Linked Type`,
      //   controlType: `dropdown`,
      //   key: 'linkTypeName',
      //   value: profile.linkType.linkTypeName,
      //   options: this.profileOptionsService.linkTypeOptions,
      //   order: 1,
      //   disabled: !this.editing,
      // }),
    ];
    userLinkFormGroups.push(linkInfoControls);
    
    return userLinkFormGroups;
  }

  getUserLinkedPlatformProfileFormGroups(userLinkedPlatformProfile) {
    const profile = userLinkedPlatformProfile; 

    const userLinkedPlatformProfileFormGroups: BaseFormInput<string | string[]>[][] = [];

    const linkedPlatformProfileInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Linked Platform Profile ID`,
        key: 'linkedPlatformProfileId',
        value: profile.linkedPlatformProfileId,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Linked Platform Profile Name`,
        key: 'linkedPlatformProfileName',
        value: profile.linkedPlatformProfileName,
        order: 2,
        disabled: !this.editing,
      }),
      new DropdownFormInput({
        label: `Linked Platform Profile Status`,
        controlType: `dropdown`,
        key: 'linkedPlatformProfileStatusCode',
        value: profile.publishStatus.publishStatusName,
        options: this.profileOptionsService.publishStatusOptions,
        order: 1,
        disabled: !this.editing,
      }),
    ];
    userLinkedPlatformProfileFormGroups.push(linkedPlatformProfileInfoControls);

    const linkedPlatformInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Linked Platform ID`,
        key: 'linkedPlatformId',
        value: profile.linkedPlatform.linkedPlatformId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Linked Platform Status`,
        controlType: `dropdown`,
        key: 'linkedPlatformProfileStatusCode',
        value: profile.linkedPlatform.publishStatus.publishStatusName,
        options: this.profileOptionsService.publishStatusOptions,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Linked Platform Name`,
        key: 'linkedPlatformName',
        value: profile.linkedPlatform.linkedPlatformName,
        order: 4,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Linked Platform Type`,
        controlType: `dropdown`,
        key: 'linkedPlatformTypeName',
        value: profile.linkedPlatform.linkedPlatformType.linkedPlatformTypeName,
        options: this.profileOptionsService.linkedPlatformTypeOptions,
        order: 1,
        disabled: true,
      }),
    ];
    userLinkedPlatformProfileFormGroups.push(linkedPlatformInfoControls);

    return userLinkedPlatformProfileFormGroups;
  };

  getUserImageFormGroups(userImage) {
    const profile = userImage; 

    const userImageFormGroups: BaseFormInput<string | string[]>[][] = [];

    const userImageInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `User Image ID`,
        key: 'userImageId',
        value: profile.userImageId,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `User Image Name`,
        key: 'userImageName',
        value: profile.imageName,
        order: 2,
        disabled: !this.editing,
      }),
      new TextFormInput({
        label: `User Image Description`,
        key: 'userImageDescription',
        value: profile.imageDescription,
        order: 2,
        disabled: !this.editing,
      }),
      new DropdownFormInput({
        label: `User Image Status`,
        controlType: `dropdown`,
        key: 'userImageStatusCode',
        value: profile.status.statusName,
        options: this.profileOptionsService.statusOptions,
        order: 1,
        disabled: !this.editing,
      }),
      new BreakFormInput({controlType: `break`}),
      new BreakFormInput({controlType: `break`}),
      new ImageFormInput({
        label: `User Image`,
        key: 'image',
        value: profile.imageUrl !== null ? profile.imageUrl : '',
        order: 3,
        required: false,
        disabled: !this.editing,
      }),
    ];
    userImageFormGroups.push(userImageInfoControls);

    return userImageFormGroups;
  }

  getUserSessionFormGroups(userSession) {
    const profile = userSession; 

    const userSessionFormGroups: BaseFormInput<string | string[]>[][] = [];

    const sessionUserInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `User Session ID`,
        key: 'sessionUserId',
        value: profile.sessionUserId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Session User Status`,
        controlType: `dropdown`,
        key: 'sessionUserStatusCode',
        value: profile.status.statusName,
        options: this.profileOptionsService.statusOptions,
        order: 1,
        disabled: !this.editing,
      }),
    ];
    userSessionFormGroups.push(sessionUserInfoControls);

    const sessionInfoControls: BaseFormInput<string>[] = [
      new DropdownFormInput({
        label: `Session Status`,
        controlType: `dropdown`,
        key: 'sessionStatusCode',
        value: profile.session.status.statusName,
        options: this.profileOptionsService.statusOptions,
        order: 1,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Session ID`,
        key: 'sessionId',
        value: profile.session.sessionId,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `Session Type`,
        controlType: `dropdown`,
        key: 'sessionType',
        value: profile.session.sessionType.sessionTypeName,
        options: this.profileOptionsService.sessionTypeOptions,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Session Description`,
        key: 'sessionDescription',
        value: profile.session.description,
        order: 2,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Times Used`,
        key: 'timesUsed',
        value: `${profile.session.timesUsed}`,
        order: 4,
        disabled: true,
      }),
    ];
    userSessionFormGroups.push(sessionInfoControls);

    return userSessionFormGroups;
  };

  getUserInventoryFormGroups(userInventoryItem) {
    const profile = userInventoryItem; 

    const userInventoryItemFormGroups: BaseFormInput<string | string[]>[][] = [];

    const userInventoryItemInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `User Inventory Item ID`,
        key: 'userInventoryItemId',
        value: `${profile.userInventoryItemId}`,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `User Inventory Item Status`,
        key: 'userInventoryStatusCode',
        value: `${profile.status.statusName}`,
        order: 2,
        options: this.profileOptionsService.statusOptions,
        disabled: !this.editing,
      }),
      new DateFormInput({
        label: `Date Purchased`,
        key: 'createdTime',
        value: `${profile.createdTime}`,
        order: 3,
        disabled: !this.editing,
      }),
    ];
    userInventoryItemFormGroups.push(userInventoryItemInfoControls);

    const inventoryItemInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Inventory Item ID`,
        key: 'inventoryItemId',
        value: `${profile.inventoryItem.id}`,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Display Name`,
        key: 'displayName',
        value: `${profile.inventoryItem.displayName}`,
        order: 2,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Harmonia Cost`,
        key: 'harmoniaCost',
        value: `${profile.inventoryItem.harmoniaCost}`,
        order: 3,
        disabled: !this.editing,
      }),
    ];
    userInventoryItemFormGroups.push(inventoryItemInfoControls);

    return userInventoryItemFormGroups;
  };

  getUserInventoryTransactionFormGroups(userInventoryTransactionItem) {
    const profile = userInventoryTransactionItem; 

    const userInventoryTransactionItemFormGroups: BaseFormInput<string | string[]>[][] = [];

    const userInventoryTransactionItemInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `User Inventory Transaction ID`,
        key: 'userInventoryTransactionId',
        value: `${profile.userInventoryTransactionsId}`,
        order: 1,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Harmonia Paid`,
        key: 'harmoniaPaid',
        value: `${profile.harmoniaPaid}`,
        order: 2,
        disabled: true,
      }),
      new DateFormInput({
        label: `Date Purchased`,
        key: 'createdTime',
        value: `${profile.createdTime}`,
        order: 3,
        disabled: !this.editing,
      }),
    ];
    userInventoryTransactionItemFormGroups.push(userInventoryTransactionItemInfoControls);

    const inventoryItemInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Inventory Item ID`,
        key: 'inventoryItemId',
        value: `${profile.inventoryItem.id}`,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Display Name`,
        key: 'displayName',
        value: `${profile.inventoryItem.displayName}`,
        order: 2,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Harmonia Cost`,
        key: 'harmoniaCost',
        value: `${profile.inventoryItem.harmoniaCost}`,
        order: 3,
        disabled: !this.editing,
      }),
    ];
    userInventoryTransactionItemFormGroups.push(inventoryItemInfoControls);

    return userInventoryTransactionItemFormGroups;
  };

  getUserPerkFormGroups(userPerk) {
    const profile = userPerk; 

    const userPerkFormGroups: BaseFormInput<string | string[]>[][] = [];

    const userPerkInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `User Perk ID`,
        key: 'userPerkId',
        value: `${profile.userPerkId}`,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `User Perk Status`,
        key: 'userPerkStatusCode',
        value: `${profile.status.statusName}`,
        order: 2,
        options: this.profileOptionsService.statusOptions,
        disabled: !this.editing,
      }),
      new DateFormInput({
        label: `Date Purchased`,
        key: 'createdTime',
        value: `${profile.createdTime}`,
        order: 3,
        disabled: !this.editing,
      }),
    ];
    userPerkFormGroups.push(userPerkInfoControls);

    const perkInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Perk ID`,
        key: 'perkId',
        value: `${profile.perk.id}`,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Display Name`,
        key: 'displayName',
        value: `${profile.perk.displayName}`,
        order: 2,
        disabled: true,
      }),
      new TextFormInput({
        label: `Description`,
        key: 'perkDescription',
        value: `${profile.perk.perkDescription}`,
        order: 3,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Level Req`,
        key: 'levelReq',
        value: `${profile.perk.levelReq}`,
        order: 4,
        disabled: true,
      }),
    ];
    userPerkFormGroups.push(perkInfoControls);

    return userPerkFormGroups;
  };

  getUserTransactionFormGroups(userTransactionItem) {
    const profile = userTransactionItem; 

    const userTransactionItemFormGroups: BaseFormInput<string | string[]>[][] = [];

    const userTransactionItemInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `User Transaction ID`,
        key: 'userInventoryItemId',
        value: `${profile.userTransactionId}`,
        order: 1,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Transaction Quantity`,
        key: 'quantity',
        value: `${profile.quantity}`,
        order: 2,
        disabled: true,
      }),
      new TextFormInput({
        label: `Transaction Platform Type`,
        key: 'platformType',
        value: `${profile.vrPlatform.type.vrPlatformTypeName}`,
        order: 3,
        disabled: !this.editing,
      }),
      new DropdownFormInput({
        label: `Transaction Status`,
        key: 'platformType',
        value: `${profile.userTransactionStatus.userTransactionStatusName}`,
        order: 3,
        options: this.profileOptionsService.statusOptions,
        disabled: !this.editing,
      }),
    ];
    userTransactionItemFormGroups.push(userTransactionItemInfoControls);

    const transactionItemInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Transaction Item ID`,
        key: 'inventoryItemId',
        value: `${profile.transactionItem.transactionItemId}`,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Display Name`,
        key: 'displayName',
        value: `${profile.transactionItem.transactionItemName}`,
        order: 2,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Dollar Cost`,
        key: 'cost',
        value: `${profile.transactionItem.cost}`,
        order: 3,
        disabled: !this.editing,
      }),
      new TextFormInput({
        label: `Currency Type`,
        key: 'currencyType',
        value: `${profile.transactionItem.currencyType}`,
        order: 2,
        disabled: true,
      }),
      new TextFormInput({
        label: `Description`,
        key: 'description',
        value: `${profile.transactionItem.description}`,
        order: 2,
        disabled: true,
      }),
      new NumberFormInput({
        label: `Harmonia Amount`,
        key: 'harmoniaAmount',
        value: `${profile.transactionItem.harmoniaAmount}`,
        order: 3,
        disabled: !this.editing,
      }),
      new NumberFormInput({
        label: `SKU`,
        key: 'sku',
        value: `${profile.transactionItem.sku}`,
        order: 3,
        disabled: !this.editing,
      }),
    ];
    userTransactionItemFormGroups.push(transactionItemInfoControls);

    return userTransactionItemFormGroups;
  };

  getUserVrPlatformProfileFormGroups(userVrPlatformProfile) {
    const profile = userVrPlatformProfile; 

    const userVrPlatformProfileFormGroups: BaseFormInput<string | string[]>[][] = [];

    const userVrPlatformProfileInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `VR Platform Profile ID`,
        key: 'vrPlatformProfileId',
        value: `${profile.vrPlatformProfileId}`,
        order: 1,
        disabled: true,
      }),
      new DropdownFormInput({
        label: `VR Platform Profile Status`,
        key: 'vrPlatformProfileStatusCode',
        value: `${profile.status.statusName}`,
        order: 2,
        options: this.profileOptionsService.statusOptions,
        disabled: !this.editing,
      }),
      new DateFormInput({
        label: `Date Added to SVR`,
        key: 'createdTime',
        value: `${profile.createdTime}`,
        order: 3,
        disabled: !this.editing,
      }),
      new TextFormInput({
        label: `VR Platform User ID`,
        key: 'vrPlatformProfileUserId',
        value: `${profile.vrPlatformUserId}`,
        order: 4,
        disabled: true,
      }),
    ];
    userVrPlatformProfileFormGroups.push(userVrPlatformProfileInfoControls);

    const vrPlatformInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `VR Platform ID`,
        key: 'vrPlatformId',
        value: `${profile.vrPlatform.vrPlatformId}`,
        order: 1,
        disabled: true,
      }),
      new TextFormInput({
        label: `Display Name`,
        key: 'vrPlatformDisplayName',
        value: `${profile.vrPlatform.vrPlatformName}`,
        order: 2,
        disabled: true,
      }),
      new TextFormInput({
        label: `Description`,
        key: 'vrPlatformDescription',
        value: `${profile.vrPlatform.vrPlatformDescription}`,
        order: 3,
        disabled: true,
      }),
    ];
    userVrPlatformProfileFormGroups.push(vrPlatformInfoControls);

    return userVrPlatformProfileFormGroups;
  };

  getFormGroupType(fragment) {
    console.log(fragment);
    let newFormGroup;
    let newFormGroupArray;
    
    switch(fragment) {
      case 'user':
      case 'User': {
        console.log('user case');
        newFormGroup = new CreateUserFormGroupsModel(this.profileOptionsService);
        console.log(newFormGroup);
        newFormGroupArray = [
          newFormGroup.info,
          {
            controls: newFormGroup.artists, 
            type: 'list', 
            fragment: 'artists', 
            title: 'Artists',
            listType: 'artists',
            listItems: [],
          },
          {
            controls: newFormGroup.linkedPlatformProfiles, 
            type: 'list', 
            fragment: 'linkedPlatformProfiles', 
            title: 'Linked Platform Profiles',
            listType: 'linkedPlatformProfiles',
            listItems: [],
          },
          {
            controls: newFormGroup.userImages, 
            type: 'list', 
            fragment: 'userImages', 
            title: 'Images',
            listType: 'userImages',
            listItems: [],
          },
          {
            controls: newFormGroup.userSessions, 
            type: 'list', 
            fragment: 'userSessions', 
            title: 'User Sessions',
            listType: 'userSessions',
            listItems: [],
          },
          {
            controls: newFormGroup.userInventory, 
            type: 'list', 
            fragment: 'userInventory', 
            title: 'Inventory',
            listType: 'userInventory',
            listItems: [],
          },
          // {
          //   controls: newFormGroup.inventoryTransactions, 
          //   type: 'list', 
          //   fragment: 'userInventoryTransactions', 
          //   title: 'Inventory Transactions',
          //   listType: 'userInventoryTransactions',
          //   listItems: [],
          // },
          {
            controls: newFormGroup.userPerks, 
            type: 'list', 
            fragment: 'userPerks', 
            title: 'Perks',
            listType: 'userPerks',
            listItems: [],
          },
          {
            controls: newFormGroup.vrPlatformProfiles, 
            type: 'list', 
            fragment: 'vrPlatformProfiles', 
            title: 'VR Platform Profiles',
            listType: 'vrPlatformProfiles',
            listItems: [],
          },
        ]
        console.log(newFormGroupArray)
        break;
      }
      case 'info': {
        console.log('info case')
        newFormGroup = new CreateUserPersonalInformationFormGroupsModel(this.profileOptionsService);
        console.log(newFormGroup);
        newFormGroupArray = newFormGroup
        break;
      }
      
      case 'artists': {
        newFormGroup = new CreateUserArtistsFormGroupsModel(this.profileOptionsService);
        newFormGroupArray = [
          {
            controls: newFormGroup.artistProfileInfoControls, 
            type: 'single', 
            fragment: 'artistProfileInformation', 
            title: 'Artist Profile Information'
          },
          {
            controls: newFormGroup.artistUserInfoControls, 
            type: 'single', 
            fragment: 'artistUserInformation', 
            title: 'Artist User Information'
          },
          {
            controls: newFormGroup.artistUserRoles, 
            listItems: [],
            type: 'list', 
            fragment: 'artistUserRoles', 
            title: 'Artist User Role Information',
            listType: 'artistUserRoles'
          },
        ]
        console.log(newFormGroupArray);
        break;
      }
      case 'artistUserRoles': {
        newFormGroup = new CreateUserArtistsRoleFormGroupsModel(this.profileOptionsService);
        newFormGroupArray = [
          {
            controls: newFormGroup.artistUserRoleInfoControls, 
            type: 'single', 
            fragment: 'artistUserRoleInformation', 
            title: 'Artist User Role Information'
          },
          {
            controls: newFormGroup.roleInfoControls, 
            type: 'single', 
            fragment: 'roleInformation', 
            title: 'Role Information'
          },
        ]
        console.log(newFormGroupArray);
        break;
      }
      case 'linkedPlatformProfiles': {
        newFormGroup = new CreateUserLinkedPlatformProfilesFormGroupsModel(this.profileOptionsService);
        newFormGroupArray = [
          {
            controls: newFormGroup.linkedPlatformProfileInfoControls, 
            type: 'single', 
            fragment: 'userLinkedPlatformProfileInformation', 
            title: 'Linked Platform Profile Information'
          },
          {
            controls: newFormGroup.linkedPlatformInfoControls, 
            type: 'single', 
            fragment: 'userLinkedPlatformInformation', 
            title: 'Linked Platform Information'
          },
        ]
        break;
      }
      case 'userLinks':
      case 'userLink': {
        newFormGroup = new CreateUserLinksFormGroupsModel(this.profileOptionsService);
        newFormGroupArray = [
          {
            controls: newFormGroup.userLinkInfoControls, 
            type: 'single', 
            fragment: 'userLinkInformation', 
            title: 'User Link Information'
          },
          // {
          //   controls: newFormGroup.linkedPlatformInfoControls, 
          //   type: 'single', 
          //   fragment: 'userLinkedPlatformInformation', 
          //   title: 'Linked Platform Information'
          // },
        ]
        break;
      }
      case 'userImages': {
        newFormGroup = new CreateUserImagesFormGroupsModel(this.profileOptionsService);
        newFormGroupArray = [
          {
            controls: newFormGroup.userImageInfoControls, 
            type: 'single', 
            fragment: 'userImageInformation', 
            title: 'Image Information'
          },
        ]
        break;
      }
      case 'userSessions': {
        newFormGroup = new CreateUserSessionsFormGroupsModel(this.profileOptionsService);
        newFormGroupArray = [
          {
            controls: newFormGroup.sessionUserInfoControls, 
            type: 'single', 
            fragment: 'userSessionUserInformation', 
            title: 'Session User Information'
          },
          {
            controls: newFormGroup.sessionInfoControls, 
            type: 'single', 
            fragment: 'userSessionInformation', 
            title: 'Session Information'
          },
        ]
        break;
      }
      case 'userInventory': {
        newFormGroup = new CreateUserInventoryFormGroupsModel(this.profileOptionsService);
        newFormGroupArray = [
          {
            controls: newFormGroup.userInventoryItemInfoControls, 
            type: 'single', 
            fragment: 'userInventoryInformation', 
            title: 'Inventory Information'
          },
          {
            controls: newFormGroup.inventoryItemInfoControls, 
            type: 'single', 
            fragment: 'inventoryItemInformation', 
            title: 'Inventory Item Information'
          },
        ]
        break;
      }
      case 'userInventoryTransactions': {
        newFormGroup = new CreateUserInventoryTransactionsFormGroupsModel(this.profileOptionsService);
        newFormGroupArray = [
          {
            controls: newFormGroup.userInventoryTransactionItemInfoControls, 
            type: 'single', 
            fragment: 'userInventoryTransactionInformation', 
            title: 'Inventory Transaction Item Information'
          },
          {
            controls: newFormGroup.inventoryItemInfoControls, 
            type: 'single', 
            fragment: 'inventoryItemInformation', 
            title: 'Inventory Item Information'
          },
        ]
        break;
      } 
      case 'userPerks': {
        newFormGroup = new CreateUserPerksFormGroupsModel(this.profileOptionsService);
        newFormGroupArray = [
          {
            controls: newFormGroup.userPerkInfoControls, 
            type: 'single', 
            fragment: 'userPerkInformation', 
            title: 'User Perk Information'
          },
          {
            controls: newFormGroup.perkInfoControls, 
            type: 'single', 
            fragment: 'perkInformation', 
            title: 'Perk Information'
          },
        ]
        break;
      }
      case 'vrPlatformProfiles': {
        newFormGroup = new CreateUserVrPlatformProfilesFormGroupsModel(this.profileOptionsService);
        newFormGroupArray = [
          {
            controls: newFormGroup.userVrPlatformProfileInfoControls, 
            type: 'single', 
            fragment: 'userVrPlatformProfileInformation', 
            title: 'VR Platform Profile Information'
          },
          {
            controls: newFormGroup.vrPlatformInfoControls, 
            type: 'single', 
            fragment: 'vrPlatformInformation', 
            title: 'VR Platform Information'
          },
        ]
        break;
      }
      
      default: {
        break;
      }
    }
    console.log(newFormGroup);
    console.log(newFormGroupArray)

    return newFormGroupArray
  }

  transformFormData(data) {
    console.log('transformFormData - start', data);
    console.log(data.artists)
    console.log(data.artists.length)
    let artists = undefined;
    let artistUserRoles = undefined;
    console.log('artistUserRoles', artistUserRoles)
    if (data.artists.length > 0) {
      artists = [];
      for (let artist of data.artists) {
        artistUserRoles = [];
        console.log('artistUserRoles', artistUserRoles)
        if (artist.artistUserRoles.length > 0) {
          for (let artistUserRole of artist.artistUserRoles) {
            console.log(artist);
            const artistUserRoleObject = {
              artistUserRoleStatusCode: artistUserRole.artistUserRoleInformation.artistUserRoleStatusCode,
              artistRoleId: parseInt(artistUserRole.roleInformation.artistRoleId),
            }
            artistUserRoles.push(artistUserRoleObject);
            console.log('artistUserRoles', artistUserRoles)
          }
        }
        console.log('artistUserRoles', artistUserRoles)
        const userArtist = {
          artistUserId: parseInt(artist.artistUserInformation.artistUserId),
          artistUserStatus: artist.artistUserInformation.artistUserStatus,
          artistUserRoles: artistUserRoles,
          artistProfileId: parseInt(artist.artistProfileInformation.artistProfileId)
          // artistProfileName: artist.artistProfileInformation.artistProfileName
        }
        artists.push(userArtist);
      }
      console.log(artists);
    }

    let linkedPlatformProfiles = undefined;
    if (data.linkedPlatformProfiles.length > 0) {
      linkedPlatformProfiles = [];
      for (let linkedPlatformProfile of data.linkedPlatformProfiles) {
        console.log(linkedPlatformProfile);
        const userLinkedPlatformProfile = {
          linkedPlatformProfileId: parseInt(linkedPlatformProfile.userLinkedPlatformProfileInformation.linkedPlatformProfileId),
          linkedPlatformProfileName: linkedPlatformProfile.userLinkedPlatformProfileInformation.linkedPlatformProfileName,
          linkedPlatformProfileStatusCode: linkedPlatformProfile.userLinkedPlatformProfileInformation.linkedPlatformProfileStatusCode,
          linkedPlatformId: parseInt(linkedPlatformProfile.userLinkedPlatformInformation.linkedPlatformId),
          linkedPlatformName: linkedPlatformProfile.userLinkedPlatformInformation.linkedPlatformName,
          userLinks: undefined,
        }
        // user links
        let userLinks = undefined;
        console.log(linkedPlatformProfile)
        console.log(linkedPlatformProfile.userLinks);
        console.log(linkedPlatformProfile.userLinks[0].hasOwnProperty('userLinkInformation'));
        if (linkedPlatformProfile.userLinks.length > 0 && linkedPlatformProfile.userLinks[0].hasOwnProperty('userLinkInformation')) {
          userLinks = [];
          for (let link of linkedPlatformProfile.userLinks) {
            console.log(link);
            const userLink = {
              userLinkId: parseInt(link.userLinkInformation.linkId),
              linkUrl: link.userLinkInformation.linkUrl,
              linkStatusCode: link.userLinkInformation.linkStatusCode,
              linkName: link.userLinkInformation.linkName,
            }
            userLinks.push(userLink);
          }
          userLinkedPlatformProfile.userLinks = userLinks;
        }
        console.log(userLinkedPlatformProfile)
        linkedPlatformProfiles.push(userLinkedPlatformProfile);
      }
      console.log(linkedPlatformProfiles)
    }

    let userImages = undefined
    if (data.userImages.length > 0) {
      userImages = [];
      for (let image of data.userImages) {
        const userImage = {
          userImageId: parseInt(image.userImageInformation.userImageId),
          userImageStatus: image.userImageInformation.userImageStatusCode,
          userImageName: image.userImageInformation.userImageName,
          userImageDescription: image.userImageInformation.userImageDescription,
          userImageUrl: image.userImageInformation.image,
        }
        userImages.push(userImage);
      }
    }

    let userSessions = undefined;
    if (data.userSessions.length > 0) {
      userSessions = [];
      for (let session of data.userSessions) {
        const userSession = {
          sessionUserId: parseInt(session.userSessionUserInformation.sessionUserId),
          sessionUserStatus: session.userSessionUserInformation.sessionUserStatusCode,
          sessionId: parseInt(session.userSessionInformation.sessionId),
          // sessionStatus: session.userSessionInformation.sessionStatusCode,
          // description: session.userSessionInformation.sessionDescription,
          // artistLinkSessionsStreamLink: session.userSessionInformation.artistLinkSessionsStreamLink,
          // timesUsed: session.userSessionInformation.timesUsed,
        }
        userSessions.push(userSession);
      }
    }

    let userInventory = undefined;
    if (data.userInventory.length > 0) {
      userInventory = [];
      for (let inventoryItem of data.userInventory) {
        const userInventoryItem = {
          userInventoryItemId: parseInt(inventoryItem.userInventoryInformation.userInventoryItemId),
          userInventoryStatusCode: inventoryItem.userInventoryInformation.userInventoryStatusCode,
          inventoryItemId: parseInt(inventoryItem.inventoryItemInformation.inventoryItemId),
        }
        userInventory.push(userInventoryItem);
      }
    }

    // let userInventoryTransactions = undefined;
    // if (data.userInventoryTransactions.length > 0) {
    //   userInventoryTransactions = [];
    //   for (let inventoryTransaction of data.userInventoryTransactions) {
    //     const userInventoryTransaction = {
          
    //     }
    //     userInventoryTransactions.push(userInventoryTransaction);
    //   }
    // }

    let userPerks = undefined;
    if (data.userPerks.length > 0) {
      userPerks = [];
      for (let userPerk of data.userPerks) {
        const userPerkItem = {
          userPerkId: parseInt(userPerk.userPerkInformation.userPerkId),
          userPerkStatusCode: userPerk.userPerkInformation.userPerkStatusCode,
          perkId: parseInt(userPerk.perkInformation.perkId),
        }
        userPerks.push(userPerkItem);
      }
    }

    // let userTransactions = undefined;
    // if (data.userTransactions.length > 0) {
    //   userTransactions = [];
    //   for (let userTransaction of data.userTransactions) {
    //     console.log(userTransaction);
    //     const userTransactionItem = {
          
    //     }
    //     console.log(userTransactionItem);
    //     userTransactions.push(userTransactionItem);
    //   }
    // }
    let vrPlatformProfiles = undefined;
    if (data.vrPlatformProfiles.length > 0) {
      vrPlatformProfiles = []
      for (let vrPlatformProfile of data.vrPlatformProfiles) {
        const vrPlatformProfileItem = {
          vrPlatformUserId: vrPlatformProfile.userVrPlatformProfileInformation.vrPlatformProfileUserId,
          vrPlatformProfileId: parseInt(vrPlatformProfile.userVrPlatformProfileInformation.vrPlatformProfileId),
          vrPlatformProfileStatusCode: vrPlatformProfile.userVrPlatformProfileInformation.vrPlatformProfileStatusCode,
          vrPlatformId: parseInt(vrPlatformProfile.vrPlatformInformation.vrPlatformId),
        }
        vrPlatformProfiles.push(vrPlatformProfileItem);
      }
    }

    const tData = {
      username: data.info.details.username === "" || 
                data.info.details.username === null || 
                data.info.details.username === undefined ? 
                "" : 
                data.info.details.username,
      // password: data.info.details.password,
      level: parseInt(data.info.details.level),
      userStatusName: data.info.details.status,
      userTypeName: data.info.details.type,
      analyticsStatusName: data.info.details.analyticsStatus,
      currency: {
        currency: parseInt(data.info.currency.harmonia),
        weekAccrual: parseInt(data.info.currency.weekAccrual),
        totalCheckinHarmonia: parseInt(data.info.currency.totalCheckinHarmonia),
        lastAccrual: data.info.currency.lastAccrual,
        perkPoints: parseInt(data.info.currency.perkPoints),
        checkinReset: data.info.currency.checkinReset,
      },
      achievements: {
        tutorialComplete: data.info.achievements.tutorialComplete === '' ? false : data.info.achievements.tutorialComplete,
        goldenRecordCount: parseInt(data.info.achievements.goldenRecordCount),
        reached100Multiplier: data.info.achievements.reached100Multiplier === '' ? false : data.info.achievements.reached100Multiplier,
        reached500Multiplier: data.info.achievements.reached500Multiplier === '' ? false : data.info.achievements.reached500Multiplier,
        reached1000Multiplier: data.info.achievements.reached1000Multiplier === '' ? false : data.info.achievements.reached1000Multiplier,
      },
      artists: artists,
      linkedPlatformProfiles: linkedPlatformProfiles,
      userImages: userImages,
      userSessions: userSessions,
      userInventory: userInventory,
      userPerks: userPerks,
      vrPlatformProfiles: vrPlatformProfiles,
    }

    return tData;
  }
}
