import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../core/auth/auth.service';
import {ArtistService} from '../../../../services/artist.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-artist-entry-form',
  templateUrl: './artist-entry-form.component.html',
  styleUrls: ['./artist-entry-form.component.css']
})
export class ArtistEntryFormComponent implements OnInit, OnDestroy {
  isLoading = true;
  authStatusSub: Subscription;
  editing: boolean = false;

  artists;

  artist;
  artistId: string;
  // private artistId: string = '123321';
  artistSub: Subscription;

  validationErrors: [];
  editArtistForm: FormGroup;

  userId: string;
  hasError = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private artistService: ArtistService
  ) { }

  ngOnInit(): void {
    // get's logged in user, subscribes to auth status and stop loading when connected
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      authStatus => {}
    );
    // automatically sets author to logged in user
    this.userId = this.authService.getUserId();



    this.artistSub = this.route.params.subscribe(
      value => {
        console.log(value);
        if (value.artist !== undefined) {
          console.log('editing is true');
          this.editing = value.editing;
          this.artistId = value.artist;
        } {
          return;
        }
      }
    );




    // get artists and try to find existing artist
    this.artists = this.artistService.getArtists();
    this.artists.forEach(artist => {
      if (artist._id === this.artistId) {
        return this.artist = artist;
      }
    });

    if (this.artist !== undefined) {
      this.editing = true;
      this.artistId = this.artist._id;
    }

    if (!this.editing) {
      this.editArtistForm = new FormGroup({
        editArtistContent: new FormGroup({
          name: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(160)]),
          website: new FormControl(null, [Validators.required, Validators.minLength(4)]),
          livestream: new FormControl(null, [Validators.required, Validators.minLength(4)]),
          linkId: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(160)]),
        }),
        editArtistMeta: new FormGroup({
          creator: new FormControl(null, [Validators.required]),
          artistId: new FormControl(null)
        })
      });
    } else {
      this.editArtistForm = new FormGroup({
        editArtistContent: new FormGroup({
          name: new FormControl(null, [Validators.minLength(4), Validators.maxLength(160)]),
          website: new FormControl(null, [Validators.minLength(4)]),
          livestream: new FormControl(null, [Validators.minLength(4)]),
          linkId: new FormControl(null, [Validators.minLength(4), Validators.maxLength(160)]),
        }),
        editArtistMeta: new FormGroup({
          creator: new FormControl(null),
          artistId: new FormControl(null)
        })
      });
    }

    // if editing mode is on, fill the form with existing values from server
    // in either case, add the creator by reading the current userId
    if (this.editing) {
      this.editArtistForm.patchValue({
        editArtistContent: {
          name: this.artist.name,
          website: this.artist.website,
          livestream: this.artist.livestream,
          linkId: this.artist.linkId,
        },
        editArtistMeta: {
          artistId: this.artistId
        }
      });
    }
    this.editArtistForm.patchValue({
      editArtistMeta: {
        creator: this.userId
      }
    });

    // subscribe to form status changes
    this.editArtistForm.statusChanges.subscribe(
      // (status) => console.log(status)
    );

    // subscribe to form value changes
    this.editArtistForm.valueChanges.subscribe(
      // (value) => {}
    );

    this.isLoading = false;
  }

  onSubmit(form) {
    console.log(`submitted`, form.value);
    this.isLoading = true;
    if (this.editing) {
      return this.submitEditArtistFormMutation(form.value);
    }
    this.submitAddArtistFormMutation(form.value);
  }

  submitAddArtistFormMutation(form) {
    console.log(`submit Add Artist args`, form);
    const artistInput = {
      name: form.editArtistContent.name,
      website: form.editArtistContent.website,
      livestream: form.editArtistContent.livestream,
      linkId: form.editArtistContent.linkId,
      artistId: form.editArtistMeta.artistId,
      creator: form.editArtistMeta.creator,
    };
    this.artistService.addArtist(artistInput);
  }

  submitEditArtistFormMutation(form) {
    console.log(`submit Edit Artist args`, form);
    const artistInput = {
      name: form.editArtistContent.name,
      website: form.editArtistContent.website,
      livestream: form.editArtistContent.livestream,
      linkId: form.editArtistContent.linkId,
      artistId: form.editArtistMeta.artistId,
      creator: form.editArtistMeta.creator,
    };
    this.artistService.editArtist(form.editArtistMeta.artistId, artistInput);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }

}
