import { Component, Injectable, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserOptionsService } from 'src/app/dash/services/user-options.service';
import { UserProfileService } from 'src/app/dash/services/user-profile.service';
import { environment } from 'src/environments/environment';
import { FormInputControlService } from '../form-items/form-input-control.service';

@Component({
  selector: 'app-dynamic-create-form',
  templateUrl: './dynamic-create-form.component.html',
  styleUrls: ['./dynamic-create-form.component.css']
})
export class DynamicCreateFormComponent implements OnInit {
  public BACKEND_URL = environment.apiUrl + '/users';

  @Injectable()parentForm
  @Injectable()formGroup
  @Injectable()categoryCardItem
  @Injectable()profileType
  @Injectable()editing
  @Injectable()mutation

  @Injectable()fragment

  formGroupType;
  newFormGroup;
  newFormGroupArray;
  newForm;

  categoryCardItems;

  payLoad = '';
  
  profileOptionData;
  public profileOptionsSubscription: Subscription;

  constructor(
    public profileOptionsService: UserOptionsService,
    public formInputControlService: FormInputControlService,
    public userProfileService: UserProfileService,
    // public patientProfileService: PatientProfileService,
    // public facilityProfileService: FacilityProfileService,
    // public providerProfileService: ProviderProfileService,
    // public staffProfileService: StaffProfileService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.parentForm = new FormGroup({});
    this.categoryCardItem = {};
    this.profileType = 'user';
    this.setUpCreateForm();
  }

  ngOnDestroy() {
    console.log(this.profileOptionsSubscription)
    if (this.profileOptionsSubscription !== undefined) {
      this.profileOptionsSubscription.unsubscribe();
    }
  }

  public setUpCreateForm() {
    console.log('fetche Profile Options');
    this.userProfileService.fetchProfileOptions()
      .subscribe(
        profileOptionData => {
          console.log(profileOptionData)
          this.profileOptionData = profileOptionData
          console.log(this.profileOptionData)
          console.log(this.profileOptionsSubscription)
          console.log(this.profileOptionData);
          this.getFormGroupType('user')
          this.createNewForm(this.newFormGroupArray)
        }
      ),
      error => {
        console.log(error);
      };
    return this.profileOptionsSubscription;
  }

  getFormGroupType(fragment) {
    switch(fragment) {
      case 'user': 
      case 'User': {
        console.log(fragment);
        this.newFormGroupArray = this.userProfileService.getFormGroupType(fragment);
        break;
      }
      // case 'artist':
      // case 'Artist': {
      //   this.newFormGroupArray = this.facilityProfileService.getFormGroupType(fragment);
      //   break;
      // }
    }
    return this.newFormGroupArray;
  }

  createNewForm(formInputModel) {
    console.log(formInputModel);
    const formGroups = this.formInputControlService.blankFormInputsToFormGroup(formInputModel);
    this.newForm = formGroups.newForm;
    this.categoryCardItems = formGroups.categoryCardListItems;

    return this.newForm;
  }

  createNewRecord(formInputModel) {
    console.log(this.categoryCardItems)
    console.log(formInputModel);

    this.payLoad = JSON.stringify(formInputModel.getRawValue());
    console.log(this.payLoad);
    this.userProfileService.createNewRecord(this.BACKEND_URL, this.payLoad)
      .subscribe(( rdata ) => {
        console.log('got data', rdata);
        // this.loading = false;
        // console.log(`../${rdata.data.createUser.createUser.SVRID}`)
        console.log(`/dash/user/profile/${rdata.data.user.SVRID}`);
        this.router.navigateByUrl(`/dash/user/profile/${rdata.data.user.SVRID}`)
        // this.router.navigateByUrl(`../${rdata.data.createPatient.patient.id}`)
      })
  }
}
