<div class="admin-form-tab-group">
  <nav mat-tab-nav-bar [backgroundColor]="background" [color]="color">
    <a
      mat-tab-link
      class="admin-form-tab"
      *ngFor="let link of links"
      (click)="activeLink = link.path"
      [active]="activeLink == link.path"
      [routerLink]="[link.path]"
      [disabled]="link.path === 'artist-entry' || link.path === 'artist-schedule' || link.path === 'artist-list'"
    > {{ link.label }}</a>
  </nav>
</div>
<h3
  *ngIf="!activeLink"
  class="admin-form-tab-start-text"
>Click a tab to get started</h3>
<router-outlet></router-outlet>
<!--<mat-tab-group dynamicHeight class="admin-form-tab-group">-->
<!--  <mat-tab label="Latest News">-->
<!--    <div class="admin-form-tab mat-elevation-z4">-->
<!--      <app-latest-news-form></app-latest-news-form>-->
<!--    </div>-->
<!--  </mat-tab>-->
<!--  <mat-tab label="New Artist Entry">-->
<!--    <div class="admin-form-tab mat-elevation-z4">-->
<!--      <app-artist-entry-form></app-artist-entry-form>-->
<!--    </div>-->
<!--  </mat-tab>-->
<!--  <mat-tab label="Artist Schedule">-->
<!--    <div class="admin-form-tab mat-elevation-z4">-->
<!--      <app-artist-schedule-form></app-artist-schedule-form>-->
<!--    </div>-->
<!--  </mat-tab>-->
<!--  <mat-tab label="Artist List">-->
<!--    <div class="admin-form-tab mat-elevation-z4">-->
<!--      <app-artist-list></app-artist-list>-->
<!--    </div>-->
<!--  </mat-tab>-->
<!--</mat-tab-group>-->
