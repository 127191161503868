import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../core/auth/auth.service';
import {NewsService} from '../../../../services/news.service';
import {MessagingService} from '../../../../services/messaging.service';

@Component({
  selector: 'app-message-blast-form',
  templateUrl: './message-blast-form.component.html',
  styleUrls: ['./message-blast-form.component.css']
})
export class MessageBlastFormComponent implements OnInit, OnDestroy {
  isLoading = true;
  authStatusSub: Subscription;
  userId: string;

  audienceGroups;

  validationErrors: [];
  messageBlastForm = new FormGroup({
    messageBlastContent: new FormGroup({
      body: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(160)]),
    }),
    messageBlastAudience: new FormGroup({
      audienceGroups: new FormControl(null, [Validators.required]),
    }),
    messageBlastMeta: new FormGroup({
      creator: new FormControl(null, [Validators.required]),
    })
  });
  hasError = false;

  constructor(
    private authService: AuthService,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    // get's logged in user, subscribes to auth status and stop loading when connected
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      authStatus => {}
    );
    // automatically sets author to logged in user
    this.userId = this.authService.getUserId();
    this.messageBlastForm.patchValue({
      messageBlastMeta: {
        creator: this.userId
      }
    });

    this.audienceGroups = this.messagingService.getAudienceGroups();

    // subscribe to form status changes
    this.messageBlastForm.statusChanges.subscribe(
      // (status) => console.log(status)
    );

    // subscribe to form value changes
    this.messageBlastForm.valueChanges.subscribe(
      // (value) => console.log(value)
    );

    this.isLoading = false;
  }

  onSubmit(form) {
    console.log(`submitted`, form.value);
    this.isLoading = true;
    this.submitMessageBlastForm(form.value);
  }

  submitMessageBlastForm(form) {
    console.log(`submit mutation args`, form);
    const messageBlastInput = {
      body: form.messageBlastContent.body,
      audienceGroups: form.messageBlastAudience.audienceGroups,
      creator: form.messageBlastMeta.creator,
    };
    this.messagingService.sendMessage(messageBlastInput);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}
