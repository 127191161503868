<mat-card class="profile-action-panel-card">
    <mat-card-header class="action-panel-header-container">
        <!-- <div class="action-panel-header-image-container">
            <img class="action-panel-header-image" src="{{actionPanelContent.header.avatar.imgSRC}}">
        </div> -->
        <div class="action-panel-header-info">
            <div *ngFor="let headerField of actionPanelContent.header.fields">{{ headerField.value }}</div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="action-panel-body-info">
            <div 
                class="action-panel-body-info-container"
                *ngFor="let body of actionPanelContent.body"
            >
                <div class="action-panel-body-info_header">{{ body.header }}</div>
                <div class="action-panel-body-info_body-container">
                    <div class="action-panel-body-info_body">{{ body.body }}</div>
                </div>
                <a mat-button class="action-
                panel-body-info_actions" *ngFor="let action of body.actions" (click)="onClickAction(action.link)">{{ action.text }}</a>    
                <div class="action-panel-body-info_subheader">{{ body.subheader }}</div>
                <div class="action-panel-body-info_subbody">{{ body.subbody }}</div>
                <br>
                <!-- <hr> -->
                <mat-divider [inset]="true"></mat-divider>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions class="profile-action-card-action-container">
        <div  
            class="profile-action-card-action"
            *ngFor="let action of actionPanelContent.actions"
        >
            <a 
                mat-button
                (click)="onClickAction(action.text)"
            >
                {{ action.text }}
            </a>
        </div>
    </mat-card-actions>
    
</mat-card>
