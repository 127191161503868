import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { UserOptionsService } from 'src/app/dash/services/user-options.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { BaseFormInput } from '../../profile/forms/form-items/base-form-input';
import { DateFormInput } from '../../profile/forms/form-items/date-form-input';
import { DropdownFormInput } from '../../profile/forms/form-items/dropdown-form-input';
import { FormInputControlService } from '../../profile/forms/form-items/form-input-control.service';
import { ImageFormInput } from '../../profile/forms/form-items/image-form-input';
import { NumberFormInput } from '../../profile/forms/form-items/number-form-input';
import { TextFormInput } from '../../profile/forms/form-items/text-form-input';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.css']
})
export abstract class ProfileViewComponent implements OnInit, OnDestroy {
  abstract BACKEND_URL: String;

  public profileSubscription: Subscription;
  public profileOptionsSubscription: Subscription;

  public editProfileIndexSubscription: Subscription;

  public profileData: {type: string, actionPanelData: {}, categoryCardItems: any[], loading: boolean, form: FormGroup} = {
    type: 'default',
    actionPanelData: {},
    categoryCardItems: [],
    loading: true,
    form: undefined,
  };

  public profileOptionData;

  public itemId;
  public editing: boolean;

  constructor(
    protected formInputControlService: FormInputControlService,
    protected profileService: ProfileService,
    public profileOptionsService: UserOptionsService,
    protected route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getProfileIdFromParams();
    console.log('this.fetchProfileOptions');
    this.fetchProfileOptions();
    console.log(this.profileOptionData);
    this.fetchProfileItem();
  }

  ngOnDestroy() {
    this.profileSubscription.unsubscribe();
    this.profileOptionsSubscription.unsubscribe();
    this.editProfileIndexSubscription.unsubscribe();
  }

  public getProfileIdFromParams() {
    this.editProfileIndexSubscription = this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        const itemId = paramMap.get('id');
        this.itemId = itemId;
      }
    })
  }

  public async fetchProfileOptions() {
    console.log('fetche Profile Options');
    this.profileOptionsSubscription = this.profileOptionsService.getUserOptions()
      .subscribe(
        profileOptionData => {
          console.log(profileOptionData)
          this.profileOptionData = profileOptionData
        }
      ),
      error => {
        console.log(error);
      }
  }
  
  public fetchProfileItem() {
    this.profileSubscription = this.profileService.getProfileItem(this.BACKEND_URL, this.itemId)
    .subscribe(
      profileData => {
        this.profileData = profileData
        this.profileData.loading = false;
      }
    ),
    error => {
      console.log(error);
    }
  }

}
