import { UserOptionsService } from "src/app/dash/services/user-options.service";
import { BaseFormInput } from "src/app/shared/components/profile/profile/forms/form-items/base-form-input";
import { CheckboxFormInput } from "src/app/shared/components/profile/profile/forms/form-items/checkbox-form-inputs";
import { DateFormInput } from "src/app/shared/components/profile/profile/forms/form-items/date-form-input";
import { DropdownFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-form-input";
import { DropdownMultiFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-multi-form-input";
import { ImageFormInput } from "src/app/shared/components/profile/profile/forms/form-items/image-form-input";
import { NumberFormInput } from "src/app/shared/components/profile/profile/forms/form-items/number-form-input";
import { TextFormInput } from "src/app/shared/components/profile/profile/forms/form-items/text-form-input";
import { UnorderedListFormInput } from "src/app/shared/components/profile/profile/forms/form-items/unordered-list-form-input";

export class CreateUserLinkedPlatformProfilesFormGroupsModel {
    linkedPlatformProfileInfoControls: BaseFormInput<string>[] = [
      new TextFormInput({
        label: `Linked Platform Profile Name`,
        key: 'linkedPlatformProfileName',
        value: undefined,
        order: 2,
        disabled: false,
      }),
      new DropdownFormInput({
        label: `Linked Platform Profile Status`,
        controlType: `dropdown`,
        key: 'linkedPlatformProfileStatusCode',
        value: 'published',
        options: this.userOptionsService.publishStatusOptions,
        order: 1,
        disabled: false,
      }),
    ];
    linkedPlatformInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Linked Platform ID`,
        key: 'linkedPlatformId',
        value: undefined,
        order: 1,
        disabled: false,
      }),
      // new TextFormInput({
      //   label: `Linked Platform Name`,
      //   key: 'linkedPlatformName',
      //   value: undefined,
      //   order: 4,
      //   disabled: true,
      // }),
      // new DropdownFormInput({
      //   label: `Linked Platform Status`,
      //   controlType: `dropdown`,
      //   key: 'linkedPlatformProfileStatusCode',
      //   value: undefined,
      //   options: this.userOptionsService.publishStatusOptions,
      //   order: 1,
      //   disabled: true,
      // }),
      // new DropdownFormInput({
      //   label: `Linked Platform Type`,
      //   controlType: `dropdown`,
      //   key: 'linkedPlatformTypeName',
      //   value: undefined,
      //   options: this.userOptionsService.linkedPlatformTypeOptions,
      //   order: 1,
      //   disabled: true,
      // }),
    ];
    
    constructor(public userOptionsService: UserOptionsService) {}
}