export class BaseFormInput<T> {
  label: string;
  controlType: string;
  key: string;
  type: string;
  value: T;
  options: {key: string, value: string}[];
  order: number;
  required: boolean;
  disabled: boolean;
  hidden: boolean;
  
  constructor(
    options: {
      label?: string;
      controlType?: string;
      key?: string;
      type?: string;
      value?: T;
      options?: {key: string, value: string}[];
      order?: number;
      required?: boolean;
      disabled?: boolean;
      hidden?: boolean;
    } = {}) 
    {
      this.label = options.label || '';
      this.controlType = options.controlType || '';
      this.key = options.key || '';
      this.type = options.type || '';
      this.value = options.value;
      this.options = options.options || [];
      this.order = options.order === undefined ? 1 : options.order;
      this.required = !!options.required;
      this.disabled = options.disabled || false;
      this.hidden = options.hidden || false;
    }
  }