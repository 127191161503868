import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorComponent } from './core/error/error.component';
import { PageNotFoundComponent } from './core/error/page-not-found/page-not-found.component';
import { ForgotPwComponent } from './core/auth/forgot-pw/forgot-pw.component';
import { LoginComponent } from './core/auth/login/login.component';
import { NavbarComponent } from './core/shared/components/navbar/navbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HomepageComponent } from './dash/views/homepage/homepage.component';
import {MatButtonModule} from '@angular/material/button';
import {AngularMaterialModule} from './core/shared/angular-material.module';
import {DashModule} from './dash/dash.module';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './core/app-routing.module';
import {AuthInterceptor} from './core/auth/auth-interceptor';
import {ErrorInterceptor} from './core/error/error-interceptor';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    // App Components
    AppComponent,

    // Shared & Core Components
    NavbarComponent,

    // Error Components
    ErrorComponent,
    PageNotFoundComponent,
  ],
  imports: [
    // Core Modules
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularMaterialModule,
    SharedModule,
    // Admin Panel Modules
    AppRoutingModule,
    DashModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ErrorComponent
  ],
})
export class AppModule { }
