import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import {MatDialog} from '@angular/material/dialog';

import { ErrorComponent } from './error.component';
import {Injectable} from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private dialog: MatDialog) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // console.log('error interceptor REQ: ', req);  // this fires on every HTTP Request
    // console.log('error interceptor NEXT: ', next);  // this fires on every HTTP Request
    return next.handle(req).pipe(
      // none of the code below fires at all
      catchError((error: HttpErrorResponse) => {
        console.log('Error Interceptor Error Handling Start:', error);
        let errorMessage = 'An unknown error occurred!';
        if (error.error.errors[0].message) {
          errorMessage = error.error.errors[0].message;
        }
        this.dialog.open(ErrorComponent, {data: {message: errorMessage}});
        return throwError(error); // returning the throwError observable with the error)
      })
    );
  }
}
