import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserOptionsService {
  public BACKEND_URL = environment.apiUrl + '/users';

  public statusOptions = [];
  public userStatusOptions = [];
  public userTypeOptions = [];
  public analyticsStatusOptions = [];
  public publishStatusOptions = [];
  public sessionTypeOptions = [];
  public roleTypeOptions = [];
  public linkedPlatformTypeOptions = [];

  constructor(
    protected http: HttpClient,
  ) { }

  getUserOptions() {
    console.log(this.BACKEND_URL);
    return this.http.get<{ data: any; loading: boolean, success: boolean;  }>(
      this.BACKEND_URL + '/user-options'
    )
    .pipe(
        map((responseData) => {
          console.log('responseData', responseData);

          let profileData = responseData.data

          const statusOptions = [];
          for (let status of profileData.statusOptions) {
            let statusOption = {
              id: status.statusId,
              key: status.statusName,
              value: status.statusName,
              description: status.statusDescription
            }
            statusOptions.push(statusOption);
            this.statusOptions = statusOptions;
          }
          const userStatusOptions = [];
          for (let userStatus of profileData.userStatusOptions) {
            let userStatusOption = {
              id: userStatus.userStatusId,
              key: userStatus.userStatusName,
              value: userStatus.userStatusName,
              description: userStatus.userStatusDescription,
            }
            userStatusOptions.push(userStatusOption);
            this.userStatusOptions = userStatusOptions;
          }
          const userTypeOptions = [];
          for (let userType of profileData.userTypeOptions) {
            let userTypeOption = {
              id: userType.userTypeId,
              key: userType.userTypeName,
              value: userType.userTypeName,
              description: userType.userTypeDescription,
            }
            userTypeOptions.push(userTypeOption);
            this.userTypeOptions = userTypeOptions;
          }
          const analyticsStatusOptions = [];
          for (let analyticsStatus of profileData.userAnalyticsStatusOptions) {
            let analyticsStatusOption = {
              id: analyticsStatus.analyticsStatusId,
              key: analyticsStatus.analyticsStatusName,
              value: analyticsStatus.analyticsStatusName,
              description: analyticsStatus.analyticsStatusDescription,
            }
            analyticsStatusOptions.push(analyticsStatusOption);
            this.analyticsStatusOptions = analyticsStatusOptions;
          }
          const publishStatusOptions = [];
          for (let publishStatus of profileData.publishStatusOptions) {
            let publishStatusOption = {
              id: publishStatus.publishStatusId,
              key: publishStatus.publishStatusName,
              value: publishStatus.publishStatusName,
              description: publishStatus.publishStatusDescription,
            }
            publishStatusOptions.push(publishStatusOption);
            this.publishStatusOptions = publishStatusOptions;
          }
          const sessionTypeOptions = [];
          for (let sessionType of profileData.sessionTypeOptions) {
            let sessionTypeOption = {
              id: sessionType.sessionTypeId,
              key: sessionType.sessionTypeName,
              value: sessionType.sessionTypeName,
              description: sessionType.sessionTypeDescription,
            }
            sessionTypeOptions.push(sessionTypeOption);
            this.sessionTypeOptions = sessionTypeOptions;
          }
          const roleTypeOptions = [];
          for (let roleType of profileData.roleTypeOptions) {
            let roleTypeOption = {
              id: roleType.roleTypeId,
              key: roleType.roleTypeName,
              value: roleType.roleTypeName,
              description: roleType.roleTypeDescription,
            }
            roleTypeOptions.push(roleTypeOption);
            this.roleTypeOptions = roleTypeOptions;
          }
          const linkedPlatformTypeOptions = [];
          for (let linkedPlatformType of profileData.linkedPlatformTypeOptions) {
            let linkedPlatformTypeOption = {
              id: linkedPlatformType.linkedPlatformTypeId,
              key: linkedPlatformType.linkedPlatformTypeName,
              value: linkedPlatformType.linkedPlatformTypeName,
              description: linkedPlatformType.linkedPlatformTypeDescription,
            }
            linkedPlatformTypeOptions.push(linkedPlatformTypeOption);
            this.linkedPlatformTypeOptions = linkedPlatformTypeOptions;
          }
          return responseData.data
        })
      )
  }
}
