import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { AuthData } from './auth-data.model';

import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

const BACKEND_URL = environment.apiUrl + '/auth';
// const FRONTEND_URL = environment.dashUrl;


@Injectable( { providedIn: 'root' } )
export class AuthService {
  private isAuthenticated = false;
  private isAuthorized = false;
  // private isAuthenticated = true;
  private token: string;
  private tokenTimer: any;
  private userId: string;
  private dashId: string;
  private userRoles: [];
  // private user: UserModel;
  private authStatusListener = new Subject<boolean>();
  private authorizationStatusListener = new Subject<boolean>();
  private userRolesListener = new Subject<[]>();
  private userIdListener = new Subject<string>();

  constructor(private http: HttpClient, private router: Router) {
  }

  getToken() {
    return this.token;
  }

  getIsAuthenticated() {
    return this.isAuthenticated;
  }

  getIsAuthorized() {
    return this.isAuthorized;
  }

  getUserId() {
    return this.userId;
  }

  getUserRoles() {
    return this.userRoles;
  }

  checkUserRole(roleData) {
    const role = {
      role: roleData
    }
    return this.http.post<{isAuthorized: boolean}>(
      BACKEND_URL + '/get-user-roles',
      role
    ).pipe(map(responseData => {
      const authorizationStatus = responseData.isAuthorized;
      return authorizationStatus
    }))
  }

  getAuthStatusListener() {
    return this.authStatusListener;
  }

  getAuthorizationStatusListener() {
    return this.authorizationStatusListener;
  }

  getUserRolesListener() {
    return this.userRolesListener;
  }

  getUserIdListener() {
    return this.userIdListener;
  }

  login(login: string, password: string) {
    const authData: AuthData = {login, password};

    this.http.post<{token: string, expiresIn: number, userId: string, dashId: string, userRoles: []}>(
      BACKEND_URL + '/admin-login',
      authData,
      // {
      //   headers: new HttpHeaders({
      //     "Origin": FRONTEND_URL,
      //     "Referer": `${FRONTEND_URL}/`,
      //   })
      // }
    ).subscribe(response => {
      const token = response.token;
      this.token = token;
      if (token) {
        const expiresInDuration = response.expiresIn;
        this.setAuthTimer(expiresInDuration);
        this.isAuthenticated = true;
        this.userId = response.userId;
        this.dashId = response.dashId;
        this.userRoles = response.userRoles
        this.authStatusListener.next(true);
        this.userRolesListener.next(this.userRoles)
        this.userIdListener.next(this.userId);

        const now = new Date();
        const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
        this.saveAuthData(token, expirationDate, this.userId, this.dashId, this.userRoles);

        this.router.navigate(['/']);
      }
    }, error => {
      this.authStatusListener.next(false);
    });
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.dashId = authInformation.dashId;
      this.userRoles = JSON.parse(authInformation.userRoles);
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
    }
  }

// clears the token and informs all interested parts of the change
  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.userRolesListener.next([]);
    this.userId = null;
    this.userRoles = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/']);
  }

  private setAuthTimer(duration: number) {
    console.log('Setting timer: ' + duration);
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date, userId: string, dashId: string, userRoles: []/*user: UserModel*/ ) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('userId', userId);
    localStorage.setItem('dashId', dashId);
    localStorage.setItem('userRoles', JSON.stringify(userRoles));
    // localStorage.setItem('user', user.toString() );
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('userId');
    localStorage.removeItem('dashId');
    localStorage.removeItem('userRoles');
    // this.apollo.getClient().resetStore();
  }

// used to get user authentication data from local storage for use in the front end
  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const userId = localStorage.getItem('userId');
    const dashId = localStorage.getItem('dashId');
    const userRoles = localStorage.getItem('userRoles');
    if (!token || !expirationDate || !userId || !dashId || !userRoles) {
      return;
    }
    return {
      token,
      expirationDate: new Date(expirationDate),
      userId,
      dashId,
      userRoles
    };
  }
}
