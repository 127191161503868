import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {AuthService} from '../../../auth/auth.service';
import {environment} from '../../../../../environments/environment';
import { RoleGuard } from 'src/app/core/auth/role.guard';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  private userRolesListenerSub: Subscription;
  private userIdListenerSub: Subscription;
  userId;
  userRoles;

  appEnv = environment.appEnvironment;

  constructor(public authService: AuthService) {}

// ngOnInit is implemented to set up the subscription to the authStatusListener
  /* in this listener, we set the local userIsAuthenticated variable to the value passed in
      from the authService isAuthenticated Observable which is called by the .getAuthStatusListener() */
  ngOnInit() {
    this.userIsAuthenticated = this.authService.getIsAuthenticated();
    this.userId = this.authService.getUserId();
    this.userRoles = this.authService.getUserRoles();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
      });
    this.userRolesListenerSub = this.authService
      .getUserRolesListener()
      .subscribe(userRoles => {
        this.userRoles = userRoles
      });
    this.userIdListenerSub = this.authService
      .getUserIdListener()
      .subscribe(userId => {
        this.userId = userId
      })
  }

  isAuthorized(roleData) {
    if (this.userRoles.includes(roleData)) {
      return true
    } else {
      return false
    }
  }

  onLogout() {
    this.authService.logout();
  }

// ngOnDestroy is implemented because we set up a custom listener so it must be destroyed manually, lest we get a data leak
  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
    this.userRolesListenerSub.unsubscribe();
  }
}
