import { UserOptionsService } from "src/app/dash/services/user-options.service";
import { BaseFormInput } from "src/app/shared/components/profile/profile/forms/form-items/base-form-input";
import { CheckboxFormInput } from "src/app/shared/components/profile/profile/forms/form-items/checkbox-form-inputs";
import { DateFormInput } from "src/app/shared/components/profile/profile/forms/form-items/date-form-input";
import { DropdownFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-form-input";
import { DropdownMultiFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-multi-form-input";
import { ImageFormInput } from "src/app/shared/components/profile/profile/forms/form-items/image-form-input";
import { NumberFormInput } from "src/app/shared/components/profile/profile/forms/form-items/number-form-input";
import { TextFormInput } from "src/app/shared/components/profile/profile/forms/form-items/text-form-input";
import { UnorderedListFormInput } from "src/app/shared/components/profile/profile/forms/form-items/unordered-list-form-input";

export class CreateUserImagesFormGroupsModel {
    userImageInfoControls: BaseFormInput<string>[] = [
      new TextFormInput({
        label: `User Image Name`,
        key: 'userImageName',
        value: undefined,
        order: 2,
        required: true,
        disabled: false,
      }),
      new TextFormInput({
        label: `User Image Description`,
        key: 'userImageDescription',
        value: undefined,
        order: 2,
        disabled: false,
      }),
      new DropdownFormInput({
        label: `Image Status`,
        controlType: `dropdown`,
        key: 'userImageStatusCode',
        value: 'active',
        options: this.userOptionsService.statusOptions,
        order: 1,
        required: true,
        disabled: false,
      }),
      new ImageFormInput({
        label: `User Image`,
        key: 'image',
        value: undefined,
        order: 3,
        required: true,
        disabled: false,
      }),
    ];
    
    constructor(public userOptionsService: UserOptionsService) {}
}