<mat-card class="artist-form-card">
  <h1 class="mat-display-1">Update Server Variables</h1>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <form
    action=""
    class="artist-form"
    [formGroup]="editServerVariablesForm"
    (ngSubmit)="onSubmit(editServerVariablesForm)"
    *ngIf="!isLoading"
  >
    <!--    Content     -->
    <div
      formGroupName="editServerVariables"
      class="form-control-group"
    >
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Daily Harmonia Award Frequency</mat-label>
        <input
          matInput
          type="number"
          name="freeHarmoniaFrequency"
          formControlName="freeHarmoniaFrequency"
          id="freeHarmoniaFrequency"
          label="freeHarmoniaFrequency"
          placeholder="Enter the freeHarmoniaFrequency here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Daily Harmonia Periodic Amount</mat-label>
        <input
          matInput
          type="number"
          name="CheckinHarmoniaAmount"
          formControlName="CheckinHarmoniaAmount"
          id="CheckinHarmoniaAmount"
          label="CheckinHarmoniaAmount"
          placeholder="Enter the CheckinHarmoniaAmount here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Checkin Days Needed to Reset</mat-label>
        <input
          matInput
          type="number"
          name="CheckinDaysNeededToReset"
          formControlName="CheckinDaysNeededToReset"
          id="CheckinDaysNeededToReset"
          label="CheckinDaysNeededToReset"
          placeholder="Enter the CheckinDaysNeededToReset here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Max Daily Accrual</mat-label>
        <input
          matInput
          type="number"
          name="maxDailyAccrual"
          formControlName="maxDailyAccrual"
          id="maxDailyAccrual"
          label="maxDailyAccrual"
          placeholder="Enter the maxDailyAccrual here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>Max Golden Record</mat-label>
        <input
          matInput
          type="number"
          name="maxGoldenRecord"
          formControlName="maxGoldenRecord"
          id="maxGoldenRecord"
          label="maxGoldenRecord"
          placeholder="Enter the maxGoldenRecord here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
      appearance="fill"
      class="form-control"
      >
        <mat-label>Daily Harmonia Limit</mat-label>
        <input
        matInput
        type="number"
        name="dailyHarmoniaLimit"
        formControlName="dailyHarmoniaLimit"
        id="dailyHarmoniaLimit"
        label="dailyHarmoniaLimit"
        placeholder="Enter the dailyHarmoniaLimit here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>SVR Live Password</mat-label>
        <input
          matInput
          type="text"
          name="SVRLivePassword"
          formControlName="SVRLivePassword"
          id="SVRLivePassword"
          label="SVRLivePassword"
          placeholder="Enter the SVRLivePassword here"
        >
      </mat-form-field>
      <br>
      <mat-form-field
        appearance="fill"
        class="form-control"
      >
        <mat-label>SoundEscape Password</mat-label>
        <input
          matInput
          type="text"
          name="SoundEscapePassword"
          formControlName="SoundEscapePassword"
          id="SoundEscapePassword"
          label="SoundEscapePassword"
          placeholder="Enter the SoundEscapePassword here"
        >
      </mat-form-field>
      <br>
    </div>
    <!--    Meta     -->
    <div
      formGroupName="editServerVariablesMeta"
      class="form-control-group"
    >
      <mat-form-field [hidden]="true">
        <mat-label>Creator</mat-label>
        <input
          matInput
          value="{{ userId }}"
          name="creator"
          formControlName="creator"
          id="creator"
          label="creator"
        >
      </mat-form-field>
    </div>
    <button class="btn mat-raised-button mat-primary" type="submit" *ngIf="!hasError">Update Server Variables</button>
  </form>
</mat-card>
