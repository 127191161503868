import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserOptionsService } from 'src/app/dash/services/user-options.service';
import { UserProfileService } from 'src/app/dash/services/user-profile.service';
import { FormInputControlService } from 'src/app/shared/components/profile/profile/forms/form-items/form-input-control.service';
import { ProfileViewComponent } from 'src/app/shared/components/profile/view/profile-view/profile-view.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.css']
})
export class UserProfileViewComponent extends ProfileViewComponent implements OnInit {
  public BACKEND_URL = environment.apiUrl + '/users';

  constructor(
    public formInputControlService: FormInputControlService,
    public userProfileService: UserProfileService,
    public profileOptionsService: UserOptionsService,
    public route: ActivatedRoute,
  ) {
    super(formInputControlService, userProfileService, profileOptionsService, route);
  }
}
