import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from '../core/shared/angular-material.module';
import { DashRoutingModule } from './dash-routing.module';
import { InfoPaneViewComponent } from './views/info-pane-view/info-pane-view.component';
import { HomepageComponent } from './views/homepage/homepage.component';
import { ServerControlViewComponent } from './views/server-control-view/server-control-view.component';
import { MessagesViewComponent } from './views/messages-view/messages-view.component';
import { ReportsViewComponent } from './views/reports-view/reports-view.component';
import { GiftsViewComponent } from './views/gifts-view/gifts-view.component';
import { LatestNewsFormComponent } from './components/forms/info-pane/latest-news-form/latest-news-form.component';
import { ArtistEntryFormComponent } from './components/forms/info-pane/artist-entry-form/artist-entry-form.component';
import { ArtistScheduleFormComponent } from './components/forms/info-pane/artist-schedule-form/artist-schedule-form.component';
import { ServerVariablesFormComponent } from './components/forms/server-control/server-variables-form/server-variables-form.component';
import { ServerCommandsFormComponent } from './components/forms/server-control/server-commands-form/server-commands-form.component';
import { MessageBlastFormComponent } from './components/forms/messages/message-blast-form/message-blast-form.component';
import { PrintReportFormComponent } from './components/forms/reports/print-report-form/print-report-form.component';
import { GiftBlastFormComponent } from './components/forms/gifts/gift-blast-form/gift-blast-form.component';
import { ArtistListComponent } from './components/info-pane/artist-list/artist-list.component';
import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { UserDirectoryViewComponent } from './views/user-view/user-directory-view/user-directory-view.component';
import { UserViewComponent } from './views/user-view/user-view.component';
import { UserProfileViewComponent } from './views/user-view/user-profile-view/user-profile-view.component';
import { UserProfileComponent } from './components/users/user-profile/user-profile.component';
import { SharedModule } from '../shared/shared.module';
import { MuseViewComponent } from './views/muse-view/muse-view.component';
import { GetStreamComponent } from './components/muse/get-stream/get-stream.component';
import { MagicMirrorLinksComponent } from './components/muse/magic-mirror-links/magic-mirror-links.component';
import { VideoStatsComponent } from './components/muse/video-stats/video-stats.component';
import { MyViewComponent } from './views/my-view/my-view.component';

@NgModule({
  declarations: [
    HomepageComponent,
    InfoPaneViewComponent,
    ServerControlViewComponent,
    MessagesViewComponent,
    ReportsViewComponent,
    GiftsViewComponent,
    LatestNewsFormComponent,
    ArtistEntryFormComponent,
    ArtistScheduleFormComponent,
    ServerVariablesFormComponent,
    ServerCommandsFormComponent,
    MessageBlastFormComponent,
    PrintReportFormComponent,
    GiftBlastFormComponent,
    ArtistListComponent,
    UserDirectoryViewComponent,
    UserViewComponent,
    UserProfileViewComponent,
    UserProfileComponent,
    MuseViewComponent,
    GetStreamComponent,
    MagicMirrorLinksComponent,
    VideoStatsComponent,
    MyViewComponent
  ],
  exports: [],
  imports: [
    // Core Modules
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    SharedModule,
    // Admin Panel Modules
    DashRoutingModule,
  ]
})
export class DashModule { }
