import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProfileService } from 'src/app/shared/services/profile.service';

import { BaseFormInput } from '../form-items/base-form-input';
import { FormInputControlService } from '../form-items/form-input-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css'],
  providers: [ FormInputControlService ]
})
export class DynamicFormComponent implements OnInit {
  @Input() formInputs: {controls: [], title: string, editing: boolean, fragment: string, type: string};
  @Input() editing = false;

  formGroup: FormGroup;
  payLoad = '';

  itemId;
  editProfileIndexSubscription;
  @Input() mutation;
  @Input() parentForm: FormGroup;

  constructor(
    private formInputControlService: FormInputControlService,
    public profileService: ProfileService,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.formGroup = this.parentForm
    // this.formGroup = this.formInputControlService.formInputsToFormGroup(this.formInputs.controls);
    this.editProfileIndexSubscription = this.getProfileIdFromParams();
  }

  public getProfileIdFromParams() {
    this.editProfileIndexSubscription = this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        const itemId = paramMap.get('id');
        this.itemId = itemId;
      }
    })
  }
  
  editCategoryCardForm() {
    console.log('editing')
    console.log(this.editing)
    this.editing = !this.editing
    console.log(this.editing)
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.formGroup.getRawValue());
    console.log(this.formGroup);
    console.log(this.payLoad);
    console.log(this.profileService.profileId);
    this.profileService.updateCategoryCardData(this.mutation, this.itemId, this.formGroup);
  }
}
