import { UserOptionsService } from "src/app/dash/services/user-options.service";
import { BaseFormInput } from "src/app/shared/components/profile/profile/forms/form-items/base-form-input";
import { CheckboxFormInput } from "src/app/shared/components/profile/profile/forms/form-items/checkbox-form-inputs";
import { DateFormInput } from "src/app/shared/components/profile/profile/forms/form-items/date-form-input";
import { DropdownFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-form-input";
import { DropdownMultiFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-multi-form-input";
import { ImageFormInput } from "src/app/shared/components/profile/profile/forms/form-items/image-form-input";
import { NumberFormInput } from "src/app/shared/components/profile/profile/forms/form-items/number-form-input";
import { TextFormInput } from "src/app/shared/components/profile/profile/forms/form-items/text-form-input";
import { UnorderedListFormInput } from "src/app/shared/components/profile/profile/forms/form-items/unordered-list-form-input";

export class CreateUserVrPlatformProfilesFormGroupsModel {
    userVrPlatformProfileInfoControls: BaseFormInput<string>[] = [
      new DropdownFormInput({
        label: `VR Platform Profile Status`,
        key: 'vrPlatformProfileStatusCode',
        value: 'active',
        order: 2,
        options: this.userOptionsService.statusOptions,
        disabled: false,
      }),
      // new DateFormInput({
      //   label: `Date Added to SVR`,
      //   key: 'createdTime',
      //   value: undefined,
      //   order: 3,
      //   disabled: false,
      // }),
      new TextFormInput({
        label: `VR Platform User ID`,
        key: 'vrPlatformProfileUserId',
        value: undefined,
        order: 4,
        disabled: false,
      }),
    ];
    vrPlatformInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `VR Platform ID`,
        key: 'vrPlatformId',
        value: undefined,
        order: 1,
        disabled: false,
      }),
      // new TextFormInput({
      //   label: `Display Name`,
      //   key: 'vrPlatformDisplayName',
      //   value: undefined,
      //   order: 2,
      //   disabled: false,
      // }),
      // new TextFormInput({
      //   label: `Description`,
      //   key: 'vrPlatformDescription',
      //   value: undefined,
      //   order: 3,
      //   disabled: false,
      // }),
    ];
    
    constructor(public userOptionsService: UserOptionsService) {}
}