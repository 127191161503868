import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DirectoryTypeModel } from 'src/app/shared/models/directoryType.model';
import { DirectoryService } from 'src/app/shared/services/directory.service';

@Component({
  selector: 'app-directory-view',
  templateUrl: './directory-view.component.html',
  styleUrls: ['./directory-view.component.css']
})
export abstract class DirectoryViewComponent implements OnInit, OnDestroy {
  abstract dataSourceType: string;
  abstract dataTable: DirectoryTypeModel[];
  public directoryData;
  // directoryItems: DirectoryTypeModel[];

  dataTableColumns: {field: string, width: number}[] = [];
  directoryColumnsHeaders = ['select', 'profile'];

  error = null;

  isLoading = false;

  abstract BACKEND_URL: String;
  // abstract QUERY: DocumentNode;

  abstract directorySubscription: Subscription;

  constructor(
    protected directoryService: DirectoryService
  ) { }

  ngOnInit(): void {
    this.handleFetchedDirectoryItems();
  }

  ngOnDestroy() {
    this.directorySubscription.unsubscribe();
  }

  protected handleFetchedDirectoryItems() {
    return this.directorySubscription = this.directoryService.getDirectoryItemArray(this.BACKEND_URL)
    .subscribe(
      directoryData => {
        this.isLoading = directoryData.loading;
        this.directoryData = directoryData
        console.log(directoryData);
      }),
      error => {
        this.error = error.message;
      };
  }
}
