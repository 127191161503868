import { UserOptionsService } from "src/app/dash/services/user-options.service";
import { BaseFormInput } from "src/app/shared/components/profile/profile/forms/form-items/base-form-input";
import { CheckboxFormInput } from "src/app/shared/components/profile/profile/forms/form-items/checkbox-form-inputs";
import { DateFormInput } from "src/app/shared/components/profile/profile/forms/form-items/date-form-input";
import { DropdownFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-form-input";
import { DropdownMultiFormInput } from "src/app/shared/components/profile/profile/forms/form-items/dropdown-multi-form-input";
import { ImageFormInput } from "src/app/shared/components/profile/profile/forms/form-items/image-form-input";
import { NumberFormInput } from "src/app/shared/components/profile/profile/forms/form-items/number-form-input";
import { TextFormInput } from "src/app/shared/components/profile/profile/forms/form-items/text-form-input";
import { UnorderedListFormInput } from "src/app/shared/components/profile/profile/forms/form-items/unordered-list-form-input";

export class CreateUserInventoryTransactionsFormGroupsModel {
    userInventoryTransactionItemInfoControls: BaseFormInput<string>[] = [
      // new NumberFormInput({
      //   label: `User Inventory Transaction ID`,
      //   key: 'userInventoryTransactionId',
      //   value: undefined,
      //   order: 1,
      //   disabled: false,
      // }),
      new NumberFormInput({
        label: `Harmonia Paid`,
        key: 'harmoniaPaid',
        value: undefined,
        order: 2,
        disabled: false,
      }),
      new DateFormInput({
        label: `Date Purchased`,
        key: 'createdTime',
        value: undefined,
        order: 3,
        disabled: false,
      }),
    ];
    inventoryItemInfoControls: BaseFormInput<string>[] = [
      new NumberFormInput({
        label: `Inventory Item ID`,
        key: 'inventoryItemId',
        value: undefined,
        order: 1,
        disabled: false,
      }),
      // new TextFormInput({
      //   label: `Display Name`,
      //   key: 'displayName',
      //   value: undefined,
      //   order: 2,
      //   disabled: false,
      // }),
      // new NumberFormInput({
      //   label: `Harmonia Cost`,
      //   key: 'harmoniaCost',
      //   value: undefined,
      //   order: 3,
      //   disabled: false,
      // }),
    ];
    
    constructor(public userOptionsService: UserOptionsService) {}
}