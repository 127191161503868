import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, OnDestroy {
  isAuthorizedSubscription: Subscription;

  constructor(private authService: AuthService) {}

  ngOnDestroy(): void {
    this.isAuthorizedSubscription.unsubscribe();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!!next.data.role) {
      const routeRole = next.data.role;
      return this.getIsAuthorized(routeRole);
    }
  }

  getIsAuthorized(routeRole) {
    return new Promise<boolean>((resolve, reject) => {
      this.isAuthorizedSubscription = this.authService.checkUserRole(routeRole).subscribe(res => {
        if (res) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, (err) => {
        resolve(false);
      });
    });
  }
}
