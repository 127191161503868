<div class="table-card__header-actions">
    <button mat-button mat-flat-button class="directory-button" routerLink="../create" (click)="addObject()">
      Add {{ fetchedSourceType.charAt(0).toUpperCase() + fetchedSourceType.slice(1) }}
    </button>
    <button mat-button mat-flat-button class="directory-button" (click)="bulkUpdateObject(this.selection.selected)" disabled="true">
      Bulk Update
    </button>
    <button mat-button mat-flat-button class="directory-button" (click)="downloadCSV()" disabled="true">
      Download
    </button>
    <button mat-button mat-flat-button class="directory-button" (click)="more()" disabled="true">
      More
    </button>
  </div>