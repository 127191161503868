<mat-card>
  <mat-card-header>
    <mat-card-title>Artist List</mat-card-title>
<!--    <mat-card-subtitle></mat-card-subtitle>-->
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Artist </th>
        <td mat-cell *matCellDef="let artist"> {{artist.name}} </td>
      </ng-container>

      <!-- website Column -->
      <ng-container matColumnDef="website">
        <th mat-header-cell *matHeaderCellDef> Website URL </th>
        <td mat-cell *matCellDef="let artist"> {{artist.website}} </td>
      </ng-container>

      <!-- livestream Column -->
      <ng-container matColumnDef="livestream">
        <th mat-header-cell *matHeaderCellDef> Livestream URL </th>
        <td mat-cell *matCellDef="let artist"> {{artist.livestream}} </td>
      </ng-container>

      <!-- linkId Column -->
      <ng-container matColumnDef="linkId">
        <th mat-header-cell *matHeaderCellDef> Link ID </th>
        <td mat-cell *matCellDef="let artist"> {{artist.linkId}} </td>
      </ng-container>

      <!-- Edit Artist Column -->
      <ng-container matColumnDef="edit-artist">
        <th mat-header-cell *matHeaderCellDef> Edit Artist </th>
        <td mat-cell *matCellDef="let artist">
          <a (click)="editArtist(artist._id)" mat-button>Edit Artist Info</a>
        </td>
      </ng-container>

      <!-- Edit Performance Column -->
      <ng-container matColumnDef="edit-performance">
        <th mat-header-cell *matHeaderCellDef> Edit Performance </th>
        <td mat-cell *matCellDef="let artist">
          <a (click)="editPerformance(artist._id)" mat-button>Edit Performance Info</a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
  <mat-card-footer>
    <mat-card-actions></mat-card-actions>
  </mat-card-footer>
</mat-card>
