import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';

const BACKEND_URL = environment.apiUrl + '/gifts';


@Injectable({
  providedIn: 'root'
})
export class GiftingService {
  private giftLinks = [
    { path: 'blast', label: 'New Gift Blast' },
  ];

  private audienceGroups = [
    'all-users',
  ];

  private giftingListener = new Subject<{}>();

  constructor(private http: HttpClient, private router: Router) {
  }

  getGiftLinks() {
    return this.giftLinks;
  }

  getAudienceGroups() {
    return this.audienceGroups;
  }

  sendGift(messageInput) {
    console.log(messageInput);
    this.http.post<{ body: any }>(
      BACKEND_URL + '/blast',
      messageInput
    )
      .subscribe(
        response => {
          console.log(response);
          // this.router.navigate(['/dash/info-pane']);
          return response;
        },
        error => {
          this.giftingListener.next();
        });
  }
}
